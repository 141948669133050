import request from '../utils/request';
export default {
    //质押
    pledgepooldo(data,address) {
        return request.request({
            url: '/pledge/pool/do',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           data:data
        })
    },
     //账单列表
     pledgerecordlist(data,address) {
        return request.request({
            url: '/pledge/record/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           params:data
        })
    },
     //赎回
     pledgepoolredeem(data,address) {
        return request.request({
            url: '/pledge/pool/redeem',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           data:data
        })
    },
   //账单类型条件
   pledgerecordlist(data,address) {
    return request.request({
        url: '/pledge/record/list',
        method: "get",
        headers:{
            Authorization : address,
            'Content-Type':'application/json',
            
        },
       params:data
    })
},
 //领取奖励
 pledgepoolclaim(data,address) {
    return request.request({
        url: '/pledge/pool/claim',
        method: "post",
        headers:{
            Authorization : address,
            'Content-Type':'application/json',
            
        },
       data:data
    })
},
//获取池子列表
pledgepoollist(address) {
    return request.request({
        url: '/pledge/pool/list',
        method: "get",
        headers:{
            Authorization : address,
            'Content-Type':'application/json',
            
        },
    })
},
}