import request from '../utils/request';
export default {
    //首页行情
    gettokenquote(address,language) {
        return request.request({
            url: '/token/quote',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           
            
        })
    },
    gettokenimgurl(address,language) {
        return request.request({
            url: '/basic/config',
            method: "get",
            headers:{
               
                'Content-Type':'application/json',
                
            },
           
            
        })
    },
    getbancer(address,language) {
        return request.request({
            url: '/basic/banner/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           
            
        })
    },
    getswapjson() {
        return request.request({
            url: 'static/upload/json/swap.json',
            // method: "get",
            //  responseType:"json",
            headers:{
              
                
              
            },
           
            
        })
    },
}