<template>
  <div id="app" class="main" :style="{ backgroundImage : `url(${$route.meta.backgroundImage ? $route.meta.backgroundImage : ''})`}">
    <Header></Header>
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapState } from 'vuex';
import { simpleRpcProvider } from '@/utils/provider';
import Web3 from 'web3';

export default {
  name: 'App',
  components: {
    Header
  },
  beforeCreate() {

  },
  created() {
    this.getGas()
  },
  computed: {
    ...mapState(['gasPriceType', 'account', 'provider', 'gasPrice', 'slippage', 'deadline', 'transactions', 'block', 'expert']),
  },
  async created() {
    this.$store.dispatch('setWallet')
    this.$store.dispatch('setWebProvider');
    setTimeout(() => {
      this.$store.dispatch('setWebProvider');

    }, 1000);
    this.$store.commit('SETACCOUNTS', '');
    // sessionStorage.getItem('account') ? sessionStorage.getItem('account') : 'en-US'
    // sessionStorage.setItem('account', item.lang);
    // commit('SETACCOUNTS',  sessionStorage.getItem('account') ? sessionStorage.getItem('account') : 'en-US');
    const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
    this.$store.commit('SETBLOCK', blockNumber);
    this.timer = setInterval(async () => {
      const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
      this.$store.commit('SETBLOCK', blockNumber);
    }, 6000);
  },
  watch: {
    block() {
      this.handleUpdateTransactions();
    },
    account() {
      this.getGas()
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
    this.$store.commit('SETACCOUNTS', '');
  },

  methods: {
    async getGas() {
      const web3js = new Web3(window.ethereum); //
      let gasPrice = await web3js.eth.getGasPrice()
      console.log('gasPrice', gasPrice);
      if (this.gasPriceType == 1) {
        gasPrice = gasPrice * 1;
      } else if (this.gasPriceType == 2) {
        gasPrice = gasPrice * 1.5;
      } else if (this.gasPriceType == 3) {
        gasPrice = gasPrice * 2;
      }
      this.$store.commit('SET_GASPRICE', parseInt(gasPrice));

    },
    async handleUpdateTransactions() {
      if (!this.account) {
        return;
      }
      const allTransactions = Object.values(this.transactions);
      const pendTransactions = allTransactions.filter(item => {
        return !item.receipt && item.from.toLowerCase() == this.account.toLowerCase();
      });
      pendTransactions.forEach((item, index) => {
        simpleRpcProvider.eth.getTransactionReceipt(item.hash).then(receipt => {
          if (receipt) {
            this.$store.commit('SETTRANSACTIONSRECEIPT', {
              hash: item.hash,
              receipt,
            });
            if (document.documentElement.clientWidth < 400) {
              // if (receipt.state) {
              this.$message({ type: 'success', title: item.title, message: item.message, hash: receipt.hash });
              // } else {
              //   this.$message({ type: 'error', title: item.title, message: item.message, hash: receipt.hash });
              // }
            } else {
              // if (receipt.state) {
              this.$message({
                position: 'top-right',
                type: 'success',
                title: item.title,
                message: item.message,
                hash: receipt.hash,
              });
              // } else {
              //   this.$message({
              //     position: 'top-right',
              //     type: 'error',
              //     title: item.title,
              //     message: item.message,
              //     hash: receipt.hash,
              //   });
              // }
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>


 .main {
  background-color: #0D081A;
  background-size: cover;
  background-repeat: no-repeat;
 
  min-height: 100vh;
  max-width: 375px;
  margin: 0 auto;
  overflow: hidden;
  transition: .5s ease;
}

@media screen and (max-width: 700px) {
  .main {
    max-width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .main {
    border-left: 1px solid #f1f0f3;
    border-right: 1px solid #f1f0f3;
  }
}
</style>
