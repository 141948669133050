export const lang = {
  swap1: '閃兌',
  swap2: '立刻開始閃兌',
  swap3: '從',
  swap4: '餘額:',
  swap5: '選擇幣種',
  swap6: '最大',
  swap7: '價格',
  swap8: '滑點容差',
  swap9: '連接錢包',
  swap10: '輸入數量',
  swap11: '餘額不足',
  swap12: '此交易的流動性不足',
  swap13: '選擇代幣',
  swap14: '授權',
  swap15: '包裝',
  swap16: '解包',
  swap17: '閃兌',
  swap18: '交易收據',
  swap19: '正在等待確認',
  swap20: '正在將',
  swap21: '交換為',
  swap22: '在你的錢包種確認此交易',
  swap23: '已提交交易',
  swap24: '在BSC上查看',
  swap25: '關閉',
  swap26: '交易拒絕',
  swap27: '確認交換',
  swap28: '輸出為估值,您將獲得至少{inputOutMin} {symbol},或者交易將被撤',
  swap29: '輸入為估值,您將出售最多{inputInMax} {symbol},或者交易將被撤回',
  swap30: '最小獲得量',
  swap31: '最大出售量',
  swap32: '到',
  swap33: '管理代幣',
  swap34: '管理代幣',
  swap35: '導入',
  swap36: '管理Tokens',
  swap37: '自定義代幣',
  swap38: '全部清除',
  swap39: '搜索名稱或粘貼地址',
  swap40: '設定',
  swap41: '默認交易速度(GWEI)',
  swap42: '標準',
  swap43: '快速',
  swap44: '即時',
  swap46: '輸入有效的滑點百分比',
  swap47: '你的交易可能會失敗',
  swap48: '你的交易可能會被超前',
  swap49: '交易截止時間(分鐘)',
  swap50: '估計',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近沒有交易',
  swap54: '全部清除',
  swap55: '未連接',
  swap56: '加入董事會',
  swap57: '捐贈',
  swap58: '權益贈送',
  swap59: '董事會成員列表',
  swap60: '邀請',
  swap61: '複製',
  swap62: '我的DAPP邀請人:',
  swap63: '邀請記錄',
  swap64: '出錯啦,請稍後再試',
  swap65: '功能暫未開放',
  swap66: '領取',
  swap67: '取出成功',
  swap68: '捐贈中，待確認',
  swap69: '已捐贈',
  swap70: '非白名單用戶',
  swap71: '捐贈無效',
  swap72: '當前餘額不足',
  swap73: '授權中，請稍等',
  swap74: '授權失敗',
  swap75: '捐贈中，請稍等',
  swap76: '捐贈失敗',
  swap77: '兌換',
  swap78: '流動性',
  swap79: '會員',
  swap80: '邀请',
  swap81: '權益池',
  swap82: '資產橋',
  swap83: '池子',
  swap84: '農場',
  swap85: '首頁',
  swap86: '市場',
  swap87: '兌換',
  swap88: '交易',
  swap89: '賺取',
  swap90: '確認',
  swap91: '價格影響',
  swap92: '最小獲得量',
  swap93: '流動性供應商費用',
  swap94: '增加流動性',
  swap95: '增加流動性以接收 LP 代幣',
  swap96: '流動性池中的份額',
  swap97: '兌換率和流動池份額',
  swap98: '您的流動性',
  swap99: '移除流動性以收回代幣',
  swap100: '未找到流動性',
  swap101: '未看到您加入的流動池？',
  swap102: '查看其他LP代幣',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供應',
  swap106: '導入池',
  swap107: '導入現有流動性池',
  swap108: '選擇代幣以查找您的流動性',
  swap109: '您在該流動性池中還沒有提供流動性',
  swap110: '您的錢包中的LP代幣',
  swap111: '接收',
  swap112: '和',
  swap113: '金額',
  swap114: '你將獲得',
  swap115: '輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
  swap116: '已焚毀',
  swap117: '已入金',
  swap118: '確認供應',
  swap119: '匯率',
  swap120: '您是第一個流動性供應商。',
  swap121: '您添加代幣的比列，將决定該流動性池的初始價格。',
  swap122: '如果您對匯率滿意，請點擊“供應”以進行檢查。',
  swap123: '正在移除',
  swap124: '正在供應',
  swap125: '確認供應',
  swap126: '白皮書',
  swap127: '稽核',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1兌換',
  swap131: '更多',
  swap132: '正在加載',
  swap133: '信息',
  swap134: '捐贈名額已滿',
  swap135: '我的PHO',
  swap136: '錢包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO價格',
  swap139: 'PHO總量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已賺取',
  swap143: '質押',
  swap144: '個人TVL',
  swap145: '社區TVL',
  swap146: '收割',
  swap147: '累計激勵 ',
  swap148: '今日激勵',
  swap149: '參與市商地址數',
  swap150: '我的邀请链接',
  swap151: '最大算力區',
  swap152: '其他算力區',
  swap153: '我的好友邀請',
  swap154: '取消',
  swap155: '修改邀請人',
  swap156: '邀請人不能為空',
  swap157: '修改成功',
  swap158: '質押LP代幣',
  swap159: '贖回LP代幣',
  swap160: '贖回',
  swap161: '獲取',
  swap162: '當前暫無收益',
  swap163: '路由',
  swap164: '審計機構',
  swap165: '合作夥伴',
  swap166: '發行',
  swap167: '活動',
  swap168: '單幣池',
  swap169: 'LP池',
  swap170: '國會',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '遊戲',
  swap174: '權益池已分配總額',
  swap175: '待領取數額',
  swap176: '我已獲取數額',
  swap177: '取出',
  swap178: '超級節點',
  swap179: '權益卡已領取份額',
  swap180: '國庫',
  swap181: '國庫累計金額',
  swap182: '國庫已分配金額',
  swap183: '國庫已回購金額',
  swap184: '專家模式',
  swap185: 'D6 Swap是基於OKExChain研發的全網首個股權稅收制Defi平臺，逐步推出Swap、NFT、Gamefi、Lend、機槍池等板塊。 問世以來通過領先的科技和公平公開的市場理念，提供更加廣泛的資產增值方案和前所未有的體驗感，深受廣大用戶喜愛。',
  swap186: '一鍵取出',
  swap187: '價格影響過高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '賺幣',
  swap191: '進行中',
  swap192: '已結束',

  swap193: '說明',
  swap194: '股權權益生態卡，全球限量1680張',
  swap195: '點亮股權權益生態卡的要求及收益如下',
  swap196: '一星卡：個人地址擁有價值1000USDT流動性，團隊1000USDT流動性，收益共分交易稅收0.1%',
  swap197: '二星卡：個人擁有價值2000USDT流動性，團隊1萬USDT流動性，收益共分交易稅收0.1%',
  swap198: '三星卡：個人3000USDT流動性，團隊10萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap199: '四星卡：個人擁有價值4000USDT流動性，團隊100萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap200: '五星卡：個人5000USDT流動性，團隊1000萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap201: '備註：所有權益生態卡收益全部鏈上自動分配，權益卡享受交易所所有生態分紅，權益卡可傳承可交易可轉讓，滿足要求自動升級，不滿足要求自動降級及自動退出。',
  swap202: '權益卡',
  swap203: '發展路線圖：',
  swap204: '2022年7月2.0版上線',
  swap205: '2022年9月開通持幣多挖',
  swap206: '2022年12月NFT交易平臺上線',
  swap207: '2023年6月Goodbaby遊戲上線',
  swap208: '2023年9月Goodlend上線',
  swap209: 'D6 Swap介紹：',
  swap210: '滑點：',
  swap211: '分配：',
  swap212: '買入',
  swap213: '賣出',
  swap214: '0.5% LP分紅',
  swap215: '0.5%生態權益卡分紅',
  swap216: '0.25%回流底池銷毀LP',
  swap217: '0.25%生態建設',
  swap218: '暫無獎勵領取',
  swap219: '個人TVL',
  swap220: '團隊TVL',
  swap221: 'LP數量',
  swap222: '錢包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累計獎勵',
  swap225: '星級用戶累計獎勵',
  swap226: '免手續費倒計時',
  swap227: '可取出獎勵',
  swap228: 'lp挖礦獎勵',
  swap229: '質押pho',
  swap230: '贖回pho',
  swap231: '目標共識',
  swap232: '已達共識',
  swap233: '已共識',
  swap234: '共識',
  swap235: '已解鎖',
  swap236: '可領取',
  swap237: '社區治理',
  swap238: '聯盟共識',
  swap239: '社區聯盟',
  swap240: '質押代幣',
  swap241: '質押中，請稍等',
  swap242: '交易挖礦',
  swap243: '昨日新增',
  swap244: '社區白名單累計獎勵',
  swap245: '股權星卡累計獎勵',
  swap246: 'LP挖礦累計獎勵',
  swap247: '當前可領取',
  swap248: '當前可領取金額',
  swap249: '交易挖礦',
  swap250: '我的分享獎勵累計',
  swap251: '我的交易獎勵累計',
  swap252: '請輸入您的交易雜湊',
  swap253: '校驗',
  swap254: '請輸入您的雜湊',
  swap255: '您的交易地址',
  swap256: '您將獲得',
  swap257: '僅買入GDT及創建流動性的交易可獲得獎勵',
  swap258: '質押GDT-PHO',
  swap259: '贖回GDT-PHO',
  swap260: '全網手續費累計',
  swap261: '待領取分紅',
  swap262: 'GDT聯合股東',
  swap263: '社交',
  swap264: '商城',
  swap265: '精選好物',
  swap266: '優選小店',
  swap267: '即將上線',
  swap268: 'VGT聯創股東',
  swap271: '推薦鏈接',
  swap272: '請綁定領導人',
  swap273: '詳細',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交換和流動性',
  swap277: '交易、發展、賺錢、',
  swap278: '多鏈聚合DEX生態平台',
  swap279: '享受最低的交易費，有利可圖的功能和多類型推薦計劃',
  swap280: '路線圖',
  swap281: '2022年7月 2.0版上線',
  swap282: '2022年9月 開通持幣多挖',
  swap283: '2022年12月 NFT交易平台上線',
  swap284: '2023年6月 Goodbaby遊戲上線',
  swap285: '2023年9月 Goodlend上線',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密數字貨幣',
  swap290: '質押流動性和代幣',
  swap291: '賺取分紅獎勵',
  swap292: '發射台',
  swap293: '認證',
  swap294: '優質加密數字資產',
  swap295: '了解',
  swap296: '藝術品',
  swap297: '交易市場',
  swap298: '瀏覽',
  swap299: '即時兌換代幣',
  swap300: '複製成功',
  swap301: '不支持複製，該瀏覽器不支持自動複製',
  swap302: '聯創累計獎勵',
  swap303: '累計分享獎勵',
  swap304: '累計交易獎勵',
  swap305: '資產包',
  swap306: '交易賺幣',
  swap307: '可取出餘額',
  swap308: '請輸入交易雜湊',
  swap309: '確定',
  swap310: '請輸入hash值',
  swap311: '入帳成功',

  swap312:'幣安',
  swap313:'平台',
  swap314:'PHO專區',
  swap315:'GDT專區',
  swap316:'VGT專區',
swap317:'PHO星級卡',
swap318:'個人',
swap319:'團隊',
swap320:'錢包中的',
swap321:'選擇資產包',
swap322:'請選擇',
swap323:'日收益率',
swap324:'收益比例',
swap325:'到期總數',
swap326:'購買',
swap327:'您的錢包餘額',
swap328:'我的資產包',
swap329:'今日總收益',
swap330:'今日靜態收益',
swap331:'今日動態收益',
swap332:'累計收益',
swap333:'到期數量',
swap334:'選擇',
swap335:'加密貨幣專區',
swap336:'購買成功',
swap337:'進入 DApp',
swap338:'圈子',
swap339:'與你的加密圈夥伴一起暢聊',
swap340:'糖果盒',
swap341:'多種優質資產空投',
swap342:'快來免費領取',
swap343:'眾創池',
swap344:'鑄幣中心',
swap345:'社區圈子',
swap346:'糖果盒子',
swap347:'賺幣',
swap348:'空投',
swap349:'分享中心',
swap350:'聯盟計劃',
swap351:"社交",
swap352:"橋",
swap353:"映射",
swap354:"Water 礦池",
swap355:"參與礦池建設，自由賺取高額激勵",
swap356:"流動性礦池",
swap357:"單幣質押池",
swap358:"實時",
swap359:"已完成",
swap360:"僅限已質押",
swap361:"排序依據",
swap362:"搜索",
swap363:" 熱門 ",
swap364:"搜索礦池",
swap365:"這裡什麼也沒有！",
swap366:"首個Water Swap的啟動模板",
swap367:"為每個優質投資者和加密資產找到最佳匹配路徑",
swap368:"創建ISO",
swap369:"成為未來的加密明星",
swap370:"即將到來",
swap371:"請先綁定推薦人",
swap372:"我的邀請人",
swap373:"邀請人地址：",
swap374:"",
swap375:"Q3  推出並上線D6 Swap，並開啟創世鑄幣，同時引入加密社交平台CIRCLE，以深度鏈接社區生態發展。",
swap376:"Q4  對D6進行流動性注入，開啟 “D6共識計劃”，打造D6流通共識，並啟用流動性激勵計劃。推出鏈上聯盟跨境商城，打造“消費增值”生態，推動社區與商業結合新方向。",
swap377:"",
swap378:"Q1 一攬子聯盟扶持計劃，推動D6作為基礎流動性支持，提高市場認可和占比度，上線D-Pay，促進D6 Coin的實體交易流通",
swap379:"Q2 正式上線D6 Trade，開啟CEX和DEX雙交易平台時代，進一步提高D6市場交易流動性，並引入新的伙伴。",
swap380:"",
swap381:"",

d1:"充幣",
d2:"提幣",
d3:"轉賬",
d4:"賬單",
d5:"社區",
d6:"閃兌",
d7:"問題反饋",
d8:"鑄造",
d9:"賺取D6 Coin",
d10:"收益高達",
d11:"進入",
d12:"Water行情",
d13:"今日價格",
d14:"今日收益",
d15:"查看",
d16:"資產",
d17:"隱藏零資產",
d18:"幣種",
d19:"凍結",
d20:"可用",
d21:"選擇通證",
d22:"充值數量",
d23:"請輸入充值數量",
d24:"錢包餘額:",
d25:"入賬地址",
d26:"確定",
d27:"充值記錄",
d28:"提取數量",
d29:"請輸入提取數量",
d30:"賬戶餘額：",
d31:"到賬地址",
d32:"提幣記錄",
d33:"轉賬數量",
d34:"請輸入轉賬數量",
d35:"輸入對方地址",
d36:"請輸入對方地址",
d37:"轉賬記錄",
d38:"資產兌換",
d39:"支付",
d40:"得到",
d41:"數量",
d42:"全部",
d43:"最多可兌換：",
d44:"交易稅：",
d45:"應到賬：",
d46:"支付數量：",
d47:"閃兌記錄",
d48:"確認兌換",
d49:"閃兌記錄",
d50:"已收益",
d51:"待收益",
d52:"燃燒金額",
d53:"請輸入燃燒金額",
d54:"進行中",
d55:"已結束",
d56:"流通進度",
d57:"暫無記錄！",
d58:"歷史收益",
d59:"我的等級",
d60:"當前等級",
d61:"註冊後即可成為 D0用戶",
d62:"好友邀請計劃",
d63:"邀請好友共同鑄幣，開啟Water共識革命，加速賺取獎勵！",
d64:"複製鏈接",
d65:"我的推薦人",
d66:"我的有效推薦",
d67:"地址",
d68:"級別",
d69:"業績",
d70:"下一等級：",
d71:"市場業績",
d72:"尚未完成",
d73:"市場部門",
d74:"您已是最高級別",
d75:"公告",
d76:"綁定確認",
d77:"領導人地址：",
d78:"確認綁定",
d79:"不能為負和零",
d80:"餘額不足",
d81:"充幣成功",
d82:"充幣失敗",
d83:"手續費：",
d84:"轉入",
d85:"轉出",
d86:"賬單記錄",
d87:"時間",
d88:"類型",
d89:"數量",
d90:"幣種",
d91:"請先選擇支付通證",
d92:"此通證禁止雙向兌換",
d93:"不能選擇相同的通證",
d94:"請先綁定領導人",
d95:"剩餘可燃燒金額",
d96:"請先綁定推薦人",
d97:"請填寫推薦人邀請碼",
d98:"最新公告",
d99:"注：",
d100:"2: 提幣訂單，預計為T+1日到賬",
d101:"1：單筆提幣 {min_withdraw} {token_name}起",
d102:"2：每筆提幣收取{min_fee} {token_name}稅收",
d103:"團隊地址",
d104:"團隊有效",
d105:"今日充值",
d106:"今日提取",
d107:"個人",
d108:"團隊",
d109:"開啟後，達到鑄造條件，自動參與鑄造",
d110:"充值後預計1-2分鐘到賬",
d111:"每筆轉賬收取{feerate}%交易稅",
d112:"如有疑問，請遺留問題反饋！",
d113:"反饋問題",
d114:"反饋列表",
d115:"反饋內容",
d116:"線上客服",
d117:"暫未回复",
d118:"回复：",
d119:"1.意見反饋",
d120:"2.添加圖片(最多3張)",
d121:"提交",
d122:"請上傳png或jpg格式圖片",
d123:"上傳中，請稍候...",
d124:"文件大小不能超過 10M",
d125:"用戶",
d126:"CIRCLES  AirDrop 礦池",
d127:"發行總量",
d128:"當前產出",
d129:"提取",
d130:"我的餘額",
d131:"查看",
d132:"分享人數",
d133:"工作的礦機",
d134:"時",
d135:"分",
d136:"秒",
d137:"挖礦中",
d138:"啟動挖礦",
d139:"邀請好友",
d140:"好友啟動挖礦",
d141:"獲得分享收益",
d142:"一級分享",
d143:"地址",
d144:"狀態",
d145:"沒有更多了",
d146:"二級分享",
d147:"創世挖礦",
d148:"進入",
d149:"CIRCLES致力於加密社交隱私公鏈建設，計畫將於2025年推出，公鏈代幣總量20億枚，每位用戶可領取1T算力，逐量衰減，累計免費挖出80%即止。",
d150:"總資產估值",
d151:"9·18 通證",
d152:"9·18通證是WaterSwap合作的首個以歷史題材社區通證，以「人人持有、永世不忘」為目標，銘記歷史，杜絕淡化，從9·18通證開始 ！即刻免費領取吧！",
d153:"挖礦規則",
d154:"每位使用者可獲得1T算力，每T算力初始24小時產出30枚，每產出1000萬枚算力遞減20%。",
d155:"直接邀請50%,間接邀請25%算力加成激勵！",
d156:"領取空投",
d157:"提幣尚未開放",
d158:"當您綁定市場領袖後，即可領取9.18枚918通證空投。",
d159:"若您參與分享，每分享10個用戶領取空投，您將再獲得一次空投獎勵資格，最高可獲得918枚！",
d160:"我的空投",
d161:"普惠空投",
d162:"我的分享",
d163:"提幣尚未開放",
d164:"當前等級",
d165:"查看收益",
d166:"普通用戶",
d167:"VIP會員",
d168:"升級",
d169:"複製邀請碼",
d170:"地址",
d171:"狀態",
d172:"生態",
d173:"持有卡牌",
d174:"參與生態建設,獲得豐厚獎勵",
d175:"今日可領取",
d176:"C2C交易市場",
d177:"當前等級",
d178:"領取",
d179:"進入",
d180:"您沒有邀請碼",
d181:"我的市場",
d182:"不能購買",
d183:"您的領袖未成為會員",
d184:"鏈上餘額不足",
d185:"平台餘額不足",
d186:"簽名失敗",
d187:"轉帳失敗",
d188:"續費",
d189:"邀請碼",
d190:"你需支付",
d191:"錢包餘額",
d192:"餘額",
d193:"平台餘額",
d194:"付款中...請等待",
d195:"支付成功",
d196:"確定付款",
d197:"關閉",
d198:"支付失敗",
d199:"交易",
d200:"賺幣",
d201:"空投",
d202:"社區",
d203:"生態中心",
d204:'選擇網路',
d205:"枚",
d206:"已領取",
  d207: "未領取",

   h335: '正在進行',
    h336: '已經結束',
    h337: '說明',
    h338: '1 ：支援以USDT或HAP進行支付',
    h339: '2：使用HAP支付可享受{rate}的折扣標準',
    h340: '3：合夥人支付可享受{rate}的折扣標準',
    h341: '4：基金會支付可享受{rate}的折扣標準',
    h342: '5：用戶只能享受最高的折扣標準，不累加。',
    h343: '6：Btpi上線後，持有價值大於{rate}U的LP即可獲得原始代幣空投',
    h344: '您已加入',
    h345: '我的分享',
  h346: '未參與',
     h347: '股東招募',
    h348: 'Pi在全球200多個國家/地區擁有超過6000萬活躍用戶，接近2億註冊用戶，Pi的推特粉絲數量超過338萬，幾乎趕上了以太坊。從未見過一個加密項目擁有其他加密項目無法比擬的活躍社區。向BTC致敬，比特派將比特幣的顛覆性力量與Pi的無限精神結合，開啟數字世界的無限未來，這不僅僅是一個區塊鏈項目，更是一場引領全球參與的創新革命。',
    h349: '創世募集'
};
