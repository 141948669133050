<template>
    <div class="circulation">
        <div class="nftcenter_sum">
            <div class="flex" style="align-items: start">
                <div class="sumbox">
                    <div class="sumbox_title">{{ $t('lang.h245') }}</div>
                    <div class="sumbox_num"><span>0</span> <span style="font-size: 12px">$</span></div>
                </div>
                <img src="@/assets/imgsnowball/circulation_box_logo.png" class="sumbox_img" alt="" />
            </div>
            <div class="flex">
                <div class="flex" style="transform: scale(0.8) translateX(-30px)">
                    <div class="sumbox" style="margin-right: 23px">
                        <div class="sumbox_title">{{ $t('lang.h253') }}</div>
                        <div class="sumbox_num"><span>0</span> <span style="font-size: 12px">HAP</span></div>
                    </div>
                    <div class="sumbox">
                        <div class="sumbox_title">{{ $t('lang.h254') }}</div>
                        <div class="sumbox_num"><span>0</span> <span style="font-size: 12px">GOC</span></div>
                    </div>
                </div>
                <div class="button" @click="GobillPage">{{ $t('lang.h104') }}</div>
            </div>
        </div>
        <div class="teamAndShare">
            <div class="share">
                <img class="teamAndShare_icon" src="@/assets/imgsnowball/share_bg_icon.png" alt="">
                <div class="abs_button" @click="GosharePage">{{ $t('lang.h255') }}</div>
                <div class="text">{{ $t('lang.h256') }}</div>
                <div class="num">{{ CuserInfo.dr_valid_num }}</div>
            </div>
            <div class="team">
                <img class="teamAndShare_icon" src="@/assets/imgsnowball/team_bg_icon.png" alt="">
                <div class="text">{{ $t('lang.h257') }}</div>
                <div class="num" style="color: rgba(0, 99, 98, 1);">{{ CuserInfo.team_valid_num }}</div>
            </div>
        </div>

        <van-swipe class="storehouse" :loop="false" indicator-color="white" :show-indicators="false" @change="change"
            ref="swiperRef">
            <van-swipe-item class="storehouse_box">
                <div class="storehouse_box_title">
                    <span>{{ $t('lang.h258') }}</span>
                    <img src="@/assets/imgsnowball/warehouse_icon.png" alt="">
                </div>
                <div class="storehouse_box_content">
                    <div class="storehouse_box_content_title">
                        <div>{{ $t('lang.h259') }}</div>
                        <div>{{ $t('lang.h260') }}</div>
                        <div>{{ $t('lang.h261') }}</div>
                    </div>
                    <div class="storehouse_box_content_num">
                        <div>{{ Cwalletasset.can_buy }}</div>
                        <div>{{ Cwalletasset.wait_buy }}</div>
                        <div style="color: rgba(182, 133, 255, 1);">{{ Cwalletasset.freeze_buy }}</div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item class="storehouse_box">
                <div class="storehouse_box_title">
                    <span>{{ $t('lang.h262') }}</span>
                    <img style="width: 20px; height: 24px;" src="@/assets/imgsnowball/Inspire_icon.png" alt="">
                </div>
                <div class="storehouse_box_content">
                    <div class="storehouse_box_content_title">
                        <div>{{ $t('lang.h263') }}</div>
                        <div>{{ $t('lang.h264') }}</div>
                    </div>
                    <div class="storehouse_box_content_num">
                        <div>{{ Cwalletasset.can_sell }}</div>
                        <div>{{ Cwalletasset.wait_sell }}</div>
                    </div>
                </div>
            </van-swipe-item>
        </van-swipe>
        <div class="market_title">
            <div class="market_title_left">
                <div style="margin-right: 26px;" :class="marketTitletype == '1' ? 'market_title_active' : ''"
                    @click="marketTitletypeFunc('1')">{{ $t('lang.h265') }}</div>
                <div :class="marketTitletype == '2' ? 'market_title_active' : ''" @click="marketTitletypeFunc('2')">{{
                    $t('lang.h266') }}
                </div>
            </div>
            <div class="market_title_right" @click="GoOrderPage">{{ $t('lang.h267') }}</div>
        </div>
        <van-list class="market_list" v-model="BillInfo.loading" :finished="BillInfo.finished"
            :finished-text="`${$t('lang.d145')}`" @load="onLoadBillInfo" v-if="BillInfo.list.length > 0">
            <div class="market_list_li" v-for="(item, index) in BillInfo.list">
                <div class="market_list_li_title flex-items-center">
                    <span style="color: rgba(255, 255, 255, 1); font-size: 16px;">{{ item.address }}</span>
                    <span style="color: rgba(255, 255, 255, 0.6); font-size: 12px;">{{ $t('lang.h268') }}：{{
                        item.created_at }}</span>
                </div>
                <div class="flex-items-center">
                    <div class="flex-items-center">
                        <span class="white06">{{ marketTitletype == '1' ? $t('lang.h269') : $t('lang.h270') }}</span>
                        <div class="flex-items-center white">
                            <img style="width: 10px; height: 11px; margin-right: 4px;"
                                src="@/assets/imgsnowball/HAP_market_icon.png" alt="">
                            <span>{{ item.token_unit }}</span>
                        </div>
                    </div>
                    <div>
                        <span class="white06">{{ marketTitletype == '1' ? $t('lang.h271') : $t('lang.h272') }}</span>
                        <span class="white">{{ item.amount }} {{ item.token_unit }}</span>
                    </div>
                </div>
                <div class="flex-items-center">
                    <div>
                        <span class="white06">{{ $t('lang.h273') }}</span>
                        <span class="white">{{ item.freeze_day }} D</span>
                    </div>
                    <div>
                        <span class="white06">{{ $t('lang.h274') }}</span>
                        <span class="white">{{ item.reward_amount }} HAP</span>
                    </div>
                </div>
            </div>
        </van-list>
        <div class="market_list" v-else>
            <div class="no_list">
                <img src="@/assets/imgsnowball/no_list_icon.png" class="no_list_icon" alt="">
                <div class="no_data" style="color: rgba(255, 255, 255, 1);margin-top: 22px;">{{ $t('lang.h275') }}</div>
            </div>

        </div>
        <div class="fix_buttons">
            <div class="button join" @click="joincirculationShowFuncOpen">{{ $t('lang.h276') }}</div>
            <div class="button sold" @click="soldcirculationShowFuncOpen">{{ $t('lang.h277') }}</div>
        </div>
        <van-popup position="bottom" v-model="joincirculationShow" closeable round>
            <div class="joincirculationShow">
                <div class="joincirculationShow_title">{{ $t('lang.h278') }}</div>
                <div class="joincirculationShow_box">
                    <div class="joincirculationShow_box_title">{{ $t('lang.h279') }}</div>
                    <div class="joincirculationShow_box_input">
                        <input type="number" v-model="EarnNum" :placeholder="`${$t('lang.h280')}${Cconfig.amount}`">
                        <div class="joincirculationShow_box_input_right">HAP</div>
                    </div>

                </div>
                <div class="joincirculationShow_box">
                    <div class="joincirculationShow_box_title">{{ $t('lang.h281') }}</div>
                    <div class="joincirculationShow_box_input">
                        <div>{{ ComEarningtion }}</div>
                        <div class="joincirculationShow_box_input_right">HAP</div>
                    </div>

                </div>
                <div class="joincirculationShow_box">
                    <div class="joincirculationShow_box_title">{{ $t('lang.h282') }}</div>
                    <div class="joincirculationShow_box_input">
                        <div>≈{{ ComFueltion }}</div>
                        <div class="joincirculationShow_box_input_right">GOC</div>
                    </div>

                </div>
                <div class="joincirculationShow_balance">
                    <div>HAP{{ $t('lang.h283') }}: {{ Tokenbanlace.HAP.usable }}</div>
                    <div>GOC{{ $t('lang.h283') }}: {{ Tokenbanlace.GOC.usable }}</div>
                </div>
                <div class="joincirculationShow_caption">{{ $t('lang.h284') }}</div>
                <div class="joincirculationShow_bnutton" v-loading="payLoading" @click="circulationorderpublish">{{
                    $t('lang.h289') }}</div>
            </div>

        </van-popup>
        <van-popup position="bottom" v-model="soldcirculationShow" closeable round>
            <div class="joincirculationShow">
                <div class="joincirculationShow_title">{{ $t('lang.h285') }}</div>

                <div class="joincirculationShow_box">
                    <div class="joincirculationShow_box_title">{{ $t('lang.h286') }}</div>
                    <div class="joincirculationShow_box_input">
                        <input type="text" v-model="EarnNum" :placeholder="`${$t('lang.h322')}${Cconfig.sell_amounts}`">
                        <div class="joincirculationShow_box_input_right">HAP</div>
                    </div>

                </div>
                <div class="joincirculationShow_balance">
                    <div>{{ $t('lang.h287') }}: <span>{{ Cwalletasset.can_sell }}</span> HAP</div>

                </div>
                <div class="joincirculationShow_caption">{{ $t('lang.h288') }}</div>
                <div class="joincirculationShow_bnutton" v-loading="payLoading" @click="circulationorderpublish">{{
                    $t('lang.h289') }}</div>
            </div>

        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { circulation, web3_api } from '@/api/index';
import topbutton from '@/components/topbutton.vue';

export default {
    data() {
        return {
            marketTitletype: '1',
            pubishType: 0,
            payLoading: false,
            joincirculationShow: false,
            soldcirculationShow: false,
            CuserInfo: {},
            Cwalletasset: {},
            Cconfig: {},
            GOCPrice: 1,
            HAPPrice: 1,
            EarnNum: '',
            Tokenbanlace: {
                HAP: {},
                GOC: {}
            },
            BillInfo: {
                list: [],
                params: {
                    page: 1,
                    page_size: 10
                },
                finished: false,
                loading: false
            }
        }
    },
    components: {
        topbutton
    },
    computed: {
        ...mapState(['account', 'lang']),
        ComEarningtion() {
            if (this.EarnNum == '') return 0
            return (this.Cconfig.reward_rate * parseFloat(this.EarnNum)).toFixed(2)
        },
        ComFueltion() {

            if (this.EarnNum == '') return 0
            return parseFloat((this.Cconfig.burn_rate * (this.ComEarningtion * this.HAPPrice)) / this.GOCPrice).toFixed(2)
        }
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    mounted() {

    },
    created() {
        this.init()
    },
    methods: {
        change(e) {

        },
        init() {
            if (this.account) {
                Promise.all([
                    this.circulationwalletasset(),
                    this.circulationconfig(),
                    this.circulationuserinfo(),
                    this.isBuyOrSell(),
                    this.getTokenbanlace(),
                    this.getGOCAndHAPPrice()
                ])

            }

        },

        async getGOCAndHAPPrice() {
            let price = await web3_api.getCurrentPairPrice(['0xc7f0bbdc745fe2cb59087c43ae200f65b22dddc2', '0xd8f0e57b3c77725cb861b3c45e2ad22894d0a2f8'], '0x69267e8d1f052eef1a236e0cf24019f7fd5f353f', 18, 18)
            let price2 = await web3_api.getCurrentPairPrice(['0xd8f0e57b3c77725cb861b3c45e2ad22894d0a2f8'], '0xb61e1493cb2cbbf2e3e89872c6095aa000d5db32', 18, 18)
            this.GOCPrice = price;
            this.HAPPrice = price2;

        },
        async circulationorderpublish() {
            if (this.payLoading) return;
            this.payLoading = true
            let sign = null
            try {
                sign = await web3_api.signMessage('Circulation Order Publish', this.account)
            } catch (error) {
                this.payLoading = false
            }

            let data = {
                type: this.pubishType,
                amount: parseFloat(this.EarnNum),
                signature: sign

            }
            circulation.circulationorderpublish(data, this.account).then(res => {
                if (res.code == 200) {
                    this.joincirculationShow = false
                    this.soldcirculationShow = false
                    this.init()
                }
                this.$toast(res.msg)
                this.payLoading = false
            })
        },
        onLoadBillInfo() {
            if(this.account){
                this.isBuyOrSell()
            }
            
        },
        getTokenbanlace() {
            circulation.walletinfobykey({ token_key: 'HAPB' }, this.account).then(res => {
                if (res.code == 200) {
                    this.Tokenbanlace.HAP = res.data
                }

            })
            circulation.walletinfobykey({ token_key: 'GOC' }, this.account).then(res => {
                if (res.code == 200) {
                    this.Tokenbanlace.GOC = res.data
                }
            })
        },
        circulationwalletasset() {
            circulation.circulationwalletasset(this.account).then(res => {
                if (res.code == 200) {
                    this.Cwalletasset = res.data
                }

            })
        },
        circulationconfig() {
            circulation.circulationconfig(this.account).then(res => {
                if (res.code == 200) {
                    this.Cconfig = res.data
                }
            })
        },
        circulationuserinfo() {
            circulation.circulationuserinfo(this.account).then(res => {
                if (res.code == 200) {
                    this.CuserInfo = res.data
                }
            })
        },
        GoOrderPage() {
            this.$router.push({
                name: 'OrderPage'
            })
        },
        GobillPage() {
            this.$router.push({
                name: 'billPage'
            })
        },
        GosharePage() {
            this.$router.push({
                name: 'sharePage'
            })
        },
        isbuysell() {
            switch (this.Cconfig.status) {
                case '0':
                    this.$toast(this.$t('lang.h328'))
                    return true;
                case '1':
                    return false;
                case '2':
                    this.$toast(this.$t('lang.h329'))
                    return true;
                default:
                    return true;
            }
        },
        joincirculationShowFuncOpen() {
             if(this.isbuysell()) return;
             if(!this.Cconfig.can_buy) return this.$toast(this.$t('lang.h330'));


            this.joincirculationShow = true
            this.EarnNum = ''
            this.pubishType = 0
        },
        soldcirculationShowFuncOpen() {
            if(this.isbuysell()) return;
            if(!this.Cconfig.can_sell) return this.$toast(this.$t('lang.h331'));

            this.soldcirculationShow = true
            this.EarnNum = ''
            this.pubishType = 1
        },
        marketTitletypeFunc(type) {
            this.marketTitletype = type
            this.initBillInfo()
            this.isBuyOrSell()
        },
        isBuyOrSell() {

            if (this.marketTitletype == '1') {
                this.circulationorderbuyzone()
            } else {
                this.circulationordersellzone()
            }
        },
        initBillInfo() {
            this.BillInfo = {
                list: [],
                params: {
                    page: 1,
                    page_size: 10
                },
                finished: false,
                loading: false
            }
        },
        circulationordersellzone() {
            this.BillInfo.loading = true
            circulation.circulationordersellzone(this.BillInfo.params, this.account).then(res => {
                if (res.data.list.length > 0) {
                    if (this.BillInfo.params.page == 1) {
                        this.BillInfo.list = res.data.list
                    } else {
                        this.BillInfo.list = this.BillInfo.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.BillInfo.params.page_size) {
                        this.BillInfo.finished = true
                    } else {
                        this.BillInfo.params.page += 1
                    }
                    this.BillInfo.loading = false
                } else {
                    this.BillInfo.finished = true
                }

            })
        },

        circulationorderbuyzone() {
            this.BillInfo.loading = true
            circulation.circulationorderbuyzone(this.BillInfo.params, this.account).then(res => {
                if (res.data.list.length > 0) {
                    if (this.BillInfo.params.page == 1) {
                        this.BillInfo.list = res.data.list
                    } else {
                        this.BillInfo.list = this.BillInfo.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.BillInfo.params.page_size) {
                        this.BillInfo.finished = true
                    } else {
                        this.BillInfo.params.page += 1
                    }
                    this.BillInfo.loading = false
                } else {
                    this.BillInfo.finished = true
                }

            })
        }
    },
}

</script>
<style scoped lang='less'>
.joincirculationShow {
    width: 100%;
    background: #fff;
    padding: 16px 20px;

    .joincirculationShow_title {
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
        margin-bottom: 13px;
    }

    .joincirculationShow_bnutton {
        margin-bottom: 35px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(3, 3, 3, 1);
        font-size: 16px;
        font-weight: bold;
        height: 48px;
        opacity: 1;
        border-radius: 10px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    }

    .joincirculationShow_balance {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
    }

    .joincirculationShow_caption {
        margin-top: 11px;
        margin-bottom: 15px;
        font-size: 12px;
        color: rgba(129, 181, 254, 1);
        text-align: center;
    }

    .joincirculationShow_box {
        .joincirculationShow_box_title {
            font-size: 12px;
            color: rgba(0, 0, 0, 1);
            font-weight: bold;
            margin-bottom: 8px;
        }

        .joincirculationShow_box_input {
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;
            background: rgba(243, 243, 243, 1);
            padding: 13px 22px;
            color: rgba(0, 0, 0, 1);
            font-size: 12px;

            input {
                outline: none;
                border: none;
                background: none;
            }

            .joincirculationShow_box_input_right {
                color: rgba(0, 0, 0, 0.4);
                padding-left: 16px;
                border-left: 1px solid rgba(210, 210, 210, 1);
            }
        }
    }
}

.fix_buttons {
    padding: 10px 19px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    opacity: 1;
    background: rgba(27, 31, 46, 1);
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.25);

    .button {
        width: 47%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        opacity: 1;
        border-radius: 8px;
        font-size: 14px;
        font-weight: bold;
        color: rgba(2, 0, 48, 1);
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    }

    .sold {
        background: rgba(67, 101, 164, 1);
        color: rgba(255, 255, 255, 1);
    }
}

.market_list {
    margin-top: 18px;

    .no_list {
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;

        .no_list_icon {
            width: 100px;
            height: 96px;
        }
    }

    .market_list_li {
        margin-bottom: 14px;
        padding: 11px 13px;
        padding-bottom: 22px;
        width: 100%;
        height: 145px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 31, 46, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border: 1px solid rgba(52, 247, 244, 0.49);

        .market_list_li_title {
            border-bottom: 1px solid rgba(36, 44, 73, 1);
            padding-bottom: 14px;
        }

        .white {
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
        }

        .white06 {
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
            margin-right: 10px;
        }

        .flex-items-center {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.market_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .market_title_right {
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
    }

    .market_title_left {
        justify-content: space-between;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;

        .market_title_active {
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-weight: bold;
            position: relative;
        }

        .market_title_active::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 35px;
            height: 2px;
            border-radius: 91px;
            background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        }
    }
}

.storehouse {
    margin-top: 21px;
    margin-bottom: 30px;

    .storehouse_box {
        width: 100%;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 31, 46, 1);

        border: 1px solid rgba(0, 0, 0, 1);

        .storehouse_box_content {
            width: 100%;
            padding: 16px 17px;

            .storehouse_box_content_title {
                margin-bottom: 21px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                color: rgba(255, 255, 255, 1);

            }

            .storehouse_box_content_num {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                color: rgba(164, 165, 171, 1);
            }
        }

        .storehouse_box_title {
            padding: 15px 18px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: rgba(255, 255, 255, 1);
            font-weight: bold;
            border-bottom: 1px solid rgba(38, 41, 53, 1);

            img {
                width: 24px;
                height: 25px;
            }
        }
    }
}

.circulation {
    padding: 20px;
    padding-bottom: 106px;
}

.nftcenter_sum {

    padding: 13px 17px;
    width: 335px;

    margin: 0 auto;
    margin-top: 22px;
    border-radius: 8px;
    background: linear-gradient(138.02deg, rgba(29, 145, 143, 1) 0%, rgba(102, 61, 184, 1) 100%);

    .button {
        width: 53px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(102, 61, 184, 1);
    }

    .sumbox_img {
        width: 93px;
        height: 92px;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sumbox {
        font-size: 12px;

        .sumbox_title {
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 4px;
        }

        .sumbox_num {
            font-size: 20px;
            color: #fff;
        }
    }
}

.teamAndShare {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div {
        padding-top: 56px;
        padding-left: 15px;
        width: 47%;
        height: 111px;
        opacity: 1;
        border-radius: 10px;
        background: url('~@/assets/imgsnowball/share_bg.png') no-repeat;
        background-size: contain;
        position: relative;

    }

    .team {
        background: url('~@/assets/imgsnowball/team_bg.png') no-repeat;
        background-size: contain;
    }

    .abs_button {
        position: absolute;
        right: 12px;
        top: 12px;
        padding: 4px 11px;
        color: rgba(13, 8, 26, 1);
        font-size: 12px;
        border-radius: 106px;
        background: rgba(255, 255, 255, 0.4);
    }

    .text {
        color: rgba(0, 0, 0, 1);
        font-size: 14px;
    }

    .num {
        font-size: 20px;
        color: rgba(15, 87, 212, 1);
    }

    .teamAndShare_icon {
        position: absolute;
        left: 50px;
        top: 20px;
        transform: translate(-50%, -50%);
        width: 85px;
        height: 85px;
    }
}
</style>