<template>
    <div class="engbill">
        <div class="imgboxs">
            <div class="box">
                <img src="@/assets/imgsnowball/eng_img1.png" alt="" />
                <span>{{ $t('lang.h124') }}</span>
            </div>

            <div class="line"></div>
            <div class="box">
                <img src="@/assets/imgsnowball/eng_img4.png" alt="" />
                <span>{{ $t('lang.h309') }} </span>
            </div>
            <div class="line"></div>
            <div class="box">
                <img src="@/assets/imgsnowball/eng_img3.png" alt="" />
                <span>{{ $t('lang.h310') }}</span>
            </div>
        </div>
        <div class="boxs1">
            <div class="box_left">
                <span class="box_num" style="color: rgba(173, 141, 255, 1);">{{ Cuserinfo.max_zone_valid_num || 0
                    }}</span>
                <span class="box_title">{{ $t('lang.h332') }}</span>
            </div>
            <div class="box_right">
                <span class="box_num" style="color: rgba(97, 239, 198, 1);">{{ Cuserinfo.other_zone_valid_num || 0
                    }}</span>
                <span class="box_title">{{ $t('lang.h333') }}</span>
            </div>
        </div>
        <div class="functional_region" id="myElementId">
            <div class="region">
                <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
                    :class="index == regionIndex ? 'special' : ''">
                    <span>{{ $t(item.name) }}</span>
                </div>
            </div>
        </div>
        <div class="list" v-if="childrenList.length > 0">
            <div class="li">
                <div>{{ $t('lang.h127') }}</div>
                <div>{{ $t('lang.h312') }}</div>
                <div>{{ $t('lang.h334') }}</div>
            </div>
            <div class="li" v-for="(item, index) in childrenList" :key="index">
                <div style="color: rgba(255, 255, 255, 1)">{{ item.address }}</div>
                <div style="color: rgba(48, 255, 252, 1)" v-if="item.is_valid == '1'">{{ $t('lang.h313') }}</div>
                <div style="color: rgba(153, 153, 153, 1)" v-else>{{ $t('lang.h314') }}</div>
                <div style="color: #fff;">
                    {{ item.children_valid_num }}
                </div>
            </div>
        </div>
        <div class="list flex-items-center" v-else>
            <img src="@/assets/img/zanwu.png" alt="">
            <span style="font-size: 16px; color: rgba(255, 255, 255, 1); margin-top: 22px;">{{ $t('lang.h315') }}</span>
        </div>

    </div>
</template>

<script>
import { circulation } from '@/api/index';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            childrenList: [],
            maxchildrenList: [],
            otherchildrenList: [],
            Cuserinfo: {},
            regionIndex: 0,
            functionOption: [{ name: 'lang.h332' }, { name: 'lang.h333' }],
        };
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {
            this.init();
        },
        lang() {
            this.init();
        },
    },
    components: {},
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        init() {

            if (this.account) {
               
                this.circulationuserinfo()
                this.circulationusermaxzone()
            }
        },
        switchovers(index) {
            this.regionIndex = index;
            switch (index) {
                case 0:
                    this.childrenList = this.maxchildrenList
                    break;

                case 1:
                    this.childrenList = this.otherchildrenList
                    break;
            }
        },
        circulationusermaxzone() {
            circulation.circulationusermaxzone(this.account).then(res => {
                if (res.code == 200) {

                    this.maxchildrenList = res.data.user_id == 0 ? [] : [res.data]
                    this.regionIndex = 0
                    this.childrenList = this.maxchildrenList
                    this.nftchildrenlist()
                }
            })
        },
        nftchildrenlist() {
            circulation.circulationuserchildrenlist(this.account).then(res => {

                if (res.code == 200) {
                    if (res.data != null) {
                        let list = res.data
                        this.otherchildrenList = this.maxchildrenList.length > 0 ? list.filter(item => item.user_id != this.maxchildrenList[0].user_id) : list
                    }
                }
            })
        },
        circulationuserinfo() {
            circulation.circulationuserinfo(this.account).then(res => {
                if (res.code == 200) {
                    this.Cuserinfo = res.data
                }
            })
        },
        toprouter() {
            this.$router.go(-1);
        },

    },
};
</script>
<style scoped lang='less'>
.functional_region {
    margin-top: 12px;
    padding-top: 18px;

    .region {
        display: flex;

        align-items: center;

        div {
            margin-right: 28px;
        }

        .special {
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            position: relative !important;

            &::before {
                content: '';
                position: absolute !important;
                width: 36px;
                height: 2px;
                border-radius: 25px;
                background: rgba(56, 248, 252, 1);
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.engbill {
    padding: 20px;
min-height: 100vh;
    padding-bottom: 44px;
}

.boxs1 {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div {
        width: 48%;
        height: 88px;
        opacity: 1;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .box_num {
            font-size: 24px;
            font-weight: 700;
            color: #FFFFFF;
        }

        .box_title {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
        }
    }

    .box_left {
        background: linear-gradient(137.72deg, rgba(48, 255, 252, 0.2) 0%, rgba(182, 133, 255, 0.2) 100%);

        border: 1px solid rgba(173, 141, 255, 1);
    }

    .box_right {
        background: linear-gradient(138.02deg, rgba(237, 255, 102, 0.2) 0%, rgba(78, 237, 211, 0.2) 100%);

        border: 1px solid rgba(97, 239, 198, 1);
    }
}

.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-items-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.list {
    margin-top: 29px;
    padding: 15px 20px;
    width: 100%;
    max-height: 460px;
    border-radius: 16px;
    background: rgba(27, 31, 46, 1);
    overflow-y: scroll;
    border: 1px solid rgba(36, 40, 52, 1);

    img {
        width: 89px;
        height: 158px;
    }

    .first {
        color: rgba(153, 153, 153, 1);
        padding-bottom: 13px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .li {
        margin-bottom: 15px;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &>div {
            flex: 1;
            text-align: center;
        }

        &>div:last-child {
            text-align: right;
        }

        &>div:first-child {
            text-align: left;
        }
    }
}

.imgboxs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #fff;

        img {
            width: 49px;
            height: 47px;
            margin-bottom: 11px;
        }
    }

    .line {
        width: 42px;
        height: 1px;
        border-bottom: 1px dotted rgba(56, 248, 252, 1);
    }
}
</style>