import request from '../utils/request';
export default {

    bpuserchildrenlist(address,params) {
        return request.request({
            url: '/bp/user/children-list',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
    bpusersummary(address) {
        return request.request({
            url: '/bp/user/summary',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    bpmint(address,data) {
        return request.request({
            url: '/bp/mint',
            method: "post",
            headers:{
                Authorization : address,
            },
            data
        })
    },
    bpmintlist(address,params) {
        return request.request({
            url: '/bp/mint/list',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
    bpwalletasset(address) {
        return request.request({
            url: '/bp/wallet/asset',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    bpwalletrecordtypes(address) {
        return request.request({
            url: '/bp/wallet/record-types',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    bpwalletrecordlist(address,params) {
        return request.request({
            url: '/bp/wallet/record-list',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
    bpwalletclaim(address,data) {
        return request.request({
            url: '/bp/wallet/claim',
            method: "post",
            headers:{
                Authorization : address,
            },
            data
        })
    },
    bpnodeconfig(address) {
        return request.request({
            url: '/bp/node/config',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    bpnodebuy(address,data) {
        return request.request({
            url: '/bp/node/buy',
            method: "post",
            headers:{
                Authorization : address,
            },
            data
        })
    },
    bpnodeinfo(address) {
        return request.request({
            url: '/bp/node/info',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    bpconfig(address) {
        return request.request({
            url: '/bp/config',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    walletinfobykey(address,params) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
    walletinfo(address,params) {
        return request.request({
            url: '/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
    tokenchart(address,params) {
        return request.request({
            url: '/token/chart',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
    exchangeconfigs(address) {
        return request.request({
            url: '/exchange/configs',
            method: "get",
            headers:{
                Authorization : address,
            },
        })
    },
    exchangedo(address,data) {
        return request.request({
            url: '/exchange/do',
            method: "post",
            headers:{
                Authorization : address,
            },
            data
        })
    },
    exchangelist(address,params) {
        return request.request({
            url: '/exchange/list',
            method: "get",
            headers:{
                Authorization : address,
            },
            params
        })
    },
}