import request from '../utils/request';
export default {
    //铸币
    postzhubi(data,address,language) {
        return request.request({
            url: '/product/mint',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           data:data
            
        })
    },
    //配置
    getpeizhi(address,language) {
        return request.request({
            url: '/product/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           
            
        })
    },
    //获取团队信息
    gettuandui(address,language) {
        return request.request({
            url: '/product/team-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           
            
        })
    },
    //获取持有列表
    getproductholdlist(address,language) {
        return request.request({
            url: '/product/hold-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
          
            
        })
    },
    //获取团队列表
    getproductteamlist(address,language) {
        return request.request({
            url: '/product/team-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
          
            
        })
    },
     //今日收益
     getjinrishoyi(address,language) {
        return request.request({
            url: '/product/reward',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
          
            
        })
    },
     //收益信息
     getshoyixinxi(address,language) {
        return request.request({
            url: '/product/reward-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
          
            
        })
    },
     //我的市场
     getproductmarketinfo(address,language) {
        return request.request({
            url: '/product/market-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
        
            
        })
    },
}