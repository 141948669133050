<template>
    <div>
      <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 32px;">
        <div style="display: flex; align-items: center;">
          <img src="@/assets/imgsnowball/BGC_icon.png" style="margin-right: 6px; width: 52px; height: 35px;" alt="">
          <span style="color: #94959B; font-size: 14px;">BGC</span>
        </div>
        <div style="display: flex; align-items: center;">
          <div style="display: flex; align-items: center; font-size: 11px;">
          <span :style="{color:lastchartData.growth >= 0 ? '#43CF7C':'#FF4444'}"> {{ lastchartData.growth >= 0 ? '↑' : '↓' }} {{ lastchartData.growth }} %</span>  
          </div>
          <div style="font-size: 24px; font-weight: bold; color: #ffffff; margin-left: 10px;">
            ${{ lastchartData.value }}
          </div>
        </div>
      </div>
      <div ref="chart" style="width: 100%; height: 300px;"></div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  import { mapState } from 'vuex';
  import { bp_api } from '@/api';
  
  export default {
    data() {
      return {
        chart: null,
        selectedIndex: null, // 记录点击的柱子索引
        chartData: [], // 所有数据
        lastchartData: {}, // 最新数据
        xAxisLabels: [], // X 轴标签
        currentStartIndex: 0, // 当前显示的数据起始索引
        displayCount: 7 // 每次显示的数据条数
      };
    },
    computed: {
      ...mapState(['account', 'lang']),
    },
    mounted() {
      this.getChartData();
      this.addSwipeListeners(); // 添加滑动事件监听
    },
    watch: {
      account() {
        this.getChartData();
      }
    },
    methods: {
      // 获取图表数据
      getChartData() {
        if (!this.account) return;
        bp_api.tokenchart(this.account, { token_key: 'BGC', day: 30 }).then(res => {
          if (res.data.chart == null) return;
          let data = res.data.chart;
  
          this.xAxisLabels = data.map(item => this.formatDate(item.grant_date));
          this.chartData = data.map((item, index) => {
            // 计算涨跌幅
            let growth = 0;
            if (index > 0) {
              const prevValue = data[index - 1].eq_usd; // 前一天的价格
              growth = ((item.eq_usd - prevValue) / prevValue) * 100; // 涨跌幅公式
              growth = parseFloat(growth.toFixed(2)); // 保留两位小数
            }
  
            return {
              value: item.eq_usd,
              growth: growth, // 动态计算涨跌幅
              date: item.grant_date
            };
          });
  
          // 初始化 lastchartData 为最新数据
          this.lastchartData = this.chartData[this.chartData.length - 1];
  
          // 初始化显示最后7条数据
          this.currentStartIndex = Math.max(this.chartData.length - this.displayCount, 0);
          this.initChart();
        });
      },
  
      // 初始化图表
      initChart() {
        this.chart = echarts.init(this.$refs.chart);
        this.updateChart();
        this.chart.on('click', this.handleClick); // 监听点击事件
      },
  
      // 格式化日期
      formatDate(dateString) {
        const date = new Date(dateString);
        const month = date.getMonth() + 1; // getMonth() 返回的是 0-11，所以要加 1
        const day = date.getDate();
        return `${month}-${day}`;
      },
  
      // 更新图表
      updateChart() {
        const displayData = this.chartData.slice(
          this.currentStartIndex,
          this.currentStartIndex + this.displayCount
        );
        const displayLabels = this.xAxisLabels.slice(
          this.currentStartIndex,
          this.currentStartIndex + this.displayCount
        );
  
        const option = {
          grid: { left: 0, right: 0, bottom: 20, top: 0, containLabel: true },
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
            backgroundColor: '#13172B',
            borderColor: '#3CF4FC',
            borderWidth: 1,
            textStyle: { color: '#ffffff' },
            formatter: (params) => {
              const data = params[0].data;
              const growthColor = data.growth >= 0 ? '#00FF00' : '#FF4444';
              return `
                <div>
                  <div style="font-size: 12px; color: #aaa;">${data.date}</div>
                  <div style="font-size: 12px; color: ${growthColor}; display: flex; align-items: center;">
                    <div style="font-size: 18px; font-weight: bold; color: #ffffff; margin-right: 9px;">$${data.value}</div>
                    <span style="font-size: 14px;">${data.growth >= 0 ? '↑' : '↓'}</span> ${data.growth}%
                  </div>
                </div>
              `;
            }
          },
          xAxis: {
            type: 'category',
            data: displayLabels, // 使用截取后的标签
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              color: '#ddd',
              interval: 0
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#323356',
                type: 'dashed'
              }
            }
          },
          series: [
            {
              type: 'bar',
              barWidth: 14,
              barGap: '20%',
              data: displayData.map((item, index) => ({
                ...item,
                itemStyle: {
                  color: index === this.selectedIndex ? '#42a5f5' : '#144c64'
                },
                label: {
                  show: true,
                  position: 'top',
                  color: '#14676B',
                  fontSize: 14,
                  formatter: `$${item.value}`
                }
              }))
            }
          ]
        };
  
        this.chart.setOption(option);
      },
  
      // 处理柱子点击事件
      handleClick(params) {
        const clickedData = this.chartData[this.currentStartIndex + params.dataIndex]; // 获取点击柱子的数据
        this.selectedIndex = params.dataIndex; // 记录点击索引
        this.lastchartData = clickedData; // 更新 lastchartData
        this.updateChart(); // 重新渲染
      },
  
      // 添加滑动事件监听
      addSwipeListeners() {
        let startX = 0;
        const chartElement = this.$refs.chart;
  
        chartElement.addEventListener('touchstart', (e) => {
          startX = e.touches[0].clientX; // 记录滑动起始位置
        });
  
        chartElement.addEventListener('touchend', (e) => {
          const endX = e.changedTouches[0].clientX; // 记录滑动结束位置
          const deltaX = endX - startX;
  
          if (Math.abs(deltaX) > 50) { // 滑动距离大于50px才触发
            if (deltaX > 0) {
              this.swipeLeft(); // 右滑（查看历史数据）
            } else {
              this.swipeRight(); // 左滑（查看最新数据）
            }
          }
        });
      },
  
      // 右滑（查看历史数据）
      swipeLeft() {
        if (this.currentStartIndex > 0) {
          this.currentStartIndex -= 1;
          this.updateChart();
        }
      },
  
      // 左滑（查看最新数据）
      swipeRight() {
        if (this.currentStartIndex + this.displayCount < this.chartData.length) {
          this.currentStartIndex += 1;
          this.updateChart();
        }
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  div {
    touch-action: pan-y; /* 允许垂直滑动 */
  }
  
  .up {
    color: #43CF7C !important;
  }
  
  .down {
    color: #43CF7C !important;
  }
  </style>