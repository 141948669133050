<template>
  <!-- 社区 -->
  <div class="MintCommunity">
    <van-popup v-model:show="showDialog" round position="bottom">
      <div style="font-weight: 500">
        <div style="padding: 20px; display: flex; justify-content: space-between">
          <span style="font-size: 14px; color: rgba(34, 34, 34, 1)">{{ $t('lang.h392') }}</span>
          <img src="@/assets/img/close_icon.png" width="13px" height="13px" @click="showDialog = false" />
        </div>
        <div style="border: 1px solid rgba(242, 242, 242, 1); height: 1px; width: 100%"></div>
        <div style="margin: 24px 20px">
          <div class="box">
            <img src="@/assets/imgsnowball/USDT.png" />
            <span>{{ nodeconfig.s_pay || 0 }} USDT</span>
          </div>
          <div style="display: flex; align-items: start; margin: 24px 0px">
            <img src="@/assets/imgsnowball/balance_icon.png" width="20px" height="20px" />
            <div style="display: flex; margin-left: 12px; flex-direction: column; width: 100%">
              <span style="margin-bottom: 7px; color: rgba(0, 0, 0, 1); font-size: 16px; font-weight: 700">{{
                $t('lang.h382') }}</span>
              <span style="margin-bottom: 3px; color: rgba(153, 153, 153, 1); font-size: 10px; font-weight: 700">USDT：{{
                walletInfo.USDT.usable }}</span>
              <div style="border: 1px solid rgba(242, 242, 242, 1); height: 1px; width: 100%"></div>
            </div>
          </div>
          <div class="button" @click="bpnodebuy"> <van-loading color="#000" v-if="payLoading" /><span v-else>{{
            $t('lang.h393') }}</span></div>
        </div>
      </div>
    </van-popup>
    <div class="swipelevels">
      <van-swipe class="my-swipe" indicator-color="white" :show-indicators="false" ref="swiperRef"> 
        <van-swipe-item class="swipeitem"
        v-for="(item, index) in levelList"
          :style="{ backgroundImage: 'url(' + item.bgImage + ')' }">
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <div style="display: flex; align-items: center;">
              <img :src="item.icon" style="margin-right: 6px;" alt="">
            <span style="font-size: 20px; font-weight: bold; color: #FFFFFF;">{{ $t(item.name
              )}}</span>
            </div>
            <!-- 普通节点 -->
            <div v-if="item.level == 1">
              <button :style="{ background: item.buttonColor }" class="level-button">{{ nodeinfo.node_level < 1 ? $t('lang.h407') : $t('lang.h408') }}</button>
            </div>
            <!-- 超级节点 -->
            <div v-else-if="item.level == 2">
              <button :style="{ background: item.buttonColor }" class="level-button" @click="showDialogOpen">{{ nodeinfo.node_level < 2 ? $t('lang.h395') :  $t('lang.h396') }}</button>
            </div>
             <!-- 超级联创 -->
            <div v-else-if="item.level == 3"> 
              <button :style="{ background: item.buttonColor }" class="level-button" @click="$toast($t('lang.h409'))">{{ $t('lang.h395') }}</button>
            </div>
            
           
           
          </div>
          <div>
            <van-progress :percentage="nodeinfo.dr_valid_num >= 10 ? 100 : (nodeinfo.dr_valid_num || 0) / 10 *100" :color="item.progressColor"
              track-color="rgba(255, 255, 255, 0.1)" :show-pivot="false" />

            <div :style="{ color: item.textColor }" class="level-text">
              <div> <span>{{ $t('lang.h394') }}</span> <span style="color: #FFFFFF;">{{ nodeinfo.dr_valid_num || 0
                  }}</span></div>
          
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>


    </div>
    <div class="titleBox">
      <div class="titleBox_text">
        <div>{{ $t('lang.h397') }}</div>
        <div>{{ $t('lang.h398') }}</div>
        <div>{{ $t('lang.h399') }}</div>
      </div>
    </div>
    <div>
      <div class="After-title ">{{ $t('lang.h400') }}</div>
    </div>
    <div class="MintList" v-if="childrenlist.length > 0">
      <div class="MintList_item">
        <div>{{ $t('lang.h401') }}</div>
        <div>{{ $t('lang.h402') }}</div>
        <div>{{ $t('lang.h403') }}</div>
      </div>
      <div class="MintList_item" v-for="item in childrenlist">
        <div style="color: #FFFFFF;">{{ item.address }}</div>
        <div>{{ item.self_cp }}</div>
        <div>{{ item.children_cp }}</div>
      </div>
    </div>
    <div class="MintList" v-else style="display: flex; flex-direction: column; align-items: center;">
      <img src="@/assets/img/zanwu.png" style="width: 89px; height: 158px;" alt="">
      <span style="font-size: 16px; color: rgba(255, 255, 255, 1); margin-top: 22px;">{{ $t('lang.h315') }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { bp_api, web3_api } from '@/api/index';
export default {
  data() {
    return {
      levelList: [
        {
          name: 'lang.h404',
          icon: require('@/assets/imgsnowball/MintCommunity_icon1.png'),
          bgImage: require('@/assets/imgsnowball/MintCommunity_bg_level0.png'),
          level: 0,
          textColor: '#FFB545',
          buttonColor:"#2F2C23",
          progressColor: 'linear-gradient(90deg, rgba(255, 181, 69, 1) 0%, rgba(255, 217, 161, 1) 100%)'
        },
        {
          name: 'lang.h386',
          icon: require('@/assets/imgsnowball/MintCommunity_icon2.png'),
          bgImage: require('@/assets/imgsnowball/MintCommunity_bg_level1.png'),
          level: 1,
          textColor: '#324985',
          buttonColor:"#193673",
          progressColor: 'linear-gradient(90deg, #657DB7 0%, #3D6CE3 100%)'
        },
        {
          name: 'lang.h387',
          icon: require('@/assets/imgsnowball/MintCommunity_icon3.png'),
          bgImage: require('@/assets/imgsnowball/MintCommunity_bg_level2.png'),
          level: 2,
          textColor: '#2018AD',
          buttonColor:"#6942EC",
          progressColor: 'linear-gradient(90deg, #6F74A3 0%, #574EFC 100%)'
        },
        {
          name: 'lang.h405',
          icon: require('@/assets/imgsnowball/MintCommunity_icon4.png'),
          bgImage: require('@/assets/imgsnowball/MintCommunity_bg_level3.png'),
          level: 3,
          textColor: '#C79865',
          buttonColor:"#8B746F",
          progressColor: 'linear-gradient(90deg, #463D3B 0%, #B89891 100%)'
        },

      ],
      nodeinfo: {
        node_level: 0
      },
      nodeconfig: {},
      showDialog: false,
      payLoading: false,
      walletInfo: {
        USDT: {}
      },
      childrenlist: []
    }
  },
  components: {

  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {
      this.init()

    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.account) return;
      Promise.all([
        this.bpnodeinfo(),
        this.bpnodeconfig(),
        this.walletinfobykey(),
        this.bpuserchildrenlist()
      ])
    },
    async bpnodebuy() {
      if (this.payLoading) return;
      this.payLoading = true
      let signature = null
      try {
        signature = await web3_api.signMessage('BP Buy Node', this.account)
      } catch (error) {
        this.payLoading = false
      }
      bp_api.bpnodebuy(this.account, { signature }).then(res => {
        if (res.code == 200) {
          this.init()
          this.showDialog = false
        }
        this.payLoading = false
        this.$toast(res.msg)
      })
    },
    bpuserchildrenlist() {
      bp_api.bpuserchildrenlist(this.account).then(res => {
        if (res.code == 200) {
          this.childrenlist = res.data || []
        }
      })
    },
    showDialogOpen() {
      if(this.nodeinfo.node_level ==  2 ) return ;
      this.showDialog = true
    },
    walletinfobykey() {
      bp_api.walletinfobykey(this.account, { token_key: 'USDT' }).then(res => {
        this.walletInfo['USDT'] = res.data
      })
    },
    bpnodeinfo() {
      bp_api.bpnodeinfo(this.account).then(res => {
        if (res.code == 200) {
          this.nodeinfo = res.data;
          this.$refs.swiperRef.swipeTo(this.nodeinfo.node_level);
        }
      })
    },
    bpnodeconfig() {
      bp_api.bpnodeconfig(this.account).then(res => {
        if (res.code == 200) {
          this.nodeconfig = res.data
        }
      })
    }
  },
}

</script>
<style scoped lang='less'>
.MintCommunity {
  min-height: 100vh;
  width: 100%;
  padding: 20px;
}

.MintList {
  border-radius: 3px;
  border: 1px solid rgba(248, 237, 253, 0.2);
  padding: 12px;

  .MintList_item {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgba(248, 237, 253, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    &>div {
      flex: 1;
    }

    &>div:first-child {
      text-align: left;
    }

    &>div:last-child {
      text-align: right;
    }
  }
}

.After-title {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.After-title::after {
  content: '';
  display: block;
  width: 26px;
  height: 5px;
  background-image: url('~@/assets/img/after_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  /* 或者设置为 contain 根据需求调整 */
  background-position: center;
  position: absolute;
  left: 50%;
  bottom: -11px;
  transform: translateX(-50%);
}

.titleBox {
  margin-top: 20px;
  width: 100%;
  height: 218.55px;
  background: url('~@/assets/imgsnowball/MintCommunity_img1.png') no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: end;
  justify-content: center;

  .titleBox_text {
    margin-bottom: 30px;
    font-size: 14px;
    color: #FFFFFF;

    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.swipelevels {
  .swipeitem {
    height: 159.43px;
    opacity: 1;
    border-radius: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .level-button {
        border-radius: 30px;
        border: none;
        color: #FFFFFF;
        padding: 5px 8px;
        font-size: 10px;
      }

    .level-text {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;

    
    }
  }
}

.box {
  border-radius: 8px;
  border: 1px solid rgba(230, 230, 230, 1);
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0px 12px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(0, 0, 0, 1);
  }
}

.button {
  border-radius: 110px;
  background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 22.4px;
  color: rgba(0, 0, 0, 1);
  height: 48px;
}
</style>