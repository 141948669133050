<template>
  <div class="engbill">
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
    <div class="imgboxs">
      <div class="box">
        <img src="@/assets/imgsnowball/eng_img1.png" alt="" />
        <span>{{ $t('lang.h124') }}</span>
      </div>

      <div class="line"></div>
      <div class="box">
        <img src="@/assets/imgsnowball/eng_img2.png" alt="" />
        <span>{{ $t('lang.h125') }} </span>
      </div>
      <div class="line"></div>
      <div class="box">
        <img src="@/assets/imgsnowball/eng_img3.png" alt="" />
        <span>{{ $t('lang.h126') }}</span>
      </div>
    </div>
    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)" :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="list" v-show="regionIndex == 0">
      <div class="li first">
        <div>{{ $t('lang.h127') }}</div>
        <div>{{ $t('lang.h128') }}</div>
        <div>{{ $t('lang.h129') }}</div>
      </div>
      <div class="li" v-for="(item, index) in childrenlist.maxlist" :key="index">
        <div style="color: rgba(255, 255, 255, 1)">{{ item.address }}</div>
        <div style="color: rgba(56, 248, 252, 1)">{{ item.self_cp }}</div>
        <div style="color: rgba(182, 133, 255, 1)">{{ item.team_cp }}</div>
      </div>
    </div>
    <div class="list" v-show="regionIndex == 1">
      <div class="li first">
        <div>{{ $t('lang.h127') }}</div>
        <div>{{ $t('lang.h128') }}</div>
        <div>{{ $t('lang.h129') }}</div>
      </div>
      <div class="li" v-for="(item, index) in childrenlist.otherlsit" :key="index">
        <div style="color: rgba(255, 255, 255, 1)">{{ item.address }}</div>
        <div style="color: rgba(56, 248, 252, 1)">{{ item.self_cp }}</div>
        <div style="color: rgba(182, 133, 255, 1)">{{ item.team_cp }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      childrenlist: {
        maxlist: [],
        otherlsit: [],
      },
      summaryinfo: {},
      regionIndex: 0,
      functionOption: [{ name: 'lang.h107' }, { name: 'lang.h108' }],
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },
  components: {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      if(this.account){
        this.regionIndex = this.$route.query.regionIndex;
        this.nftsummaryinfo();
      }
   
    },
    switchovers(index) {
      this.regionIndex = index;
    },
    toprouter() {
      this.$router.go(-1);
    },
    nftsummaryinfo() {
      nft.nftsummaryinfo(this.account).then(res => {
        this.summaryinfo = res.data.team_info;

        this.nftchildrenlist();
      });
    },
    nftchildrenlist() {
      nft.nftchildrenlist({}, this.account).then(res => {
        let array = res.data;
        if (array != null) {
          const valueToMatch = this.summaryinfo.max_zone_cp;
          const keyToCheck = 'team_cp';
          let matchedArray = [];
          let unmatchedArray = [];

          let found = false;
          unmatchedArray = array.filter(item => {
            if (!found && item[keyToCheck] === valueToMatch) {
              matchedArray.push(item);
              found = true;
              return false; // 不加入unmatchedArray
            }
            return true; // 加入unmatchedArray
          });
          unmatchedArray.sort((a, b) => {
            if (a[keyToCheck] < b[keyToCheck]) {
              return 1;
            }
            if (a[keyToCheck] > b[keyToCheck]) {
              return -1;
            }
            return 0;
          });

          this.childrenlist.maxlist = matchedArray;
          this.childrenlist.otherlsit = unmatchedArray;
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
.functional_region {
  margin-top: 12px;
  padding-top: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  .region {
    display: flex;
     color: rgba(255, 255, 255, 0.6);
    align-items: center;
    div {
      margin-right: 28px;
    }

    .special {
      font-size: 18px;
      color: #fff;
      position: relative !important;
      &::before {
        position: absolute !important;
        width: 36px;
        height: 2px;
        border-radius: 25px;
        background: rgba(56, 248, 252, 1);
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.engbill {
  padding: 20px;
  padding-bottom: 44px;
  min-height: 100vh;
}
.list {
  margin-top: 29px;
  padding: 15px 20px;
  width: 100%;
  border-radius: 16px;
  background: rgba(27, 31, 46, 1);

  border: 1px solid rgba(36, 40, 52, 1);
  .first {
    color: rgba(153, 153, 153, 1);
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .li {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    & > div {
      width: calc(100% / 3);
      text-align: center;
    }
    & > div:first-child {
      text-align: left;
    }
    & > div:last-child {
      text-align: right;
    }
  }
}
.imgboxs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    img {
      width: 49px;
      height: 47px;
      margin-bottom: 11px;
    }
  }

  .line {
    width: 42px;
    height: 1px;
    border-bottom: 1px dotted rgba(56, 248, 252, 1);
  }
}
</style>