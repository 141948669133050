<template>
    <div class="earn">
        <div class="e_main"
            :style="`background: url(${ColorCheckData1 == 'black' ? bglist.image : bglist.image1}) no-repeat;`">
            <span class="m_title">{{ $t('lang.h49') }}</span>
            <span class="m_text">{{ $t('lang.swap367') }}</span>
            <div class="m_button">
                <span class="b_title">{{ $t('lang.swap368') }}</span>
                <span class="b_text">{{ $t('lang.swap369') }}</span>
            </div>
        </div>
        <van-tabs v-model="active" animated class="van_tabs" background="var( --box-bg-color)">
            <van-tab :title="`${$t('lang.d42')}`" background="var( --box-bg-color)">
                <div class="i_lon">
                    <div class="recruit">
                        <div class="recruit_title">
                            <img src="@/assets/img/recruit_logo.png" class="recruit_title_left" alt="">
                            <div class="recruit_title_right">
                                <div style="color: rgba(69, 131, 255, 1); font-size: 24px; font-weight: bold;">
                                    Btpi</div>
                                <div style="color: rgba(255, 255, 255, 1); font-size: 18px;font-weight: bold;">{{
                                    $t('lang.h347') }}</div>
                                <div class="recruit_title_right_button" style="background: rgba(69, 131, 255, 1); color: #fff;" @click="goRecuitMember" v-if="smConfigData.timeStatus == '2'">{{ $t('lang.h335') }}
                                </div>
                                <div class="recruit_title_right_button" v-else-if="smConfigData.timeStatus == '1'">{{ $t('lang.h184') }}
                                </div>
                                <div class="recruit_title_right_button"  v-else-if="smConfigData.timeStatus == '3'" style="background: rgba(109, 118, 135, 1); color: rgba(255, 255, 255, 1);" >{{ $t('lang.h336') }}
                                </div>
                            </div>
                        </div>

                        <div class="recruit_text">
                            <span>{{ $t('lang.h348') }}</span>
                        </div>
                    </div>

                </div>
            </van-tab>
            <van-tab :title="`${$t('lang.d54')}`">
                <div class="i_lon">
                    <div class="recruit" v-if="smConfigData.timeStatus == '2'">
                        <div class="recruit_title">
                            <img src="@/assets/img/recruit_logo.png" class="recruit_title_left" alt="">
                            <div class="recruit_title_right">
                                <div style="color: rgba(69, 131, 255, 1); font-size: 24px; font-weight: bold;">
                                    Btpi</div>
                                <div style="color: rgba(255, 255, 255, 1); font-size: 18px;font-weight: bold;">{{
                                    $t('lang.h347') }}</div>
                                <div class="recruit_title_right_button" style="background: rgba(69, 131, 255, 1); color: #fff;" @click="goRecuitMember">{{ $t('lang.h335') }}
                                </div>
                            </div>
                        </div>

                        <div class="recruit_text">
                            <span>{{ $t('lang.h348') }}</span>
                        </div>
                    </div>

                    <div class="meiyou" v-else>
                        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData1 == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
                        <span>{{ $t('lang.h50') }}</span>
                    </div>
                </div>
            </van-tab>
            <van-tab :title="`${$t('lang.swap370')}`">
                <div class="i_lon">
                    <div class="recruit" v-if="smConfigData.timeStatus == '1'">
                        <div class="recruit_title">
                            <img src="@/assets/img/recruit_logo.png" class="recruit_title_left" alt="">
                            <div class="recruit_title_right">
                                <div style="color: rgba(69, 131, 255, 1); font-size: 24px; font-weight: bold;">
                                    Btpi</div>
                                <div style="color: rgba(255, 255, 255, 1); font-size: 18px;font-weight: bold;">{{
                                    $t('lang.h347') }}</div>
                             
                                <div class="recruit_title_right_button">{{ $t('lang.h184') }}
                                </div>
                                
                            </div>
                        </div>

                        <div class="recruit_text">
                            <span>{{ $t('lang.h348') }}</span>
                        </div>
                    </div>

                    <div class="meiyou" v-else>
                        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData1 == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
                        <span>{{ $t('lang.h50') }}</span>
                    </div>
                </div>
            </van-tab>
            <van-tab :title="`${$t('lang.swap359')}`">
                <div class="i_lon">
                    <div class="recruit" v-if="smConfigData.timeStatus == '3'">
                        <div class="recruit_title">
                            <img src="@/assets/img/recruit_logo.png" class="recruit_title_left" alt="">
                            <div class="recruit_title_right">
                                <div style="color: rgba(69, 131, 255, 1); font-size: 24px; font-weight: bold;">
                                    Btpi</div>
                                <div style="color: rgba(255, 255, 255, 1); font-size: 18px;font-weight: bold;">{{
                                    $t('lang.h347') }}</div>
                                
                                <div class="recruit_title_right_button" style="background: rgba(109, 118, 135, 1); color: rgba(255, 255, 255, 1);" >{{ $t('lang.h336') }}
                                </div>
                            </div>
                        </div>

                        <div class="recruit_text">
                            <span>{{ $t('lang.h348') }}</span>
                        </div>
                    </div>

                    <div class="meiyou" v-else>
                        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData1 == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
                        <span>{{ $t('lang.h50') }}</span>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { sm_api } from '@/api/index';
export default {
    name: 'earn',
    components: {

    },
    watch:{
        account(){
            this.init()
        }
    },
    data() {
        return {
            smConfigData: {
                timeStatus: 1
            },
            sipper: 50,
            active: '',
            selectlist: [
                {
                    name: "全部"
                },
                {
                    name: "进行中"
                },
                {
                    name: "即将到来"
                },
                {
                    name: "已完成"
                }
            ],
            datalist: [
                { ishidden: false, }, { ishidden: false, }, { ishidden: false, }
            ],
            bglist: {
                image: require('@/assets/img/earnimg.png'),
                image1: require('@/assets/img/earnimg1.png'),
            },
        }
    },
    computed: {
        ...mapState(['account', 'lang', 'ColorCheckData1']),
    },
    created() {
           this.init()
    },
    methods: {
        init(){
            if(this.account){
                this.smconfig()
            }
          
        },
        smconfig(){
            sm_api.smconfig(this.account).then(res=>{
                     if(res.code == 200){
                        this.smConfigData = res.data
                        this.smConfigData.timeStatus = this.checkTimeRange(this.smConfigData.begin_time, this.smConfigData.end_time)
                     }
            })
        },
        ishiddenFun(item) {
            item.ishidden = !item.ishidden
        },
        goRecuitMember() {
            this.$router.push({ name: 'RecuitMember' })
        },
        checkTimeRange(startTime, endTime) {
            const currentTime = new Date();
            const start = new Date(startTime);
            const end = new Date(endTime);

            if (currentTime < start) {
                return '1'; // 还没开始
            } else if (currentTime >= start && currentTime <= end) {
                return '2'; // 进行中
            } else {
                return '3'; // 已结束
            }
        }
    },
}
</script>

<style lang="less" scoped>
.recruit {
    border-radius: 20px;
    background: rgba(45, 58, 82, 1);
    padding: 30px 19px;
    width: 100%;

    .recruit_text {
        margin-top: 21px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 16.8px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: middle;
    }

    .recruit_title {
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .recruit_title_left {
            width: 119px;
            height: 119px;
        }

        .recruit_title_right {
            height: 119px;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .recruit_title_right_button {
                font-size: 16px;
                color: rgba(0, 0, 0, 1);
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 11px 20px;
                border-radius: 10px;
                background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);

            }
        }
    }
}

.earn {
    padding-bottom: 30px;
}

.e_main {
    text-align: center;
    width: 100%;
    height: 471px;

    background-size: contain !important;
    background-position: 50% 50% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .m_title {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32.82px;
        color: var(--text-color);

        margin-top: 40px;
    }

    .m_text {
        margin-top: 10px;
        opacity: 0.6;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18.75px;
        color: var(--text-color);
        text-align: center;
        width: 84%;
    }

    .m_button {
        padding: 10px;
        width: 336px;
        height: 60px;
        opacity: 1;
        border-radius: 124px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        position: absolute;
        bottom: 35px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        .b_title {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0px;
            line-height: 18.75px;
            color: rgba(34, 34, 34, 1);
            margin-bottom: 5px;
        }

        .b_text {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 16.41px;
            color: rgba(34, 34, 34, 1);

        }
    }
}

.van_tabs {

    border-radius: 30px 30px 0 0;
    overflow: hidden;
    margin-bottom: 50px;
}

/deep/.van-tabs__content {

    border: none !important;
}

/deep/.van-tabs__nav {
    background: var(--box-bg-color) !important;
    border: none !important;

}

/deep/.van-tabs__line {
    background: rgba(50, 160, 255, 1) !important;
    width: 20px;
    height: 2px;
    border: none !important;
}

/deep/.van-tab {
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(153, 153, 153, 1);
}

/deep/.van-tab--active {
    border: none !important;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;
    color: var(--text-color);
}

.i_lon {
    border: none !important;
    background: var(--box-bg-color);
    width: 100%;
    padding: 20px;
    min-height: 400px;
    padding-bottom: 100px;
    // margin-top: 20px;

    position: relative;

    .lon_list {
        margin-bottom: 26px;
        width: 100%;
        padding: 38px 19px;
        opacity: 1;
        border-radius: 16px;

        border: 1.5px solid rgba(201, 223, 255, 1);

        .lon_line1 {
            margin-bottom: 37px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .line1_left {
                display: flex;
                align-items: center;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 21px;
                color: rgba(56, 56, 56, 1);

                img {
                    width: 58px;
                    height: 85px;
                }
            }

            .line1_right {
                width: 81px;
                height: 28px;
                opacity: 1;
                border-radius: 75px;

                border: 1px solid rgba(201, 223, 255, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 18.2px;
                color: rgba(0, 0, 0, 1);

                .quare {
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    opacity: 1;
                    background: rgba(69, 131, 255, 1);
                    margin-right: 8px;
                }

            }
        }

        .lon_line2 {
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 21px;
            color: rgba(102, 102, 102, 1);
        }

        .lon_line3 {
            margin-bottom: 37px;
            font-size: 34px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 21px;
            color: rgba(56, 56, 56, 1);
        }

        .lon_line4 {
            margin-bottom: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 14px;
            color: rgba(153, 153, 153, 1);
        }

        .lon_line5 {
            width: 100%;
            padding: 5px 4px;
            height: 52px;
            opacity: 1;
            border-radius: 82px;
            background: rgba(240, 245, 254, 1);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .line5_num {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 27px;
                color: rgba(0, 0, 0, 1);
            }

            .line5_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 44px;
                width: 44px;
                height: 44px;
                font-size: 32px;
                font-weight: bold;
                color: var(--text-color);
                background: rgba(201, 223, 255, 1);
                border-radius: 50%;
            }
        }

        .lon_line6 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            opacity: 1;
            border-radius: 112px;
            background: rgba(69, 131, 255, 1);
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 18px;
            color: var(--text-color);
        }

        .lon_line7 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 11px;

            .line7_left {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
            }

            .line7_right {
                font-size: 14px;
                font-weight: 900;
                letter-spacing: 0px;
                line-height: 21px;
                color: rgba(34, 34, 34, 1);
            }
        }

        .lon_line8 {
            margin-bottom: 20px;
            width: 100%;
            height: 60px;
            opacity: 1;
            border-radius: 16px;
            background: rgba(240, 245, 254, 1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 11px 0 17px;

            .line8_left {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 27px;
                color: rgba(0, 0, 0, 1);
            }

            .line8_right {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 67px;
                height: 41px;
                opacity: 1;
                border-radius: 8px;
                background: rgba(69, 131, 255, 1);
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 24px;
                color: var(--text-color);
            }
        }

        .lon_line9 {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 27px;
            color: rgba(69, 131, 255, 1);
        }

        .lon_line10 {
            height: 0px;
            overflow: hidden;

            transition: .5s;

            .line10_text {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
                margin-bottom: 21px;
            }
        }
    }

    .meiyou {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;



        img {
            width: 89px;
            height: 158px;
            margin-bottom: 18px;
        }

        span {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 19.6px;
            color: var(--text-color);
        }
    }
}
</style>