import request from '../utils/request';
export default {
    sminfo(address){
        return request.request({
            url: '/sm/info',
            method: "get",
            headers:{
                Authorization : address,
                
            },
        })
    },
    smjoin(data,address){
        return request.request({
            url: '/sm/join',
            method: "post",
            headers:{
                Authorization : address,
                
            },
            data
        })
    },
    smchildrenlist(address){
        return request.request({
            url: '/sm/children-list',
            method: "get",
            headers:{
                Authorization : address,
                
            },
        })
    },
    smsummary(address){
        return request.request({
            url: '/sm/summary',
            method: "get",
            headers:{
                Authorization : address,
                
            },
        })
    },
    smpaydiscount(params,address){
        return request.request({
            url: '/sm/pay-discount',
            method: "get",
            headers:{
                Authorization : address,
                
            },
            params
        })
    },
    smconfig(address){
        return request.request({
            url: '/sm/config',
            method: "get",
            headers:{
                Authorization : address,
                
            },
        })
    },
}