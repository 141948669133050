import configURL from "@/config/configUrl/configUrl";
import Clipboard from 'clipboard';
export const isEmptyObject = (obj) => {
    // 检查对象是否为空
    return Object.keys(obj).length === 0;
};

export const isString = (value) => typeof value === 'string';


export const isNumber = (value) => typeof value === 'number';

export  const getFullImagePath = (imagePath) => {
    const apiPrefix = configURL.http_url; // 替换为你的接口前缀
    // 判断路径是否以 'http' 或 'https' 开头
    if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
        return imagePath; // 如果是完整路径，则直接返回
    }

    // 如果不是完整路径，拼接接口前缀并返回
    return apiPrefix + imagePath;
}

export const   handleDecimals = (a, b, operator) => {
    // 将两个数转换为字符串形式
    let aStr = a.toString();
    let bStr = b.toString();

    // 获取小数点后的位数
    let aDecimals = aStr.includes('.') ? aStr.split('.')[1].length : 0;
    let bDecimals = bStr.includes('.') ? bStr.split('.')[1].length : 0;

    // 找到小数点后最长的位数，用来标准化两个数
    let multiplier = Math.pow(10, Math.max(aDecimals, bDecimals));

    // 将两个数转为整数
    let intA = Math.round(a * multiplier);
    let intB = Math.round(b * multiplier);

    let result;

    // 根据操作符进行相应的运算
    switch (operator) {
        case 'add': // 加法
            result = (intA + intB) / multiplier;
            break;
        case 'subtract': // 减法
            result = (intA - intB) / multiplier;
            break;
        case 'multiply': // 乘法
            result = (intA * intB) / (multiplier * multiplier);
            break;
        case 'divide': // 除法
            result = (intA / intB);
            break;
        default:
            throw new Error('无效的操作符');
    }

    return result;
}

export const startsWithDash = (str) => {
    return str.charAt(0) === '-' || str[0] === '-';
}


export const copyText  = (doccument)  => {
    return new Promise((resolve, reject) => {
   
     
      const clipboard = new Clipboard(doccument);
      clipboard.on('success', e => {
        resolve('Text copied to clipboard');
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        reject('Could not copy text: ' + err);
        clipboard.destroy(); // 释放内存
      });
    });
  }
  
  export const formatReadableTime = (dateString) => {
    // 创建一个 Date 对象
    const date = new Date(dateString);

    // 格式化日期为 YYYY-MM-DD HH:mm:ss
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // 拼接为可读格式
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const  getInviteCodeFromURL=(paramsName) => {
    // 获取当前页面的URL
    const url = window.location.href;

    // 检查是否有 hash 部分
    const hashIndex = url.indexOf('#');
    let queryParams = '';

    // 如果有 hash，提取 hash 后的查询参数
    if (hashIndex !== -1) {
        const hashPart = url.substring(hashIndex + 1);
        const queryIndex = hashPart.indexOf('?');
        if (queryIndex !== -1) {
            queryParams = hashPart.substring(queryIndex + 1);
        }
    }

    // 使用 URLSearchParams 获取查询参数
    const params = new URLSearchParams(queryParams);

    // 检查是否存在 invite_code 参数
    if (params.has(paramsName)) {
        // 获取 invite_code 参数的值
        const inviteCode = params.get(paramsName);
        return inviteCode;
    } else {
        // 如果没有 invite_code 参数，直接返回
        return null;
    }
}

// 防抖函数
export const debounce = (func, delay) => {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  export const base64ToBlobUrl = (base64String, fileType) => {
    // 移除 Base64 前缀（如 "data:image/png;base64,"）
    const base64Content = base64String.split(',')[1];
  
    // 解码 Base64 为字节数组
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    // 创建 Blob 对象
    const blob = new Blob([byteArray], { type: fileType });
  
    // 生成 Blob URL
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }
  export const base64ToFile = (base64String, fileName, fileType) => {
    // 验证 Base64 字符串的格式是否正确
    if (!/^data:[a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+;base64,/.test(base64String)) {
      throw new Error('Invalid Base64 string');
    }
  
    // 移除前缀
    const base64Content = base64String.split(',')[1];
  
    try {
      // 解码 Base64 字符串为字节数组
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
  
      // 创建 Blob 并转换为 File 对象
      const blob = new Blob([byteArray], { type: fileType });
      return new File([blob], fileName, { type: fileType });
    } catch (error) {
      console.error('Error decoding Base64:', error);
      throw new Error('Failed to decode Base64 string');
    }
  }

  export const generateCharHash =(length) => {
    // 生成 16 个字符的随机哈希字符串
    let hash = '';
    for (let i = 0; i < length; i++) {
      // 生成一个随机的 0 到 255 的数字，并转换为两位十六进制字符串
      hash += Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
    }
    return hash.substring(0, 16); // 确保字符串长度为 16
  }

 