<template>
    <div class="transferPage">
        <van-popup v-model="selectPopShow" round position="bottom" closeable>
            <div class="selectPopShow">
                <div class="selectPopShow_title">
                    {{ $t('lang.h416') }}
                </div>
                <div class="selectPopShow_box" v-for="(item, index) in TokensList" :key="index"
                    @click="chooseTokenFun(item)">
                    <div class="selectPopShow_box_left">
                        <img :src="getFullImagePath(item.logo)" class="selectPopShow_box_left_logo" alt="">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
            </div>
        </van-popup>
        <div class="check_account">
            <div class="check_account_box" @click="checkTokensShowFun('from')"> {{ isEmptyObject(fromCur) ?
                $t('lang.h418') : fromCur.name }} </div>
            <div @click="changeFun"
                :class="focusType == 'from' ? 'check_account_logo' : 'check_account_logo check_account_logo_tran'">
                <img src="@/assets/imgsnowball/transfer_check.png" alt="">
            </div>
            <div class="check_account_box" @click="checkTokensShowFun('to')"
                style="border: 1px solid rgba(180, 135, 255, 0.46); background: transparent; color: rgba(255, 255, 255, 0.6);">
                {{ isEmptyObject(toCur) ? $t('lang.h418') : toCur.name }}</div>
        </div>
        <div class="transfer_box" style="padding-top: 20px;">
            <div class="transfer_box_title">{{ $t('lang.h163') }}</div>
            <div class="transfer_box_input">
                <input type="number" v-model="exchangeNum" :placeholder="`${$t('lang.h164')}`" />
                <div class="transfer_box_input_All" @click="allexchangeNumFun">{{ $t('lang.h165') }}</div>
            </div>
            <div class="transfer_box_banlace" v-show="!isEmptyObject(fromCur)">
                {{ fromCur.name }} {{ $t('lang.h118') }}：{{ fromCurBanlace }}
            </div>
            <div class="transfer_box_banlace" v-show="!isEmptyObject(toCur)">
                {{ toCur.name }} {{ $t('lang.h118') }}：{{ toCurBanlace }}
            </div>
            <div class="transfer_box_between">
                <div class="transfer_box_between_left" style="display: flex; align-items: center;"
                    @click="changeCurPriceFunc">
                    {{ $t('lang.h419') }}
                    <img src="@/assets/img/Convert_icon.png" style="width: 14px; height: 14px; margin-left: 5px;"
                        alt="">
                </div>
                <div class="transfer_box_between_right">
                    {{ ComExchangePriveCur }}
                </div>
            </div>
            <div class="transfer_box_between">
                <div class="transfer_box_between_left">
                    {{ $t('lang.h420') }} :
                </div>
                <div class="transfer_box_between_right">
                    {{ ComTaxRate }}
                </div>
            </div>
            <div class="transfer_box_between">
                <div class="transfer_box_between_left">
                    {{ $t('lang.h421') }} :
                </div>
                <div class="transfer_box_between_right">
                    {{ ComExchangeGetToCurNum }} {{ toCur.name }}
                </div>
            </div>
            <button class="transfer_box_button" @click="exchangedo">
                <van-loading color="#000" v-if="payLoading" />
                <span v-else>{{ $t('lang.h417') }}</span>

            </button>
        </div>
        <div class="title">{{ $t('lang.h169') }}</div>
        <div class="transfer_box" v-if="transferList.list.length > 0">
            <van-list v-model="transferList.Listloading" :finished="transferList.Listfinished"
                :finished-text="$t('lang.d145')" @load="ListonLoad">
                <div class="transfer_box_li" v-for="(item, index) in transferList.list" :key="index">
                    <div class="transfer_box_li_left">
                        <div style="margin-bottom: 5px;">{{ item.t1_name }} {{ $t('lang.h428') }} {{ item.t2_name }}</div>
                        <div style="opacity: 0.6;">{{ $t('lang.h429') }} : <span>{{ item.amount }}</span> </div>
                    </div>
                    <div class="transfer_box_li_right">
                        <div style="color: #30FFFC; margin-bottom: 5px;">+ {{ item.real_exchange_amount }}</div>
                        <div style="opacity: 0.6;">{{ item.created_time }}</div>
                    </div>
                </div>
            </van-list>

        </div>
        <div class="transfer_box" v-else style="display: flex; flex-direction: column;align-items: center;">
            <img src="@/assets/img/zanwu.png" class="wujilu" alt="" />
            <span style="color: rgba(255, 255, 255, 1); font-size: 16px; "> {{ $t('lang.h174') }}</span>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import { bp_api, web3_api } from '@/api';
import { isEmptyObject, getFullImagePath } from '@/utils/pettern';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
export default {
    data() {
        return {
            checkBool: '0',
            exchangeNum: '',
            selectPopShow: false,
            focusType: 'from',
            changeCurPrice: 'to',
            defultCurList: [],
            exchangeCurList: {},
            TokensList: [],
            fromCur: {},
            toCur: {},
            fromCurBanlace: 0,
            toCurBanlace: 0,
            payLoading: false,
            transferList: {
                list: [],
                Listloading: false,
                Listfinished: false,
                Info: {
                    page: 1,
                    page_size: 10,
                }
            }
        }
    },
    watch: {
        account() {
            //获取账号
            this.init();
        },
        lang() {
            this.init();
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
        ComTax() {
            if (isEmptyObject(this.toCur) || this.exchangeNum == '') return 0;
            return significantDigits(significantDigits(this.exchangeNum * this.toCur.exchange_rate) * (this.toCur.fee_rate))
        },
        ComTaxRate() {
            if (isEmptyObject(this.toCur) || this.exchangeNum == '') return '0%';
            return this.toCur.fee_rate * 100 + '%'
        },

        ComExchangeGetToCurNum() {
            if (isEmptyObject(this.toCur) || this.exchangeNum == '') return 0;
            return significantDigits(significantDigits(this.exchangeNum * this.toCur.exchange_rate) - this.ComTax);
        },


        ComExchangePriveCur() {
            if (isEmptyObject(this.fromCur) || isEmptyObject(this.toCur)) return;
            if (this.changeCurPrice == 'to') {
                return `1 ${this.fromCur.name} ≈ ${this.toCur.exchange_rate} ${this.toCur.name} `
            } else if (this.changeCurPrice == 'from') {
                return `1 ${this.toCur.name} ≈ ${significantDigits(1 / this.toCur.exchange_rate)} ${this.fromCur.name} `
            }

        }
    },
    components: {

    },
    created() {
        this.init()
    },
    methods: {
        isEmptyObject,
        getFullImagePath,
        init() {
            if (this.account) {
                this.initexchangelist();
                this.exchangeconfigs()
            }

        },
        async exchangedo() {
            if (this.exchangeNum == '') return this.$toast(this.$t('lang.h164'));
            if (isEmptyObject(this.toCur)) return this.$toast(this.$t('lang.h422'));
            if (parseFloat(this.exchangeNum) < this.toCur.min_amount) return this.$toast(this.$t('lang.h423', { num: this.toCur.min_amount }));
            if (parseFloat(this.exchangeNum) > this.toCur.max_amount) return this.$toast(this.$t('lang.h424', { num: this.toCur.max_amount }));
            if (this.payLoading) return;
            this.payLoading = true
            let sign = null
            try {
                sign = await web3_api.signMessage('Exchange Do', this.account)
            } catch (error) {
                this.payLoading = false;
                this.$toast(this.$t('lang.d186'))
                return;
            }

            let data = {
                t1_id: this.fromCur.token_id,
                t2_id: this.toCur.token_id,
                amount: parseFloat(this.exchangeNum),
                signature: sign

            }
            bp_api.exchangedo(this.account, data).then(res => {
                if (res.code == 200) {
                    this.getBanlace();
                    this.initexchangelist();
                }
                this.$toast(res.msg)
                this.payLoading = false
            })
        },
        changeCurPriceFunc() {
            if (this.changeCurPrice == 'from') {
                this.changeCurPrice = 'to'
            } else if (this.changeCurPrice == 'to') {
                this.changeCurPrice = 'from'
            }
        },
        chooseTokenFun(item) {
            this.selectPopShow = false;
            if (this.focusType == 'from') {
                this.fromCur = item
                this.toCur = {}
                this.toCurBanlace = 0

            } else if (this.focusType = 'to') {
                this.toCur = item;
            }
            this.getBanlace()
        },
        checkTokensShowFun(type) {
            if (isEmptyObject(this.exchangeCurList) || this.defultCurList.length == 0) return;
            this.focusType = type
            if (type == 'from') {
                this.TokensList = this.defultCurList;

            } else if (type == 'to') {

                if (isEmptyObject(this.fromCur)) return this.$toast(this.$t('lang.h427'));

                this.TokensList = this.exchangeCurList[this.fromCur.name]
            }
            this.selectPopShow = true;
        },
        changeFun() {
            if (isEmptyObject(this.fromCur) || isEmptyObject(this.toCur)) return this.$toast(this.$t('lang.h426'));
            if (this.exchangeCurList[this.toCur.name] == undefined || this.exchangeCurList[this.toCur.name] == null) return this.$toast(this.$t('lang.h425'));
            let isexist = false
            this.exchangeCurList[this.toCur.name].forEach(item => {
                if (item.t2_token == this.fromCur.name) {
                    this.fromCur = item
                    isexist = true

                }
            })
            if (!isexist) return this.$toast(this.$t('lang.h425'));
            let temp = this.fromCur
            this.fromCur = this.toCur
            this.toCur = temp
            this.getBanlace()

        },
        exchangeconfigs() {
            bp_api.exchangeconfigs(this.account).then(res => {
                this.defultCurList = res.data.token_list
                this.exchangeCurList = res.data.exchange_map
                if (this.defultCurList.length == 0 || isEmptyObject(this.exchangeCurList)) return;
                this.defultCurList.forEach(item => {
                    item.name = item.token
                })
                for (const key in this.exchangeCurList) {
                    this.exchangeCurList[key].forEach(item => {
                        item.logo = item.t2_logo
                        item.name = item.t2_token
                        item.token_id = item.t2_id
                    })
                }
                this.fromCur = this.defultCurList[0]
                this.toCur = this.exchangeCurList[this.fromCur.token][0];
                console.log(this.defultCurList, this.exchangeCurList);
                console.log(this.fromCur, this.toCur);

                this.getBanlace()
            })
        },
        getBanlace() {
            bp_api.walletinfo(this.account, { token_id: this.fromCur.token_id }).then(res => {
                this.fromCurBanlace = res.data.usable
            })
            if (isEmptyObject(this.toCur)) return this.fromCurBanlace = 0;
            bp_api.walletinfo(this.account, { token_id: this.toCur.token_id }).then(res => {
                this.toCurBanlace = res.data.usable
            })
        },
        ListonLoad() {

        },
        checkBoolFun() {
            this.checkBool = this.checkBool == '0' ? '1' : '0';
        },
        allexchangeNumFun() {
            this.exchangeNum = this.fromCurBanlace;
        },


        toprouter() {
            this.$router.go(-1);
        },


        initexchangelist() {
            this.transferList = {
                list: [],
                Listloading: false,
                Listfinished: false,
                Info: {
                    page: 1,
                    page_size: 10,
                }

            }
            this.exchangelist()
        },
        exchangelist() {
            this.transferList.Listloading = true
            bp_api.exchangelist(this.account, this.transferList.Info).then(res => {
                if (res.code == 200) {
                    if (res.data.list != null && res.data.list.length > 0) {
                        if (this.transferList.Info.page == 1) {
                            this.transferList.list = res.data.list
                        } else {
                            this.transferList.list = [...this.transferList.list, ...res.data.list]
                        }
                        if (res.data.list.length < this.transferList.Info.page_size) {
                            this.transferList.Listfinished = true
                        } else {
                            this.transferList.Info.page++
                        }
                        this.transferList.Listloading = false
                    } else {
                        this.transferList.Listfinished = true
                    }
                }
            })
        },
    },
}

</script>
<style scoped lang='less'>
/deep/.van-popup {
    background: #FFFFFF;
}

.selectPopShow {
    padding: 20px;

    .selectPopShow_title {
        font-size: 16px;
        color: #222222;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid #F0F0F0;
    }

    .selectPopShow_box {
        margin-top: 20px;
        border: 1px solid #D9D9D9;
        border-radius: 7px;
        padding: 16px;

        .selectPopShow_box_left {
            color: #000000;
            font-size: 14px;
            font-weight: bold;
            display: flex;
            align-items: center;

            .selectPopShow_box_left_logo {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                border-radius: 50%;

            }
        }

    }
}

.transferPage {
    padding: 20px;
    background: rgba(13, 8, 26, 1);
    min-height: 100vh;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin: 30px 0 12px 0;
    color: #fff;
}

.transfer_box {
    margin: 22px 0 30px 0;
    padding: 20px;
    padding-top: 0;
    border-radius: 30px;
    background: rgba(27, 31, 46, 1);

    .wujilu {
        margin: 0 auto;
        width: 89px;
        height: 158px;
        margin-bottom: 16px;
    }

    .transfer_box_li {
        padding: 20px 0;
        border-bottom: 1px solid #292A2E;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        .transfer_box_li_right{
            color: #F8EDFD;
              text-align: right;
        }
        .transfer_box_li_left{
            color: #F8EDFD;
        }
        
    }

    .transfer_box_button {
        margin-top: 33px;
        width: 100%;
        height: 52px;
        opacity: 1;
        border-radius: 110px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(204, 204, 204, 1);
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(0, 0, 0, 1);
        border: none;
    }

    .transfer_box_between {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        margin-top: 12px;

        .transfer_box_between_left {
            color: rgba(255, 255, 255, 0.6);
        }

        .transfer_box_between_right {
            color: rgba(255, 255, 255, 1);
        }
    }

    .transfer_box_title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 16px;

    }

    .transfer_box_banlace {
        margin: 14px 0 0 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 16.8px;
        color: rgba(255, 255, 255, 1);
        opacity: 0.4;
    }

    .transfer_box_input {
        width: 100%;
        height: 52px;
        opacity: 1;
        border-radius: 87px;
        background: rgba(45, 58, 82, 1);
        display: flex;
        padding: 16px 30px;
        font-size: 14px;
        font-weight: 500;

        input {
            flex: 1;
            border: none;
            background: none;
            color: rgba(255, 255, 255, 0.6);
        }

        .transfer_box_input_All {
            border-left: 2px solid rgba(179, 136, 255, 1);
            padding-left: 19.5px;
            color: rgba(179, 136, 255, 1);

            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.check_account {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;

    .check_account_logo_tran {
        transform: rotate(180deg);
    }

    color: rgba(0, 0, 0, 1);

    .check_account_box {
        width: 35%;
        height: 48px;
        opacity: 1;
        border-radius: 30px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .check_account_logo {
        transition: .5s;
        width: 22px;
        height: 22px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>