import request from '../utils/request';
export default {
    //购买
    nftholdbuy(data,address) {
        return request.request({
            url: '/nft/hold/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           data:data
        })
    },
     //领取
     nftholdclaim(data,address) {
        return request.request({
            url: '/nft/hold/claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           data:data
        })
    },
      //持有列表
      nftholdlist(data,address) {
        return request.request({
            url: '/nft/hold/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           params:data
        })
    },
      //持有列表
      nftholdlist(data,address) {
        return request.request({
            url: '/nft/hold/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           params:data
        })
    },
      //账单列表
      nftwalletrecord(data,address) {
        return request.request({
            url: '/nft/wallet/record',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           params:data
        })
    },
     //资产
     nftwalletinfo(address) {
        return request.request({
            url: '/nft/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            }, 
        })
    },
    nftlist(address) {
        return request.request({
            url: '/nft/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            }, 
        })
    },
    nftrankinglist(address) {
        return request.request({
            url: '/nft/ranking/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            }, 
        })
    },
    nftsummaryinfo(address) {
        return request.request({
            url: '/nft/user/summary',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            }, 
        })
    },
    walletinfobykey(data,address) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           params:data
        })
    },
    nftchildrenlist(data,address) {
        return request.request({
            url: '/nft/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           params:data
        })
    },
}