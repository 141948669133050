import pledge_abi from '../pledge_abi.json';
import { BtPi_BNB_PLEDGE_ADDR } from './ConfigAddr.js';
import Web3 from 'web3';
import store from '@/store';
//存入
function stake(myAddress, amount, transactionsDetail) {
  return new Promise(function (resolve, reject) {
    let web3 = new Web3(window.ethereum);
    let crossingOkContractInstance = new web3.eth.Contract(pledge_abi, BtPi_BNB_PLEDGE_ADDR);
    crossingOkContractInstance.methods
      .stake(amount)
      .send({ from: myAddress })
      .on('transactionHash', res => {
        transactionsDetail['hash'] = res;
        store.commit('SETTRANSACTIONS', transactionsDetail);
      })
      .on('confirmation', res => {
        resolve(res)``;
      })
      .on('error', res => {
        reject(res);
      });
  });
}

//取出
function withdraw(myaddress, amount, transactionsDetail) {
  return new Promise(function (resolve, reject) {
    let web3 = new Web3(window.ethereum);
    let crossingOkContractInstance = new web3.eth.Contract(pledge_abi, BtPi_BNB_PLEDGE_ADDR);
    crossingOkContractInstance.methods
      .withdraw(amount)
      .send({ from: myaddress })
      .on('transactionHash', res => {
        transactionsDetail['hash'] = res;
        store.commit('SETTRANSACTIONS', transactionsDetail);
      })
      .on('confirmation', res => {
        resolve(res);
      })
      .on('error', res => {
        reject(res);
      });
  });
}

//奖励数量
function earned(myAddress) {
  return new Promise(function (resolve, reject) {
    let web3 = new Web3(window.ethereum);
    let crossingOkContractInstance = new web3.eth.Contract(pledge_abi, BtPi_BNB_PLEDGE_ADDR);
    crossingOkContractInstance.methods
      .earned(myAddress)
      .call()
      .then(res => {
        resolve(res);
      });
  });
}

//领取奖励
function claimReward(myAddress, transactionsDetail) {
  return new Promise(function (resolve, reject) {
    let web3 = new Web3(window.ethereum);
    let crossingOkContractInstance = new web3.eth.Contract(pledge_abi, BtPi_BNB_PLEDGE_ADDR);
    crossingOkContractInstance.methods
      .claimReward()
      .send({ from: myAddress })
      .on('transactionHash', res => {
        transactionsDetail['hash'] = res;
        store.commit('SETTRANSACTIONS', transactionsDetail);
      })
      .on('confirmation', res => {
        resolve(res);
      })
      .on('error', res => {
        reject(res);
      });
  });
}



//获取当前质押数量
function balance(myaddress) {
  return new Promise(function (resolve, reject) {
    let web3 = new Web3(window.ethereum);
    let crossingOkContractInstance = new web3.eth.Contract(pledge_abi, BtPi_BNB_PLEDGE_ADDR);
    crossingOkContractInstance.methods
      .balanceOf(myaddress)
      .call()
      .then(res => {
        resolve(res);
      });
  });
}

export default {
  stake,
  withdraw,
  earned,
  claimReward,
  balance,
};
