<template>
    <div class="c2cOrder">
     
        <div class="functional_region">
            <div class="region">
                <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index, item.status)"
                    :class="index == regionIndex ? 'special' : ''">
                    <span>{{ $t(item.name) }}</span>
                </div>
            </div>
        </div>
        <div class="list" v-if="BillInfo.list.length > 0">
            <van-list v-model="BillInfo.loading" :finished="BillInfo.finished" :finished-text="`${$t('lang.d145')}`"
                @load="onLoadList">

                <div class="li" v-for="(item, index) in BillInfo.list" :key="index"">
            <div class=" li_item">
                    <div class="li_item_tips" v-if="item.type == '0'|| regionIndex == 1">
                        {{ $t('lang.h290') }}
                    </div>
                    <div class="li_item_tips" style="background: rgba(67, 101, 164, 1); color: #fff;" v-else>
                        {{ $t('lang.h291') }}
                    </div>
                    <div class="li_item_status" @click="circulationordercancel(item)" v-loading="payLoading"
                        style="border: 1px solid rgba(104, 204, 253, 1); color: rgba(104, 204, 253, 1);"
                        v-if="regionIndex == 0">
                        {{ $t('lang.h292') }}
                    </div>
                    <div class="li_item_status" v-else-if="regionIndex == 1"
                        style="border: 1px solid rgba(104, 204, 253, 1); color: rgba(104, 204, 253, 1);">
                        {{ $t('lang.h293') }}
                    </div>
                    <div class="li_item_status" style="opacity: 0.6;" v-else-if="regionIndex == 2">
                        {{ $t('lang.h294') }}
                    </div>
                    <div class="li_item_status" style="opacity: 0.6;" v-else-if="regionIndex == 3">
                        {{ $t('lang.h295') }}
                    </div>
                </div>
                <div class="li_item" v-if="regionIndex !=1">
                    <div class="li_item_left"> {{ item.type == '0' ? $t('lang.h296') : $t('lang.h297') }} : </div>
                    <div class="li_item_right">{{ item.amount }}</div>
                </div>
                <div class="li_item" v-if="item.type == '0'|| regionIndex == 1">
                    <div class="li_item_left">{{ $t('lang.h300') }}:</div>
                    <div class="li_item_right">{{item.amount + item.reward_amount }}</div>
                </div>
                <div class="li_item">
                    <div class="li_item_left">{{ $t('lang.h302') }}:</div>
                    <div class="li_item_right">{{ item.created_at }}</div>
                </div>
                <div class="li_item" v-if="regionIndex == 1">
                    <div class="li_item_left">{{ $t('lang.h298') }}:</div>
                    <div class="li_item_right">{{ item.begin_time }}</div>
                </div>
                <div class="li_item" v-if="regionIndex == 1">
                    <div class="li_item_left">{{ $t('lang.h299') }}:</div>
                    <div class="li_item_right">{{ item.end_time }}</div>
                </div>

               
                <div class="li_item" v-else-if="item.type == '1' && regionIndex == 0">
                    <div class="li_item_left">{{ $t('lang.h301') }}:</div>
                    <div class="li_item_right">{{ item.amount }}</div>
                </div>
            

                <div class="li_item" v-if="regionIndex == 3">
                    <div class="li_item_left">{{ $t('lang.h303') }}:</div>
                    <div class="li_item_right">{{ item.finished_at }}</div>
                </div>

        </div>
        </van-list>

    </div>
    <div class="list" v-else>
        <div class="no_list">
            <img src="@/assets/imgsnowball/no_list_icon.png" class="no_list_icon" alt="">
            <div class="no_data" style="color: rgba(255, 255, 255, 1);margin-top: 22px;">{{ $t('lang.h304') }}</div>
        </div>

    </div>
    </div>
</template>

<script>
import { circulation, web3_api } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    data() {
        return {
            functionOption: [
                {
                    name: 'lang.h305',
                    status: 0

                },
                { name: 'lang.h306' },
                { name: 'lang.h307' },
                { name: 'lang.h308' }
            ],
            payLoading: false,
            regionIndex: '0',
            BillInfo: {
                list: [],
                params: {
                    page: 1,
                    page_size: 10,
                    type: 0
                },
                finished: false,
                loading: false
            }
        }
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    components: {

    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    mounted() {

    },
    created() {
        this.init()
    },
    methods: {
        toprouter() {
            this.$router.go(-1);

        },
        async circulationordercancel(item) {
            if (this.payLoading) return;
            this.payLoading = true
            let sign = null
            try {
                sign = await web3_api.signMessage('Circulation Order Cancel', this.account)
            } catch (error) {
                this.payLoading = false

                return;
            }

            let data = {
                type: parseInt(item.type),
                id: item.id,
                signature: sign

            }
            circulation.circulationordercancel(data, this.account).then(res => {
                if (res.code == 200) {
                    this.init()
                }
                this.payLoading = false
                this.$toast(res.msg)
            })
        },
        switchovers(index) {

            this.regionIndex = index;
            console.log(this.regionIndex);

            this.initBillInfo()
            this.isMatchedFunc()
        },
        init() {
            if (this.account) {
                this.isMatchedFunc()
            }
        },
        initBillInfo() {
            this.BillInfo = {
                list: [],
                params: {
                    page: 1,
                    page_size: 10,
                    type: this.regionIndex
                },
                finished: false,
                loading: false
            }
        },
        onLoadList() {
            if(this.account){
                this.isMatchedFunc()
            }
           
        },
        isMatchedFunc() {
            if (this.regionIndex == 1) {
                this.circulationuserstorelist()
            } else {
                this.circulationuserorderlist()
            }
        },

        circulationuserorderlist() {
            this.BillInfo.loading = true
            circulation.circulationuserorderlist(this.BillInfo.params, this.account).then(res => {
                if (res.data.list.length > 0) {
                    if (this.BillInfo.params.page == 1) {
                        this.BillInfo.list = res.data.list
                    } else {
                        this.BillInfo.list = this.BillInfo.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.BillInfo.params.page_size) {
                        this.BillInfo.finished = true
                    } else {
                        this.BillInfo.params.page += 1
                    }
                    this.BillInfo.loading = false
                } else {
                    this.BillInfo.finished = true
                }

            })
        },
        circulationuserstorelist() {
            this.BillInfo.loading = true
            circulation.circulationuserstorelist(this.BillInfo.params, this.account).then(res => {
                if (res.data.list.length > 0) {
                    if (this.BillInfo.params.page == 1) {
                        this.BillInfo.list = res.data.list
                    } else {
                        this.BillInfo.list = this.BillInfo.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.BillInfo.params.page_size) {
                        this.BillInfo.finished = true
                    } else {
                        this.BillInfo.params.page += 1
                    }
                    this.BillInfo.loading = false
                } else {
                    this.BillInfo.finished = true
                }

            })
        },
    },
}

</script>
<style scoped lang='less'>
.recruit_title {
    color: rgba(255, 255, 255, 1);
    padding: 0px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.functional_region {

    padding: 20px 20px 0;
    padding-top: 0;

    .region {
        display: flex;
        width: 100%;
        line-height: 22px;
        font-size: 16px;
        border-radius: 30px;
        padding: 3px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: rgba(160, 209, 204, 1);
        font-weight: 600;
        // background:  rgba(69, 131, 255, 1);
        border-radius: 34px;
        // margin: 20px 0;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            text-align: center;
            // width: 192rpx;
            padding: 16px 0;
        }

        .special {
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            font-weight: bold;
            position: relative;
        }

        .special::after {
            content: '';
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            width: 35px;
            height: 2px;
            border-radius: 91px;
            background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        }
    }
}

.c2cOrder {
   min-height: 100vh;
    padding-bottom: 44px;

}

.list {
    padding: 19px 18px;

    .no_list {
        height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;

        .no_list_icon {
            width: 100px;
            height: 96px;
        }
    }


    .li {
        margin-bottom: 15px;
        border-radius: 10px;
        padding: 17px 14px;
        border-radius: 10px;
        background: rgba(27, 31, 46, 1);

        border: 1px solid rgba(0, 0, 0, 1);

        .li_item {
            margin-bottom: 19px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            .li_item_right {
                color: rgba(255, 255, 255, 1);
            }

            .li_item_status {
                width: 79px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 700;
                border-radius: 6px;
                border: 1px solid rgba(102, 180, 174, 1);
                color: rgba(102, 180, 174, 1);
            }

            .li_item_tips {
                padding: 10px 28px;
                font-size: 14px;
                font-weight: 700;
                color: #000;
                border-radius: 6px;
                background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
            }
        }
    }
}
</style>