<template>
  <div>
     <div class="back">
        <van-icon name="down" style="transform: rotate(90deg);" color="#FFFFFF" @click="goback" />
        <div style="font-size: 20px; font-weight: bold;color: #FFFFFF;">{{  $t($route.meta.name)  }}</div>
        <div style="width: 20px;"></div>
     </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
      goback(){
          this.$router.go(-1)
      }
  },
}

</script>
<style scoped lang='less'>
.back{
    padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
}
</style>