<template>
  <div class="chobi">
    <div class="chozhi">
      <div class="tonzhen" @click="tonzhenfunis">
        <span>{{ stokenname }}</span>
        <img src="@/assets/imgsnowball/xiala.png" alt="" />
      </div>
      <van-popup v-model="tonzhenshow" round position="bottom" class="vantonzhenshow">
        <div class="t_li" v-for="(item, index) in tokens" @click="seclettokenfun(item)" :key="index">
          <img :src="`${imgurl}${item.logo}`" alt="" />
          <span style="color: var(--text-color);"> {{ item.token }}</span>
        </div>

        <div class="quxiao">
          <span @click="tonzhenfunis">{{ $t('lang.swap154') }}</span>
        </div>
      </van-popup>
      <p class="c_shul">{{ $t('lang.d28') }}</p>
      <van-field type="number" v-model="toInput" input-align="left" @input="shoxufei" :placeholder="`${$t('lang.d29')} `" class="van-field" />
      <div class="line3">
        <p class="balance" v-show="shoxu">{{ $t('lang.d83') }}{{ sfee }}</p>
        <p class="balance" v-show="yuen">{{ $t('lang.d30') }}{{ balance }}</p>
      </div>

      <p class="c_shul">{{ $t('lang.d31') }}</p>

      <div class="tonzhen ruzhang" v-if="!inputcheck">
        <span style="color: var( --text-color);">{{ addressshow }} </span>
        <div class="r_line"></div>
        <!-- @click="inputcheck =true"  -->
        <img src="@/assets/img/bianji.png" alt="" style="opacity: 0.4" />
      </div>
      <div v-else style="width: 100%; background: #313235; display: flex; justify-content: space-between; align-items: center; border-radius: 10px">
        <van-field type="text" v-model="toaddress" input-align="left" style="height: 52px; width: 80%" :placeholder="`${$t('lang.d29')} `" class="van-field" />
        <div style="margin-right: 20px; color: var( --text-color); font-weight: bold" @click="toaddressque"><van-icon name="success" /></div>
      </div>
      <div style="margin-top: 10px; font-size: 14px;color: #FFFFFF;" v-show="tokenId">
        <div>{{ $t('lang.d99') }}</div>
        <div>
          {{ $t('lang.d101', { min_withdraw: min_withdraw, token_name: token_name }) }}
        </div>
        <!-- <div>
          {{ $t('lang.d100') }}
        </div> -->
        <div>
          {{ $t('lang.d102',{ min_fee: sindex.min_fee, token_name: token_name }) }}
        </div>
      </div>
      <div class="c_button" v-if="showbutton" @click="sendtranform">{{ $t('lang.d26') }}</div>
      <div class="c_button" v-else style="opacity: 0.6;">{{ $t('lang.d26') }}</div>
    </div>
    <p class="c_jilu">{{ $t('lang.d32') }}</p>
    <div class="c_wlist" v-if="orderlist.length == 0">
      <img src="@/assets/imgsnowball/wujilu.png" alt="" />
      <p>{{ $t('lang.d57') }}</p>
    </div>
    <div class="c_list" v-else>
      <van-list v-model="loading1" :finished="finished1" :finished-text="`${$t('lang.d145')}`" @load="onLoad1" class="list_box">
        <div class="li" v-for="(item,index) in orderlist" :key="index">
        <div>
          <div class="c_head">{{ item.status_txt }}{{ item.token_name }}</div>
          <div>{{ item.created_time }}</div>
        </div>
        <span style="color: red">- {{ item.amount }}</span>
      </div>
      </van-list>
      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { bizhong, homedata, moneymask, tibi } from '@/api/index';
import Web3 from 'web3';
export default {
  data() {
    return {
      imgurl: '',
      tonzhenshow: false,
      toInput: '',
      toaddress: '',

      value1: 0,
      tokens: [],
      stokenname: this.$t('lang.d21'),
      stokenaddress: '',
      tokenId: '',
      stokenlist: [],
      ruzhangaddress: '',
      balance: '',
      invite_link: '',
      popupshow: false,
      addresslist: [],
      inputcheck: false,
      orderlist: [],
      sindex: '',
      sfee: '',
      min_withdraw: 50,
    
      token_name: '',
      loading1:false,
      finished1:false,
      page:1,
    };
  },
  created() {
    this.init();
    this.toaddress = this.account;
    console.log('eee', this.defaultaddress);
    console.log(this.account);
  },
  computed: {
    ...mapState(['account', 'defaultaddress', 'lang']),

    showbutton() {
      return this.stokenname != this.$t('lang.d21') && !!this.toInput && !!this.toaddress;
    },
    addressshow() {
      const head = this.account.slice(0, 4);
      const tail = this.account.slice(this.account.length - 4);
      return head + '****' + tail;
    },
    yuen() {
      if (this.stokenname != '选择通证') {
        return true;
      } else {
        false;
      }
    },
    shoxu() {
      if (this.toInput != '') {
        return true;
      } else {
        false;
      }
    },
  },
  mounted() {},
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
      console.log(this.stokenname);
      if (this.stokenname != this.$t('lang.d21')) {
        console.log(333);
        this.stokenname = this.$t('lang.d21');
      }
    },
  },
  methods: {
    init() {
      if(this.account){
        this.page = 1
      this.orderlist =[]
      this.gettokenconfigs();
      this.getimgurl();

      this.tibilistfun();
      }
    
    },
    onLoad1() {
      if(this.account){
        this.page++;
        this.tibilistfun()
      }
      
    },
    withdrawconfig() {
      let data = {
        token_id: this.tokenId,
      };
      tibi.getwithdrawcondition(data, this.account).then(res => {
        console.log(res);
        this.min_withdraw = res.data.min_withdraw;
        this.token_name = this.stokenname;
        console.log(this.token_name);
      });
    },
    tibilistfun() {
      let data ={
        page: this.page,
        page_size: 10,
      }
      
      tibi.getwithdrawlist(data,this.account).then(res => {
    
        if (res.data.list.length != 0) {
          this.orderlist = [...this.orderlist, ...res.data.list];
        
        }

        if (res.data.list.length < 10) {
          this.finished1 = true;
        }
    
      });
    },

    tonzhenfunis() {
      this.tonzhenshow = !this.tonzhenshow;
    },
    gettokenconfigs() {
      bizhong.gettokenwithdrawconfigs(this.account).then(res => {
        console.log(res);
        this.tokens = res.data;
      });
      // bizhong.gettokenrechargeconfigs(this.account).then(res =>{
      //   console.log(res);
      //   this.tokens=res.data;
      // })
      tibi.getwithdrawaddresslist(this.account).then(res => {
        console.log(res);
        this.addresslist = res.data;
      });
    },
    shoxufei() {
      if (this.tokenId != '') {
        console.log(this.sindex.min_fee);
        var fee = this.toInput * this.sindex.fee_rate;
        if (fee <= this.sindex.min_fee) {
          this.sfee = this.sindex.min_fee;
        } else if (fee >= this.sindex.max_fee) {
          this.sfee = this.sindex.max_fee;
        } else {
          this.sfee = fee;
        }
        console.log(fee);
      } else {
        this.sfee = '';
      }
    },
    toaddressque() {
      this.inputcheck = false;
      this.$store.commit('SETDEFAULTADDRESS', this.toaddress);
    },

    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
      });
    },
    secletaddressfun() {
      this.popupshow = false;
    },
    seclettokenfun(item) {
      this.sindex = item;
      // this.sindex = this.stokenlist[index].fee_rate;
      console.log(this.sindex);
      this.tonzhenshow = false;
      this.stokenname = item.token;
      this.stokenaddress = item.address;
      this.tokenId = item.token_id;
      this.min_withdraw = item.min_amount;
      this.token_name = item.token;
      this.geterc20banceof();
      this.shoxufei();
    },
    async sendtranform() {
     
      if (this.toInput <= this.balance) {
        if (this.toInput <= 0) {
          this.$toast(this.$t('lang.d79'));
        } else {
          this.sendf();
        }
      } else {
        this.$toast(this.$t('lang.d80'));
      }
    },
    async sendf() {
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Withdraw Order'), this.account);
      console.log('sign', sign);
      const parseinput = parseFloat(this.toInput);
      let data = {
        chain_id: 13,
        token_id: this.tokenId,
        amount: parseinput,
        address: this.toaddress,
        signature: sign,
      };
      await tibi.postwithdraworder(data, this.account).then(res => {
        console.log(res);
        if(res.code == 200){
          this.init();
        this.geterc20banceof();
        }
     
        this.$toast(res.msg);
      });
    },
    async geterc20banceof() {
      let data = {
        token_id: this.tokenId,
      };
      moneymask.getwalletgetinfo(data, this.account).then(res => {
        this.balance = res.data.usable;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.chobi {
    padding: 20px;
    width: 100%;
    background: var( --box-bg-color);
    border-radius: 20px;
}

.chozhi {
    width: 100%;
    padding: 15px 20px;
       
    border-radius: 20px;
    background: ;

    .tonzhen {
        width: 100%;
        height: 52px;
        border-radius: 87px;
        background: var( --line-bg-color);
        padding: 15px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 10px;
            height: 10px;

        }

        span {
            color: var( --text-color);
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }
    }

    .vantonzhenshow {
        padding: 20px;
          background: var( --line-bg-color);
        .t_li {
            height: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);

            img {
                width: 24px;
                height: 24px;
                margin-right: 15px;
                border-radius: 50%;
            }
        }

        .quxiao {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            color: var( --text-color);
            font-size: 16px;
            font-weight: 500;
            font-family: "PingFang SC";
        }
    }

    .c_shul {
        color: var( --text-color);
        font-size: 16px;
        font-weight: 700;
        font-family: "PingFang";
        margin-top: 30px;
    }

    .van-field {
        width: 100%;
        border-radius: 87px;
        font-weight: bold;
        background: var( --line-bg-color) !important;
        padding: 16px 20px !important;

        /deep/ .van-field__control {
            font-size: 18px;
            color: var( --text-color);
        }
    }

    .balance {
        color: #9395a4ff;
        font-size: 12px;
        font-weight: 700;
        font-family: "PingFang";
    }

    .ruzhang {
        position: relative;

        span {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }

        .r_line {
            height: 15px;
            width: 1px;
            background: #56A0B7;
            position: absolute;
            right: 60px;
            top: 20px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .c_button {
        margin-top: 30px;
        width: 100%;
        height: 55px;
        border-radius: 110px;
background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        box-shadow: 0 20px 30px 0 #191c321a;
        text-align: center;
        line-height: 55px;
        color: var( --text-color);
        font-size: 16px;
        font-weight: 700;
        font-family: "PingFang";
    }
}

.c_jilu {
    color: var( --text-color);
    font-size: 18px;
    font-weight: 700;
    font-family: "PingFang";
    margin-top: 30px;
}

.c_wlist {
    width: 100%;
    height: 219px;
    border-radius: 20px;
    opacity: 1;
    background: var( --line-bg-color);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 122px;
height: 92px;

    }

    p {
        color: var( --text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
    }
}

.c_list {
    width: 100%;
    background: var( --line-bg-color);
    padding: 15px 18px;
    border-radius: 20px;

    .li {
        height: 40px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .c_head {
                color: var( --text-color);
                font-size: 14px;
                font-weight: 700;
                font-family: "PingFang";
            }

            .c_time {
                color: var( --text-color);
                font-size: 12px;
                font-weight: 500;
                font-family: "PingFang";
            }
        }

        span {
            color: var( --text-color);
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }
    }
}

.zhihui {
    margin-top: 30px;
    width: 100%;
    height: 55px;
    border-radius: 65px;
    opacity: 1;
    box-shadow: 0 20px 30px 0 #191c321a;
    text-align: center;
    line-height: 55px;
    color: var( --text-color);
    font-size: 16px;
    font-weight: 700;
    font-family: "PingFang";
    background: #4583FF;
}

.op {
    opacity: 0.4;
}</style>