<template>
  <div class="home">
    <KeepAlive>
      <router-view />
    </KeepAlive>
    <BottomNavigation />
  </div>
</template>
<script>
// import VisualChart from './swap/VisualChart.vue'
import BottomNavigation from '@/components/BottomNavigation.vue';
export default {
  name: 'Home',
  data() {
    return {
      VisualChartShow: false
    };
  },
  components: { BottomNavigation },
  created() {
  },

  methods: {
    handleSelectd(index, path) {
      if (index == this.selectdIndex) {
        return;
      }
      this.selectdIndex = index;
      this.$router.push(path);
    },
  },

};
</script>

<style lang="less" scoped>
.home{
  padding-bottom: 50px;
}</style>
