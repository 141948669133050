<template>
  <!-- 底部导航routerView -->
  <div class="MintPool">
    <router-view style="padding-bottom: 100px" />
    <div class="footer">
      <div class="box">
        <div v-for="(item, index) in footerlist" :key="index" class="item" @click="bNav(item.router, index)">
          <img class="abs_img" :src="$route.name == item.router ? item.activeimage : item.image"  />
          <span :style="{color:$route.name == item.router ? 'rgba(54, 210, 230, 1)':''}">{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerlist: [
        { image: require('@/assets/imgsnowball/zubi_icon2.png'), activeimage: require('@/assets/imgsnowball/zubi_icon1.png'), name: '铸币', router: 'MintCenter' },
        { image: require('@/assets/imgsnowball/zubi_icon4.png'), activeimage: require('@/assets/imgsnowball/zubi_icon3.png'), name: '社区', router: 'MintCommunity' },
      ],
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.name);
    
  },
  methods: {
    bNav(router, index) {
      if (router != '') {
        this.$router.push({ name: router });
      } else {
      }
    },
  },
};
</script>
<style scoped lang="less">
.MintPool {
}
.footer {
  z-index: 99;
  position: fixed;
  width: 100%;
  height: 76px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 20px 20px;
  .box {
    border-radius: 24px;
    background: rgba(47, 44, 63, 0.4);
    backdrop-filter: blur(50px);
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    padding: 13px 0;
    .item {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      font-weight: 500;
      color: rgba(95, 93, 109, 1);
      img {
        width: 24px;
        height: 24px;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
