<template>
  <!-- 铸币中心 -->
  <div>
    <div class="container">
      <!-- 顶部奖励区域 -->
      <div class="top-section">
        <div class="reward-card">
          <div>
            <div class="label">{{ $t('lang.h358') }}</div>
          </div>
          <div class="value" style="margin-top: 9px; margin-bottom: 22px">{{ summaryInfo.today_reward }}</div>
        </div>

        <div class="stats-row">
          <div class="stat-item">
            <div class="label">{{ $t('lang.h359') }}</div>
            <div class="value">{{ summaryInfo.today_cp_reward || 0 }}</div>
          </div>
          <div class="stat-item">
            <div class="label">{{ $t('lang.h360') }}</div>
            <div class="value">{{ summaryInfo.today_share_reward || 0 }}</div>
          </div>
          <div class="stat-item">
            <div class="label">{{ $t('lang.h361') }}</div>
            <div class="value">{{ summaryInfo.today_node_reward || 0 }}</div>
          </div>
          <div class="bill-btn" @click="to('MintBill')">{{ $t('lang.h362') }}</div>
        </div>
      </div>

      <!-- 中间算力信息 -->
      <div class="middle-section">
        <div class="info-card left_card">
          <img src="@/assets/imgsnowball/zubi_card_icon1.png" />
          <div class="value">{{ significantDigits(configInfo.day_addition * 100) || 0 }}%</div>
          <div class="label">{{ $t('lang.h363') }}</div>
        </div>
        <div class="info-card right_card">
          <img src="@/assets/imgsnowball/zubi_card_icon2.png" />
          <div class="value">{{ summaryInfo.total_cp || 0 }}</div>
          <div class="label">{{ $t('lang.h364') }}</div>
        </div>
      </div>

      <!-- 推广算力 -->
      <div class="promotion-section">
        <span>{{ summaryInfo.self_share_cp || 0 }}</span>
        <div class="promotion">
          <img src="@/assets/imgsnowball/arrow-icon.png" />
          <span class="label">{{ $t('lang.h365') }}</span>
        </div>
        <div class="look" @click="to('MintCommunity')">{{ $t('lang.h366') }}</div>
      </div>
      <div class="promotion-card">
        <div class="item">
          <span class="value">{{ summaryInfo.self_cp || 0 }}</span>
          <div style="display: flex; justify-content: center">
            <img src="@/assets/imgsnowball/grsl_icon.png" />
            <span class="label">{{ $t('lang.h367') }}</span>
          </div>
        </div>
        <div style="border: 1px solid rgba(67, 62, 79, 1); height: 25.42px; width: 1px"></div>
        <div class="item">
          <span class="value">{{ summaryInfo.dr_num || 0 }}</span>
          <div style="display: flex; justify-content: center">
            <img src="@/assets/imgsnowball/yxzt_icon.png" />
            <span class="label">{{ $t('lang.h368') }}</span>
          </div>
        </div>
      </div>
      <!-- 金额选择 -->
      <div class="amount-section">
        <label>{{ $t('lang.h369') }}</label>
        <div class="amount-input">
          <img src="@/assets/imgsnowball/reduce_icon.png" @click="reduceAndAdd('reduce')" />
          <input v-model="mintAmount" type="number" @input="handleInputChange" placeholder="0" />
          <img src="@/assets/imgsnowball/add_icon.png" @click="reduceAndAdd('add')" />
        </div>
        <span class="label">{{ $t('lang.h370') }}</span>
        <div class="pay-item">
          <div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
            <div style="position: relative; margin-right: 15px;display: flex;">
              <img src="@/assets/tokenlogo/HAP.png" style="z-index: 11px; border-radius: 50%;" />
              <img src="@/assets/imgsnowball/BI.png" style="z-index: 1px; margin-left: -5px" />
            </div>
            <span class="value">{{ ComUPayRateSum }} HAP + {{ ComBtPiPayRateSum || 0 }} BtPi</span>
          </div>

          <van-icon name="checked" color="#D059FF" size="24" />
          <!-- <van-icon name="circle" color="#71778A" size="24" @click="selectedValue = true" /> -->
        </div>
        <span class="label">{{ $t('lang.h371') }} </span>
        <div class="amount-input">{{ ComGetMintNum }}</div>
        <div class="button" @click="showDialogOpen">{{ $t('lang.h372') }}</div>
      </div>

      <div>
        <span :class="cur == 1 ? 'After-title' : 'title'" @click="selectCur(1)" style="margin-right: 30px">{{
          $t('lang.h373') }}</span>
        <span :class="cur == 0 ? 'After-title' : 'title'" @click="selectCur(0)">{{ $t('lang.h374') }}</span>
      </div>
      <van-list v-if="BillInfo.list.length > 0" v-model="BillInfo.loading" :finished="BillInfo.finished"
        :finished-text="`${$t('lang.d145')}`" @load="onLoadrecord">
        <div class="produce-list" v-for="(item, index) in BillInfo.list" :key="index">
          <img src="@/assets/img/zubi_list_img.png" />
          <div class="content">
            <span class="value">{{ $t('lang.h375') }}：{{ item.cp }}</span>
            <span class="label" style="margin-top: 5px">{{ item.created_at }}</span>
            <div style="display: flex; gap: 8px">
              <div class="card"><span class="label">{{ $t('lang.h376') }}：</span><span class="value"
                  style="margin-top: 3px">{{ item.amount }}</span>
              </div>
              <div class="card"><span class="label">{{ $t('lang.h377') }}：</span><span class="value"
                  style="margin-top: 3px">{{ item.amount * 2 }}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <span class="label">{{ $t('lang.h378') }}：</span>
              <span class="label">{{ cur == 1 ? $t('lang.h379') : $t('lang.h380') }}</span>
            </div>
            <van-progress color="#50E1FD" track-color="rgba(255,255,255,0.1)"
              :percentage="(1 - item.remain_amount / item.out_amount) * 100" :show-pivot="false"
              style="margin-top: 6px;width: 100%;" />
          </div>
        </div>
      </van-list>
      <div style="display: flex; flex-direction: column; align-items: center; margin-top: 30px;" v-else>
        <img src="@/assets/img/zanwu.png" style="width: 89px; height: 158px;" alt="">
        <span style="font-size: 16px; color: rgba(255, 255, 255, 1); margin-top: 22px;">{{ $t('lang.h315') }}</span>
      </div>

    </div>
    <van-popup v-model:show="showDialog" round position="bottom">
      <div style="font-weight: 500">
        <div style="padding: 20px; display: flex; justify-content: space-between">
          <span style="font-size: 14px; color: rgba(34, 34, 34, 1)">{{ $t('lang.h381') }}</span>
          <img src="@/assets/img/close_icon.png" width="13px" height="13px" @click="showDialog = false" />
        </div>
        <div style="border: 1px solid rgba(242, 242, 242, 1); height: 1px; width: 100%"></div>
        <div style="margin: 24px 20px">
          <div class="box">
            <img style="border-radius: 50%;" src="@/assets/tokenlogo/HAP.png" />
            <span>{{ ComUPayRateSum }} HAP</span>
          </div>
          <div style="text-align: center; margin: 17px 0px">
            <img src="@/assets/imgsnowball/add.png" width="18px" height="18px" />
          </div>
          <div class="box">
            <img src="@/assets/imgsnowball/BI.png" />
            <span>{{ ComBtPiPayRateSum }} BtPi</span>
          </div>
          <div style="display: flex; align-items: start; margin: 24px 0px">
            <img src="@/assets/imgsnowball/balance_icon.png" width="20px" height="20px" />
            <div style="display: flex; margin-left: 12px; flex-direction: column; width: 100%">
              <span style="margin-bottom: 7px; color: rgba(0, 0, 0, 1); font-size: 16px; font-weight: 700">{{
                $t('lang.h382') }}</span>
              <span style="margin-bottom: 3px; color: rgba(153, 153, 153, 1); font-size: 10px; font-weight: 700">HAP:{{
                walletInfo.HAPB.usable }}</span>
              <span style="margin-bottom: 7px; color: rgba(153, 153, 153, 1); font-size: 10px; font-weight: 700">BtPi：{{
                walletInfo.BtPi.usable }}</span>
              <div style="border: 1px solid rgba(242, 242, 242, 1); height: 1px; width: 100%"></div>
            </div>
          </div>
          <div class="button" @click="bpmint"> <van-loading color="#000" v-if="payLoading" /><span v-else>{{
            $t('lang.h383') }}</span></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { bp_api, web3_api } from '@/api/index';
import { debounce } from '@/utils/pettern.js';
import { significantDigits } from '@/utils/format.js';
export default {
  data() {
    return {
      mintAmount: 100,
      minting: false,
      cur: 1,
      debouncedChangeInput: null,
      showDialog: false,
      summaryInfo: {},
      nodeinfo: {
        node_level: 0
      },
      configInfo: {},
      walletInfo: {
        HAPB: {},
        BtPi: {
          usd_price: 0
        }
      },
      payLoading: false,
      multiple: 100,
      BillInfo: {
        list: [],
        params: {
          page: 1,
          page_size: 10,
          status: 1
        },
        finished: false,
        loading: false
      },
    };
  },
  components: {},
  computed: {
    ...mapState(['account', 'lang']),
    ComUPayRateSum() {
      if (!this.configInfo.u_rate) return 0
      return parseFloat(significantDigits((this.mintAmount * this.configInfo.u_rate) / this.walletInfo.HAPB.usd_price))
    },
    ComBtPiPayRateSum() {
      return significantDigits((this.mintAmount - (this.mintAmount * this.configInfo.u_rate)) / this.walletInfo.BtPi.usd_price)
    },
    ComGetMintNum() {
      if (!this.configInfo.day_addition_rate) return 0;
      if (this.nodeinfo.node_level == 2) {
        return parseFloat(this.mintAmount) + parseFloat(significantDigits(this.mintAmount * this.configInfo.day_addition)) + parseFloat(significantDigits(this.mintAmount * this.configInfo.sn_addition_rate))

      } else {
        return parseFloat(this.mintAmount) + parseFloat(significantDigits(this.mintAmount * this.configInfo.day_addition))
      }

    }
  },
  watch: {
    account() {
      this.init()

    }
  },
  created() {
    this.init()
  },
  methods: {
    significantDigits,
    init() {
      this.debouncedChangeInput = debounce(this.changeInput, 500);
      if (!this.account) return;
      Promise.all([
        this.bpusersummary(),
        this.bpconfig(),
        this.walletinfobykey(),
        this.initBillInfo(),
        this.bpnodeinfo(),
        this.getBtPiPrice()
      ])
    },
    bpnodeinfo() {
      bp_api.bpnodeinfo(this.account).then(res => {
        if (res.code == 200) {
          this.nodeinfo = res.data
        }
      })
    },
    showDialogOpen() {
      this.showDialog = true
    },
    async bpmint() {
      if (this.payLoading) return;
      this.payLoading = true
      let sign = null
      try {
        sign = await web3_api.signMessage('BP Mint', this.account)
      } catch (error) {
        this.payLoading = false
      }

      bp_api.bpmint(this.account, { amount: this.mintAmount, signature: sign }).then(res => {
        if (res.code == 200) {
          this.init()
          this.showDialog = false
        }
        this.payLoading = false
        this.$toast(res.msg)

      })
    },
    handleInputChange() {
      this.debouncedChangeInput(); // 调用防抖后的方法
    },
    walletinfobykey() {
      bp_api.walletinfobykey(this.account, { token_key: 'BtPi' }).then(res => {
        this.walletInfo['BtPi'] = res.data
        this.getBtPiPrice()
      })
      bp_api.walletinfobykey(this.account, { token_key: 'HAPB' }).then(res => {
        this.walletInfo['HAPB'] = res.data

      })
    },
    getBtPiPrice() {
      web3_api.getCurrentPairPrice(['0xef5be20eb9b29fcd17ac4680b6e51f6a4f10529d', '0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae'], '0x92627C455652Fad0fFFaBDF61f71a7455c333274', 18, 18).then(res => {
        this.walletInfo['BtPi'].usd_price = res;
      }).catch(err => {
        console.log(err);

      })
    },
    changeInput() {

      let num = parseInt(this.mintAmount || 0)
      if (num < this.configInfo.min_amount) num = this.configInfo.min_amount;

      if (num % this.multiple != 0) {
        num = num - num % this.multiple
      }
      this.mintAmount = num
    },
    bpusersummary() {
      bp_api.bpusersummary(this.account).then(res => {
        if (res.code == 200) {
          this.summaryInfo = res.data
        }
      })
    },
    bpconfig() {
      bp_api.bpconfig(this.account).then(res => {
        if (res.code == 200) {
          this.configInfo = res.data;
          this.mintAmount = this.configInfo.min_amount;
        }
      })
    },
    toprouter() {
      this.$router.go(-1);
    },
    to(router) {
      console.log(router);

      this.$router.push({ name: router });
    },
    selectCur(index) {
      this.cur = index;
      this.initBillInfo();
    },
    reduceAndAdd(type) {
      if (type == 'reduce') {
        this.mintAmount = this.mintAmount - this.multiple;
      } else if (type == 'add') {
        this.mintAmount = this.mintAmount + this.multiple;
      }
      this.changeInput();
    },
    initBillInfo() {
      this.BillInfo = {
        list: [],
        params: {
          page: 1,
          page_size: 10,
          status: this.cur,
        },
        finished: false,
        loading: false
      }
      this.bpmintlist()
    },
    onLoadrecord() {
      this.bpmintlist()
    },
    bpmintlist() {
      this.BillInfo.loading = true
      bp_api.bpmintlist(this.account, this.BillInfo.params).then(res => {
        if (res.data.length > 0) {
          if (this.BillInfo.params.page == 1) {
            this.BillInfo.list = res.data
          } else {
            this.BillInfo.list = this.BillInfo.list.concat(res.data)
          }
          if (res.data.length < this.BillInfo.params.page_size) {
            this.BillInfo.finished = true
          } else {
            this.BillInfo.params.page += 1
          }
          this.BillInfo.loading = false
        } else {
          this.BillInfo.finished = true
        }

      })
    },
  },
};
</script>
<style scoped lang="less">
.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  background: #0f0e1d;
  min-height: 100vh;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
}

/* 顶部区域样式 */
.top-section {
  border-radius: 10px;
  background: linear-gradient(128.73deg, rgba(103, 172, 255, 1) 0%, rgba(121, 27, 252, 1) 100%);
  padding: 13px 18px;
  margin-bottom: 20px;

  .reward-card {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      font-weight: 700;
      opacity: 0.8;
    }

    .value {
      font-size: 28px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
  }

  .stats-row {
    display: flex;
    justify-content: space-between;

    .stat-item {
      text-align: center;

      .label {
        font-size: 10px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.6);
      }

      .value {
        font-size: 14px;
        margin-top: 5px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
      }
    }

    .bill-btn {
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      padding: 0px 13px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 21px;
      color: rgba(102, 61, 184, 1);
      display: flex;
      align-items: center;
      height: 30px;
    }
  }
}

/* 中间算力卡片 */
.middle-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;

  .info-card {
    border-radius: 15px;
    padding: 20px;
    height: 150px;
  }

  .left_card {
    background-image: url('~@/assets/imgsnowball/zubi_card_bg1.png');
    background-size: contain;
    /* 添加这一行，使背景图片不拉伸并完整显示 */
    background-repeat: no-repeat;
    /* 可选，防止背景图片重复显示，根据需要添加 */
  }

  .right_card {
    background-image: url('~@/assets/imgsnowball/zubi_card_bg2.png');
    background-size: contain;
    /* 添加这一行，使背景图片不拉伸并完整显示 */
    background-repeat: no-repeat;
    /* 可选，防止背景图片重复显示，根据需要添加 */
  }

  img {
    width: 84px;
    height: 84px;
    margin-left: -20px;
    margin-top: -20px;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: rgba(111, 117, 135, 1);
    margin-top: 5px;
  }

  .value {
    font-size: 24px;
    font-weight: 500;
    margin-top: -5px;
  }
}

.view-btn {
  background: none;
  border: 1px solid #6a11cb;
  color: #6a11cb;
  padding: 4px 12px;
  border-radius: 12px;
}

/* 推广算力 */
.promotion-section {
  background-image: url('~@/assets/img/tg_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 13px 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  position: relative;

  .promotion {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: rgba(111, 117, 135, 1);
    margin-bottom: 2px;
  }

  .look {
    border-radius: 101px;
    background: rgba(208, 89, 255, 1);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    font-weight: 400;
    width: 66px;
    height: 31px;
    padding: 4px 12px;
  }
}

.promotion-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;
  padding: 10px 0;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(40, 73, 89, 1) 0%, rgba(28, 25, 41, 1) 100%);

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }

    .value {
      font-size: 16px;
      font-weight: 500;
    }

    .label {
      font-size: 12px;
      font-weight: 500;
      color: rgba(111, 117, 135, 1);
      margin-bottom: 2px;
    }
  }
}

/* 金额输入 */
.amount-section {
  border-radius: 16px;
  background: rgba(28, 25, 41, 1);
  padding: 16px 20px;
  margin-bottom: 20px;
  margin-top: 18px;

  label {
    font-size: 16px;
    font-weight: 700;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    color: rgba(248, 237, 253, 0.4);
  }

  .amount-input {
    border-radius: 12px;
    background: rgba(36, 33, 48, 1);
    padding: 12px 17px;
    height: 57px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      all: unset;
      display: block;
      width: 60%;
      text-align: center;
    }
  }

  img {
    width: 24px;
    height: 24px;
  }

  .pay-item {
    border-radius: 12px;
    border: 1px solid rgba(36, 33, 48, 1);
    padding: 4px 18px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-top: 12px;
    margin-bottom: 24px;
    height: 57px;
    align-items: center;

    .value {
      font-size: 14px;
      font-weight: 500;
      color: rgba(113, 119, 138, 1);
      overflow: hidden;
    }
  }

  .button {
    border-radius: 10px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 22.4px;
    color: rgba(0, 0, 0, 1);
    height: 46px;
  }
}

.After-title {
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.After-title::after {
  content: '';
  display: block;
  width: 26px;
  height: 5px;
  background-image: url('~@/assets/img/after_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  /* 或者设置为 contain 根据需求调整 */
  background-position: center;
  position: absolute;
  left: 50%;
  bottom: -11px;
  transform: translateX(-50%);
}

.title {
  font-size: 14px;
  font-weight: 700;
  color: rgba(116, 110, 126, 1);
}

.produce-list {
  border-radius: 16px;
  background: #1c1929;
  padding: 15px;
  display: flex;
  margin-top: 24px;

  img {
    width: 111px;
    height: 126px;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 100%;

    .card {
      border-radius: 5px;
      background: #242130;
      padding: 6px 4px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
    }

    .label {
      font-size: 10px;
      font-weight: 500;
      color: rgba(116, 110, 126, 1);
    }

    .value {
      font-size: 12px;
      font-weight: 500;
    }

    .process-box {
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.1);
      height: 4px;
      width: 100%;
      margin-top: 3px;
    }
  }
}

.box {
  border-radius: 8px;
  border: 1px solid rgba(230, 230, 230, 1);
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0px 12px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(0, 0, 0, 1);
  }
}

.button {
  border-radius: 110px;
  background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 22.4px;
  color: rgba(0, 0, 0, 1);
  height: 48px;
}
</style>
