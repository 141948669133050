<template>
  <!-- 铸币账单 -->
  <div style="padding: 20px; margin-bottom: 88px; color: rgba(255, 255, 255, 1)">

    <div>
      <span class="card_title">{{ $t('lang.h359') }}</span>
      <div class="card">
        <img src="@/assets/img/bill_icon1.png" />
        <div class="right">
          <div class="box">
            <div class="text">
              <span class="label">{{ $t('lang.h384') }}</span>
              <span class="value">{{ walletasset.claimed_cp_reward || 0 }}</span>
            </div>
            <div class="text">
              <span class="label">{{ $t('lang.h385') }}</span>
              <span class="value" style="color: rgba(48, 255, 252, 1)">{{ walletasset.cp_reward || 0 }}</span>
            </div>
          </div>
          <div class="button" @click="bpwalletclaim(0)"> <van-loading color="#fff"
              v-if="payLoading && claimtype == 0" /> <span v-else>{{ $t('lang.h388') }}</span></div>
        </div>
      </div>

      <span class="card_title">{{ $t('lang.h360') }}</span>
      <div class="card">
        <img src="@/assets/img/bill_icon2.png" />
        <div class="right">
          <div class="box">
            <div class="text">
              <span class="label">{{ $t('lang.h384') }}</span>
              <span class="value">{{ walletasset.claimed_share_reward || 0 }}</span>
            </div>
            <div class="text">
              <span class="label">{{ $t('lang.h385') }}</span>
              <span class="value" style="color: rgba(48, 255, 252, 1)">{{ walletasset.share_reward || 0 }}</span>
            </div>
          </div>
          <div class="button" @click="bpwalletclaim(1)"> <van-loading color="#fff"
            v-if="payLoading && claimtype == 1" /> <span v-else>{{ $t('lang.h388') }}</span></div>
        </div>
      </div>

      <span class="card_title">{{ $t('lang.h386') }}</span>
      <div class="card">
        <img src="@/assets/img/bill_icon3.png" />
        <div class="right">
          <div class="box">
            <div class="text">
              <span class="label">{{ $t('lang.h384') }}</span>
              <span class="value">{{ walletasset.claimed_node_reward || 0 }}</span>
            </div>
            <div class="text">
              <span class="label">{{ $t('lang.h385') }}</span>
              <span class="value" style="color: rgba(48, 255, 252, 1)">{{ walletasset.node_reward || 0 }}</span>
            </div>
          </div>
          <div class="button" @click="bpwalletclaim(2)"> <van-loading color="#fff"
            v-if="payLoading && claimtype == 2" /> <span v-else>{{ $t('lang.h388') }}</span></div>
        </div>
      </div>

      <span class="card_title">{{ $t('lang.h387') }}</span>
      <div class="card">
        <img src="@/assets/img/bill_icon4.png" />
        <div class="right">
          <div class="box">
            <div class="text">
              <span class="label">{{ $t('lang.h384') }}</span>
              <span class="value">{{ walletasset.claimed_super_node_reward || 0 }}</span>
            </div>
            <div class="text">
              <span class="label">{{ $t('lang.h385') }}</span>
              <span class="value" style="color: rgba(48, 255, 252, 1)">{{ walletasset.super_node_reward || 0 }}</span>
            </div>
          </div>
          <div class="button" @click="bpwalletclaim(3)"> <van-loading color="#fff"
            v-if="payLoading && claimtype == 3" /> <span v-else>{{ $t('lang.h388') }}</span></div>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between">
        <span v-for="(item, index) in barList" :key="index" :class="cur == index ? 'After-title' : 'title'"
          @click="selectCur(index)">{{ $t(item.name) }}</span>
      </div>

      <div class="list">
        <van-list v-if="BillInfo.list.length > 0" v-model="BillInfo.loading" :finished="BillInfo.finished" :finished-text="`${$t('lang.d145')}`"
          @load="onLoadrecord">
          <div class="text1">
            <span>{{ $t('lang.h389') }}</span>
            <div style="display: flex; width: 55%; justify-content: space-between">
              <span>{{ $t('lang.h390') }}</span>
              <span>{{ $t('lang.h391') }}</span>
            </div>
          </div>
          <div class="list-item" v-for="(item, index) in BillInfo.list" :key="index">
            <span>{{ item.created_at }}</span>
            <div style="display: flex; width: 55%; justify-content: space-between">
              <span :style="parseFloat(item.usable_change) > 0 ? 'color:#30FFFC' : 'color:#30FFFC'">{{parseFloat(item.usable_change) > 0 ? '+'+ item.usable_change :  item.usable_change
                }}</span>
              <span :style="parseFloat(item.usable_change) > 0 ? 'color:#F8EDFD' : 'color:#9A91A2'">{{ item.type_txt
                }}</span>
            </div>
          </div>
        </van-list>
        <div v-else style="display: flex; flex-direction: column; align-items: center; padding: 20px 0;">
          <img src="@/assets/img/zanwu.png" style="width: 89px; height: 158px;" alt="">
          <span style="font-size: 16px; color: rgba(255, 255, 255, 1); margin-top: 22px;">{{ $t('lang.h315') }}</span>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { bp_api, web3_api } from '@/api/index';
export default {
  data() {
    return {
      cur: 0,
      claimtype: 0,
      barList: [
        { name: 'lang.h359', id: 0 },
        { name: 'lang.h360', id: 1 },
        { name: 'lang.h386', id: 2 },
        { name: 'lang.h387', id: 3 },
      ],
      walletasset: {},
      BillInfo: {
        list: [],
        params: {
          page: 1,
          page_size: 10,
          type: 0
        },
        finished: false,
        loading: false
      },
      payLoading: false
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  components: {},
  watch: {
    account() {
      this.init()

    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.account) return;
      Promise.all([
        this.bpwalletasset()
      ])
    },
    async bpwalletclaim(type) {
      if (this.payLoading) return;
      this.claimtype = type;
      this.payLoading = true
      let signature = null
      try {
        signature = await web3_api.signMessage('BP Reward Claim', this.account)
      } catch (error) {
        this.payLoading = false
      }
      bp_api.bpwalletclaim(this.account, { type, signature }).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.payLoading = false
        this.$toast(res.msg)
      })
    },
    initBillInfo() {
      this.BillInfo = {
        list: [],
        params: {
          page: 1,
          page_size: 10,
          type: this.cur,
        },
        finished: false,
        loading: false
      }
      this.bpwalletrecordlist()
    },
    bpwalletasset() {
      bp_api.bpwalletasset(this.account).then(res => {
        if (res.code == 200) {
          this.walletasset = res.data
        }
      })
    },
    onLoadrecord() {
      this.bpwalletrecordlist()
    },
    bpwalletrecordlist() {
      this.BillInfo.loading = true
      bp_api.bpwalletrecordlist(this.account, this.BillInfo.params).then(res => {
        if (res.data.list.length > 0) {
          if (this.BillInfo.params.page == 1) {
            this.BillInfo.list = res.data.list
          } else {
            this.BillInfo.list = this.BillInfo.list.concat(res.data.list)
          }
          if (res.data.list.length < this.BillInfo.params.page_size) {
            this.BillInfo.finished = true
          } else {
            this.BillInfo.params.page += 1
          }
          this.BillInfo.loading = false
        } else {
          this.BillInfo.finished = true
        }

      })
    },
    toprouter() {
      this.$router.go(-1);
    },
    selectCur(index) {
      this.cur = index;
      this.initBillInfo();
    },
  },
};
</script>
<style scoped lang="less">
.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_title {
  font-size: 16px;
  font-weight: 700;
  margin-left: 12px;
}

.card {
  background-size: 100% 100%;
  background-image: url('~@/assets/img/bill_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;
  margin-bottom: 30px;

  img {
    width: 107px;
    height: 107px;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 12px;
    width: 60%;

    .box {
      border-radius: 12px;
      background: rgba(22, 19, 32, 1);
      padding: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .button {
      border-radius: 8.47px;
      background: rgba(98, 51, 214, 1);
      font-size: 14px;
      font-weight: 400;
      padding: 8px 12px;
      text-align: center;
    }

    .label {
      font-size: 12px;
      font-weight: 400;
      color: rgba(248, 237, 253, 1);
      opacity: 0.6;
    }

    .value {
      font-size: 18px;
      font-weight: 400;
      color: rgba(248, 237, 253, 1);
    }
  }
}

.After-title {
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.After-title::after {
  content: '';
  display: block;
  width: 26px;
  height: 5px;
  background-image: url('~@/assets/img/after_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  /* 或者设置为 contain 根据需求调整 */
  background-position: center;
  position: absolute;
  left: 50%;
  bottom: -11px;
  transform: translateX(-50%);
}

.title {
  font-size: 16px;
  font-weight: 700;
  color: rgba(116, 110, 126, 1);
}

.list {
  border-radius: 11.5px;
  padding: 12px 17px;
  border: 1px solid rgba(248, 237, 253, 0.2);
  margin-top: 25px;
  margin-bottom: 48px;

  .text1 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(248, 237, 253, 1);
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgba(248, 237, 253, 1);
    margin-bottom: 22px;
  }
}
</style>
