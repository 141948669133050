<template>
  <div class="engbill">

    <div class="title">
      {{ $t('lang.h245') }}
    </div>
    <div class="receivebox"
    v-loading="loading"
      style="background: linear-gradient(138.02deg, rgba(29, 145, 143, 1) 0%, rgba(102, 61, 184, 1) 100%);">
      <img src="@/assets/imgsnowball/excitation1.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.h246') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ Cwalletasset.circulation_reward }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.h247') }}</div>
        <div class="num_num">{{ todayreward.circulation_reward }}</div>
      </div>
      <div class="button">
        {{ $t('lang.h248') }}
      </div>
    </div>
    <div class="title">
      {{ $t('lang.h249') }}
    </div>
    <div class="receivebox"
    v-loading="loading"
      style="background: linear-gradient(138.02deg, rgba(29, 50, 145, 1) 0%, rgba(153, 61, 184, 1) 100%);">
      <img src="@/assets/imgsnowball/excitation2.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.h246') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ Cwalletasset.share_reward }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.h247') }}</div>
        <div class="num_num">{{ todayreward.share_reward }}</div>
      </div>
      <div class="button">
        {{ $t('lang.h248') }}
      </div>
    </div>
    <div class="title">
      {{ $t('lang.h250') }}
    </div>
    <div class="receivebox"
    v-loading="loading"
      style="background: linear-gradient(138.02deg, rgba(29, 145, 93, 1) 0%, rgba(61, 67, 184, 1) 100%);">
      <img src="@/assets/imgsnowball/excitation3.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.h246') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ Cwalletasset.fund_reward }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.h247') }}</div>
        <div class="num_num">{{ todayreward.fund_reward }}</div>
      </div>
      <div class="button">
        {{ $t('lang.h251') }}
      </div>
    </div>
    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="li first">
        <div>{{ $t('lang.h136') }}</div>
        <div>{{ $t('lang.h137') }}</div>
        <div>{{ $t('lang.h138') }}</div>
      </div>
      <van-list v-model="BillInfo.loading" :finished="BillInfo.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadrecord">
        <div class="li" v-for="(item, index) in BillInfo.list" :key="index">
          <div style="color: rgba(153, 153, 153, 1);">{{ item.created_at }}</div>
          <div style="color: rgba(56, 248, 252, 1);">+{{ item.usable_change }}</div>
          <div style="color: rgba(153, 153, 153, 1);">{{ item.token }}</div>
        </div>
      </van-list>

    </div>
  </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import { circulation, web3_api } from '@/api/index';
import Web3 from 'web3';
export default {
  data() {
    return {
      functionOption: [{ name: 'lang.h245' }, { name: 'lang.h249' }, { name: 'lang.h250' }],
      regionIndex: '0',
      loading: false,
      todayreward:{},
      Cwalletasset:{},
      BillInfo: {
        list: [],
        params: {
          page: 1,
          page_size: 10,
          type: 0
        },
        finished: false,
        loading: false
      }
    }
  },
  components: {

  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {

      this.init()
    },
    lang() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    switchovers(index) {
      this.regionIndex = index;
     this.initBillInfo()
     this.circulationwalletrecordlist()

    },
    initBillInfo() {
            this.BillInfo = {
                list: [],
                params: {
                    page: 1,
                    page_size: 10,
                    type: this.regionIndex
                },
                finished: false,
                loading: false
            }
        },
    toprouter() {
      this.$router.go(-1);

    },
    init() {
      if(this.account){
        Promise.all([
          this.circulationwalletrecordlist(),
          this.circulationwallettodayreward(),
          this.circulationwalletasset()
         ]) 
      }
      

    },
    circulationwalletasset() {
       this.loading = true
            circulation.circulationwalletasset(this.account).then(res => {
                if (res.code == 200) {
                    this.Cwalletasset = res.data
                }
                this.loading = false
            })
        },
    circulationwallettodayreward(){
      circulation.circulationwallettodayreward(this.account).then(res=>{
             if(res.code == 200){
              this.todayreward = res.data
             }
      })
    },
    onLoadrecord() {
      if(this.account){
        this.circulationwalletrecordlist()
      }
     
    },
    circulationwalletrecordlist() {
      this.BillInfo.loading = true
      circulation.circulationwalletrecordlist(this.BillInfo.params, this.account).then(res => {
        if (res.data.list.length > 0) {
          if (this.BillInfo.params.page == 1) {
            this.BillInfo.list = res.data.list
          } else {
            this.BillInfo.list = this.BillInfo.list.concat(res.data.list)
          }
          if (res.data.list.length < this.BillInfo.params.page_size) {
            this.BillInfo.finished = true
          } else {
            this.BillInfo.params.page += 1
          }
          this.BillInfo.loading = false
        } else {
          this.BillInfo.finished = true
        }

      })
    },
  },
}

</script>
<style scoped lang='less'>
.functional_region {

  .region {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 80%;

    .special {
      font-size: 18px;
      color: #fff;
    }
  }
}

.engbill {
  padding: 20px;
  min-height: 100vh;
  padding-bottom: 44px;

}

.receivebox {
  margin-bottom: 28px;
  flex-wrap: wrap;
  padding: 20px 15px 20px 8px;
  width: 100%;
  height: 95px;
  opacity: 1;
  border-radius: 8px;
  background: linear-gradient(90deg, rgba(29, 145, 143, 1) 0%, rgba(102, 61, 184, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 56px;
    height: 56px;
  }

  .button {
    align-self: end;
    padding: 5px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 700;
    color: rgba(102, 61, 184, 1);
  }

  .num {
    .num_title {
      font-size: 12px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);
    }

    .num_num {
      font-size: 18px;
      font-weight: 500;
      color: rgba(56, 248, 252, 1);
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 12px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-top: 29px;
  padding: 15px 20px;
  width: 100%;
  border-radius: 16px;
  background: rgba(27, 31, 46, 1);

  border: 1px solid rgba(36, 40, 52, 1);

  .first {
    color: rgba(153, 153, 153, 1);
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .li {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 700;

    &>div {
      width: calc(100% / 3);
      text-align: center;
    }

    &>div:first-child {
      text-align: left;
    }

    &>div:last-child {
      text-align: right;
    }
  }
}
</style>