<template>
    <div class="transferPage">
        <div class="check_account">
            <div class="check_account_box"> {{ checkBool == '0' ? $t('lang.h161') : $t('lang.h162') }} </div>
            <div :class="checkBool == '0' ? 'check_account_logo' : 'check_account_logo check_account_logo_tran'"
                @click="checkBoolFun">
                <img src="@/assets/imgsnowball/transfer_check.png" alt="">
            </div>
            <div class="check_account_box"
                style="border: 1px solid rgba(180, 135, 255, 0.46); background: transparent; color: rgba(255, 255, 255, 0.6);">
                {{ checkBool == '1' ? $t('lang.h161') : $t('lang.h162') }}</div>
        </div>
        <div class="transfer_box">
            <div class="transfer_box_title">{{ $t('lang.h163') }}</div>
            <div class="transfer_box_input">
                <input type="number" v-model="InputData" :min="gameConfigData.min_amount"
                    :max="gameConfigData.max_amount"
                    :placeholder="`${$t('lang.h164')}${gameConfigData.min_amount}~${gameConfigData.max_amount}`" />
                <div class="transfer_box_input_All" @click="allInputDataFun">{{ $t('lang.h165') }}</div>
            </div>
            <div class="transfer_box_banlace">
                {{ $t('lang.h166') }}：{{ checkBool == '0' ? gameBanlace : assetBanlace }}
            </div>
            <div class="transfer_box_between">
                <div class="transfer_box_between_left">
                    {{ $t('lang.h167') }}
                </div>
                <div class="transfer_box_between_right">
                    {{  ComputFeeRate  }}
                </div>
            </div>
            <van-button class="transfer_box_button" @click="transferFun" v-if="!signingBool">{{ $t('lang.h168')
                }}</van-button>
            <van-button class="transfer_box_button" loading v-else></van-button>
        </div>
        <div class="title">{{ $t('lang.h169') }}</div>
        <div class="transfer_box" v-if="transferList.list.length > 0">
            <div class="transfer_box_li" style=" border-bottom: 1px solid rgba(58, 70, 92, 1); padding-bottom: 16px;">
                <div class="transfer_box_li_box">
                    {{ $t('lang.h170') }}
                </div>
                <div class="transfer_box_li_box">
                    {{ $t('lang.h171') }}
                </div>
                <div class="transfer_box_li_box">
                    {{ $t('lang.h172') }}
                </div>
            </div>
            <van-list v-model=" transferList.Listloading" :finished="transferList.Listfinished" finished-text="没有更多了" @load="ListonLoad">
                <div class="transfer_box_li" v-for="(item,index) in transferList.list" :key="index">
                    <div class="transfer_box_li_box">
                       {{ item.created_at }}

                    </div>
                    <div class="transfer_box_li_box">
                        <span :style="`color: ${item.type == '0'? 'rgba(182, 133, 255, 1)': 'red'}  ;`">{{ item.type == '0'? '+' + Math.abs(parseFloat(item.real_amount)) : '-' + item.real_amount  }}</span>   
                    </div>
                    <div class="transfer_box_li_box">
                      <span> {{ item.status != '3'? $t('lang.h173') :$t('lang.h177') }}</span>   &nbsp; <span :style="`color: ${item.type == '0'? 'rgba(182, 133, 255, 1)': 'red'}  ;`">({{ item.type == '0'? $t('lang.h179'):$t('lang.h178')  }}) </span> 
                    </div>
                </div>
            </van-list>

        </div>
        <div class="transfer_box" v-else style="display: flex; flex-direction: column;align-items: center;">
            <img src="@/assets/img/zanwu.png" class="wujilu" alt="" />
            <span style="color: rgba(255, 255, 255, 1); font-size: 16px; "> {{ $t('lang.h174') }}</span>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import { happydex, other_api } from '@/api';
import Web3 from 'web3';
export default {
    data() {
        return {
            checkBool: '0',
            gameConfigData: {
                fee_rate:0
            },
            InputData: '',
            signingBool: false,
            gameBanlace: 0,
            assetBanlace: 0,
            transferList: {
                list:[],
                Listloading:false,
                Listfinished:false,
                Info:{
                    page:1,
                    page_size:10,
                    status:-1
                }
            }
        }
    },
    watch: {
        account() {
            //获取账号
            this.init();
        },
        lang() {
            this.init();
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
        ComputFeeRate(){
               if(this.checkBool == '0'){
                return this.InputData!='' ? this.InputData : 0
               }else{
                   
                return  (this.gameConfigData && this.InputData!='') ? parseFloat(this.InputData)  - parseFloat(this.InputData * this.gameConfigData.fee_rate).toFixed(3) : 0
               }
           
        }
    },
    components: {

    },
    mounted() {

    },
    created() {
        this.init()
    },
    methods: {
        ListonLoad(){
            this.gettransferList()
        },
        checkBoolFun() {
            this.checkBool = this.checkBool == '0' ? '1' : '0';
        },
        allInputDataFun() {
            this.InputData = this.checkBool == '0' ? this.gameBanlace : this.assetBanlace
        },
        gettransferList(){
            this.transferList.Listloading = true
             happydex.gameTransferList(this.transferList.Info,this.account).then(res=>{
                  if(res.code == 200){
                    if(res.data.list != null || res.data.list.length > 0){
                        if(this.transferList.Info.page == 1){
                            this.transferList.list = res.data.list
                        }else{
                            this.transferList.list = [...this.transferList.list,...res.data.list]
                        }
                        if(res.data.list.length < this.transferList.Info.page_size){
                            this.transferList.Listfinished = true
                        }else{
                            this.transferList.Info.page++
                        }
                        this.transferList.Listloading = false
                    }
                  }
             })
        },
        getGameBanlace() {
            other_api.getNumber({ address: this.account }).then(res => {
                if (res.code == 200) {
                    this.gameBanlace = res.data.number
                }
            })
        },
        walletinfobykey() {
            happydex.walletinfobykey({ token_key: 'GOC' }, this.account).then(res => {
                if (res.code == 200) {
                    this.assetBanlace = res.data.usable
                }
            })
        },
        toprouter() {
            this.$router.go(-1);
        },
        init() {
            if (this.account) {
                this.transferList = {
                list:[],
                Listloading:false,
                Listfinished:false,
                Info:{
                    page:1,
                    page_size:10,
                    status:-1
                }
            }
                this.gameconfig()
                this.getGameBanlace()
                this.walletinfobykey()
                this.gettransferList()
            }

        },
        transferFun() {
           
            if (this.gameConfigData.status == '0') return this.$toast(this.$t('lang.h175'));
            if (this.InputData < this.gameConfigData.min_amount || this.InputData > this.gameConfigData.max_amount) return this.$toast(this.$t('lang.h176'));
            if (this.checkBool == '0') {
                if(parseFloat(this.InputData) >  this.gameBanlace ) return this.$toast(this.$t('lang.h180'));
                this.gameassettranout()

            } else {
                if(parseFloat(this.InputData) >  this.assetBanlace ) return this.$toast(this.$t('lang.h180'));
                this.gameassettransin()
            }
        },
        gameconfig() {
            happydex.gameconfig(this.account).then(res => {
                if (res.code == 200) {
                    this.gameConfigData = res.data
                }
            })
        },
        async gameassettransin() {

            this.signingBool = true;
            let sign = null
            try {
                let web3 = new Web3(window.ethereum);
                sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Game Transfer In'), this.account);
            } catch (error) {
                this.signingBool = false;
            }

            let data = {
                amount: parseFloat(this.InputData),
                signature: sign
            }
            happydex.gameassettransin(data, this.account).then(res => {
                this.signingBool = false;
                this.$toast(res.msg);
                if (res.code == 200) {
                    this.init()
                }
            })
        },
        async gameassettranout() {
            this.signingBool = true;
            let sign = null
            try {
                let web3 = new Web3(window.ethereum);
                sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Game Transfer Out'), this.account);
            } catch (error) {
                this.signingBool = false;
            }

            let data = {
                amount: parseFloat(this.InputData),
                signature: sign
            }
            happydex.gameassettransout(data, this.account).then(res => {
                this.signingBool = false;
                this.$toast(res.msg);
                if (res.code == 200) {
                    this.init()
                }
            })
        }
    },
}

</script>
<style scoped lang='less'>
.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transferPage {
    padding: 20px;
    background: rgba(13, 8, 26, 1);
    min-height: 100vh;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin: 30px 0 12px 0;
    color: #fff;
}

.transfer_box {
    margin: 22px 0 30px 0;
    padding: 22px 18px;
    border-radius: 30px;
    background: rgba(27, 31, 46, 1);

    .wujilu {
        margin: 0 auto;
        width: 89px;
        height: 158px;
        margin-bottom: 16px;
    }

    .transfer_box_li:first-child {
    
    }

    .transfer_box_li {
        margin-bottom: 34px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.6);

        &>div {
            width: calc(100%/3);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &>div:first-child {
            justify-content: left;
        }

        &>div:last-child {
            justify-content: right;
        }
    }

    .transfer_box_button {
        margin-top: 33px;
        width: 100%;
        height: 52px;
        opacity: 1;
        border-radius: 110px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(204, 204, 204, 1);
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(0, 0, 0, 1);
        border: none;
    }

    .transfer_box_between {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;

        .transfer_box_between_left {
            color: rgba(255, 255, 255, 0.6);
        }

        .transfer_box_between_right {
            color: rgba(255, 255, 255, 1);
        }
    }

    .transfer_box_title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 16px;

    }

    .transfer_box_banlace {
        margin: 14px 0 29px 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 16.8px;
        color: rgba(255, 255, 255, 1);
        opacity: 0.4;
    }

    .transfer_box_input {
        width: 100%;
        height: 52px;
        opacity: 1;
        border-radius: 87px;
        background: rgba(45, 58, 82, 1);
        display: flex;
        padding: 16px 30px;
        font-size: 14px;
        font-weight: 500;

        input {
            flex: 1;
            border: none;
            background: none;
            color: rgba(255, 255, 255, 0.6);
        }

        .transfer_box_input_All {
            border-left: 2px solid rgba(179, 136, 255, 1);
            padding-left: 19.5px;
            color: rgba(179, 136, 255, 1);

            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.check_account {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;

    .check_account_logo_tran {
        transform: rotate(180deg);
    }

    color: rgba(0, 0, 0, 1);

    .check_account_box {
        width: 35%;
        height: 48px;
        opacity: 1;
        border-radius: 30px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .check_account_logo {
        transition: .5s;
        width: 22px;
        height: 22px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>