import Vue from 'vue';
import VueRouter from 'vue-router';
import SwapIndex from '../views/swap/Index.vue';
import swapV2Index from '../views/swapV2/Index.vue';
import Swap from '../views/swap/Swap.vue';
import SwapV2 from '../views/swapV2/swapV2.vue';
import Home from '../views/Home.vue';
import LiquidityIndex from '../views/liquidity/Index.vue';
import LiquidityV2Index from '../views/liquidityV2/Index.vue';
import Liquidity from '../views/liquidity/Liquidity.vue';
import LiquidityV2 from '../views/liquidityV2/Liquidity.vue';
import importMining from '../views/liquidity/importMining.vue';
import importMiningV2 from '../views/liquidityV2/importMining.vue';
import Remove from '../views/liquidity/Remove.vue';
import RemoveV2 from '../views/liquidityV2/Remove.vue';
import Invitation from '../views/invitation/Index.vue';
import Index from '../views/index/index.vue';

import Assets from '../views/SnowballProgram/chobi/Index.vue';
import ChoBi1 from '../views/SnowballProgram/chobi/chobi.vue';
import TiBi2 from '../views/SnowballProgram/chobi/tibi.vue';
import ZhuangZhan3 from '../views/SnowballProgram/chobi/zhuanzhang.vue';
import gongao from '../views/SnowballProgram/gongao/Index.vue';
import xiangqing from '../views/SnowballProgram/gongao/xiangqing.vue';
import zhangdan from '../views/SnowballProgram/zhangdan/Index.vue';
import zhuzao from '../views/SnowballProgram/zhuzao/Index.vue';
import fankui from '../views/SnowballProgram/fankui/Index.vue';
import fankuiadd from '../views/SnowballProgram/fankui/addfankui.vue';
import fanxiangqin from '../views/SnowballProgram/fankui/xiangqin.vue';
import kuangchi from '../views/SnowballProgram/kuangchi/kuangchi.vue';
import fenxiang918 from '../views/SnowballProgram/kuangchi/fenxiang918.vue';
import konto918 from '../views/SnowballProgram/kuangchi/konto918.vue';
import kuangchiIndex from '../views/SnowballProgram/kuangchi/Index.vue';
import fenxiang from '../views/SnowballProgram/kuangchi/fenxiang.vue';
import Next from '../views/Next.vue';
import newearn from '../views/earnmoney/newIndex.vue';
import iso from '../views/iso/Index.vue';
import recruit from '../views/recruit/Index.vue';
import NFTCenter from '../views/nftCenter/index.vue';

import engbill from '../views/nftCenter/engbill.vue';
import nftbill from '../views/nftCenter/nftbill.vue';

import gamePage from '../views/gamemarket/index.vue';

import transferPage from '../views/SnowballProgram/transfer/transferPage.vue';
import newRecuitMember from '../views/earnmoney/newrecuitMember.vue';

import newchildlist from '../views/earnmoney/newchildlist.vue';
import circulationIndex from '../views/circulation/Index.vue';
import sharePage from '../views/circulation/sharePage.vue';
import billPage from '../views/circulation/billPage.vue';
import OrderPage from '../views/circulation/OrderPage.vue';
import MintPool from '../views/MintPool/Index.vue';
import MintCenter from '../views/MintPool/MintCenter.vue';
import MintCommunity from '../views/MintPool/MintCommunity.vue';
import FlashExchange from '../views/AssetManagement/FlashExchange.vue';

import MintBill from '../views/MintPool/MintBill.vue';
// import boardOfDirectors from '../views/boardOfDirectors/Index.vue'
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    redirect: 'Index',
    component: Home,
    children: [
      {
        path: 'Index',
        name: 'Index',
        component: Index,
      },
      {
        path: 'SwapIndex',
        name: 'SwapIndex',
        component: SwapIndex,
        children: [
          {
            path: 'Swap',
            name: 'Swap',
            component: Swap,
          },
          {
            path: 'LiquidityIndex',
            name: 'LiquidityIndex',
            component: LiquidityIndex,
          },
          {
            path: 'Liquidity',
            name: 'Liquidity',
            component: Liquidity,
          },
          {
            path: 'importMining',
            name: 'importMining',
            component: importMining,
          },
          {
            path: 'Remove',
            name: 'Remove',
            component: Remove,
          },
        ],
      },
      {
        path: 'kuangchiIndex',
        name: 'kuangchiIndex',
        component: kuangchiIndex,
      },
      {
        path: 'kuangchi',
        name: 'kuangchi',
        component: kuangchi,
      },
      {
        path: 'fenxiang',
        name: 'fenxiang',
        component: fenxiang,
      },
      {
        path: 'fenxiang918',
        name: 'fenxiang918',
        component: fenxiang918,
      },
      {
        path: 'konto918',
        name: 'konto918',
        component: konto918,
      },
      {
        path: 'Invitation',
        name: 'Invitation',
        component: Invitation,
      },
      {
        path: 'earn',
        name: 'iso',
        component: newearn,
      },
      {
        path: 'iso',
        name: 'earn',
        component: iso,
      },



    ],
  },
  {
    path: '/Next',
    name: 'Next',
    component: Next,
    children: [
      {
        path: 'childlist',
        name: 'childlist',
        component: newchildlist,
      },
      {
        path: 'FlashExchange',
        name: 'FlashExchange',
        component: FlashExchange,
        meta:{
          name:'lang.h416'
        }
      },
      {
        path: 'RecuitMember',
        name: 'RecuitMember',
        component: newRecuitMember,
      },
      {
        path: 'swapV2Index',
        name: 'swapV2Index',
        component: swapV2Index,
        children: [
          {
            path: 'SwapV2',
            name: 'SwapV2',
            component: SwapV2,
          },
          {
            path: 'LiquidityV2Index',
            name: 'LiquidityV2Index',
            component: LiquidityV2Index,
          },
          {
            path: 'LiquidityV2',
            name: 'LiquidityV2',
            component: LiquidityV2,
          },
          {
            path: 'importMiningV2',
            name: 'importMiningV2',
            component: importMiningV2,
          },
          {
            path: 'RemoveV2',
            name: 'RemoveV2',
            component: RemoveV2,
          },
        ],
      },
      {
        path: 'circulationIndex',
        name: 'circulationIndex',
        component: circulationIndex,
      },
      {
        path: 'sharePage',
        name: 'sharePage',
        component: sharePage,
      },
      {
        path: 'billPage',
        name: 'billPage',
        component: billPage,
      },
      {
        path: 'OrderPage',
        name: 'OrderPage',
        component: OrderPage,
      },

      {
        path: 'NFTCenter',
        name: 'NFTCenter',
        component: NFTCenter,
      },
      {
        path: 'engbill',
        name: 'engbill',
        component: engbill,
      },
      {
        path: 'nftbill',
        name: 'nftbill',
        component: nftbill,
      },
      {
        path: 'recruit',
        name: 'recruit',
        component: recruit,
      },
      {
        path: 'gongao',
        name: 'gongao',
        component: gongao,
      },
      {
        path: 'xiangqing',
        name: 'xiangqing',
        component: xiangqing,
      },

      {
        path: 'gamePage',
        name: 'gamePage',
        component: gamePage,
      },
      {
        path: 'transferPage',
        name: 'transferPage',
        component: transferPage,
      },
      {
        path: 'Assets',
        name: 'Assets',

        component: Assets,
        children: [
          {
            path: 'chobi1',
            name: 'ChoBi1',
            component: ChoBi1,
          },
          {
            path: 'tibi2',
            name: 'TiBi2',
            component: TiBi2,
          },
          {
            path: 'zhuangzhan3',
            name: 'ZhuangZhan3',
            component: ZhuangZhan3,
          },
        ],
      },
      {
        path: 'fankui',
        name: 'fankui',
        component: fankui,
      },

      {
        path: 'fankuiadd',
        name: 'fankuiadd',
        component: fankuiadd,
      },
      {
        path: 'fanxiangqin',
        name: 'fanxiangqin',
        component: fanxiangqin,
      },
      {
        path: 'zhuzao',
        name: 'zhuzao',
        component: zhuzao,
      },
      {
        path: 'zhangdan',
        name: 'zhangdan',
        component: zhangdan,
        meta:{
          name:'lang.d86'
        }
      },
      {
        path: '/MintBill',
        name: 'MintBill',
        component: MintBill,
      },
      {
        path: '/MintPool',
        name: 'MintPool',
        component: MintPool,
        children: [
          {
            path: 'MintCenter',
            name: 'MintCenter',
            component: MintCenter,
            meta:{
              backgroundImage:require('@/assets/imgsnowball/MintCenterBg.png')
            }
          },
          {
            path: 'MintCommunity',
            name: 'MintCommunity',
            component: MintCommunity,
            meta:{
              backgroundImage:require('@/assets/imgsnowball/MintCommunityBg.png')
            }
          },
        ],
      },
    ]
  },

];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  // base: './',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },

  routes,
});
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
export default router;
