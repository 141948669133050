<template>
  <div class="iso">
    <div class="i_main" :style="`background: url(${ColorCheckData1 == 'black' ? bglist.image : bglist.image1}) no-repeat;`">
      <div class="m_title">{{ $t('lang.h47') }}</div>
      <div class="m_text">{{ $t('lang.h48') }}</div>
      <div class="buttons">
        <div class="b_b1">{{ $t('lang.swap356') }}</div>
        <div class="b_b1">{{ $t('lang.swap357') }}</div>
      </div>
    </div>
    <div class="i_sosuo">
      <div class="s_line1">
        <div class="region">
          <div v-for="(item, index) of switchlist" :key="index" @click="shiswitch(index)" :class="index == regionIndex ? 'special' : ''">
            <span>{{ $t(item) }}</span>
          </div>
        </div>
        <div class="l_zhi">
          <van-switch v-model="checked" size="18px" active-color="rgba(182, 133, 255, 1)" inactive-color="#fff" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>
      </div>
      <div class="i_pai">
        <div class="p_li">
          <span>{{ $t('lang.swap361') }}</span>
          <div class="menu">
            {{ $t('lang.swap363') }}
            <van-icon name="play" class="m_icon" @click="remeshowfun" />
            <div class="over" v-show="remenshow"></div>
          </div>
        </div>
        <div class="p_li">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field type="number" v-model="toInput" input-align="left" :placeholder="`${$t('lang.swap364')}`" class="van-field" />
        </div>
      </div>
    </div>
    <div class="i_lon">
      <!-- <div class="meiyou">
        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData1 == 'black'" />
        <img src="@/assets/img/zanwu1.png" alt="" v-else />
        <span>{{ $t('lang.swap365') }}</span>
      </div> -->
      <template>
        <div class="lon_list" style="margin-bottom: 26px">
          <div class="lon_title">
            <div class="lon_title_left" style="display: flex; align-items: center">
              <div class="title_img">
                <img src="@/assets/tokenlogo/Btpi.png" alt="" />
                <img src="@/assets/tokenlogo/BNB.png" alt="" />
              </div>
              <span style="font-size: 14px; font-weight: 600">BtPi - BNB {{ $t('lang.swap143') }}</span>
            </div>
            <div class="lon_title_right">
              <span v-if="BtPi_BNBData.myNumber">{{ $t('lang.h217') }}</span>
              <span v-else>{{ $t('lang.h218') }}</span>
             
            </div>
          </div>
          <div class="lon_line" style="margin-bottom: 22px">
            <div class="line_left">
              <span>{{ $t('lang.h219') }} USDT</span>
              <span style="font-size: 24px">{{ BtPi_BNBData.profitUSDT }}</span>
            </div>
            <div class="line_right_button button_active" @click="BtPi_BNBHarvest">
              {{ $t('lang.h220') }}
            </div>
          </div>
          <div class="lon_line" style="margin-bottom: 22px">
            <div class="line_left">
              <span>{{ $t('lang.h219') }} BtPi</span>
              <span style="font-size: 24px">{{ BtPi_BNBData.profitBtPi }}</span>
            </div>
            <div class="line_right_button button_active"  @click="BtPi_BNBHarvest">
              {{ $t('lang.h220') }}
            </div>
          </div>
          <div class="lon_line">
            <div class="line_left">
              <span>{{ $t('lang.swap143') }}</span>
              <span>{{ BtPi_BNBData.myNumber }}</span>
            </div>
            <div class="line_right_button button_active" v-if="BtPi_BNBData.allowanceToRouter" @click="approveBtPi_BNB">
              <van-loading color="#1989fa" v-if="BtPi_BNBData.showApprove" />
              <span v-else>{{ $t('lang.swap14') }}</span> 
            </div>
            <div v-else class="line_right_buttons">
              <div @click="selectBtPi_BNB(false)" style="margin-right: 16px">-</div>
              <div @click="selectBtPi_BNB(true)">+</div>
            </div>
          </div>
        </div>
        <div class="lon_list" style="margin-bottom: 26px" v-for="(item, index) in poollist" :key="index">
          <div class="lon_title">
            <div class="lon_title_left" style="display: flex; align-items: center">
              <div class="title_img">
                <img :src="item.logo" alt="" />
              </div>
              <span>{{ item.token }}</span>
            </div>
            <div class="lon_title_right">
              <span v-if="item.pledge_amount > 0">{{ $t('lang.h217') }}</span>
              <span v-else>{{ $t('lang.h218') }}</span>
            </div>
          </div>
          <div class="lon_line" style="margin-bottom: 22px">
            <div class="line_left">
              <span>{{ $t('lang.h219') }}</span>
              <span style="font-size: 24px">{{ item.reward_amount }}</span>
            </div>
            <div class="line_right_button button_active" @click="pledgepoolclaim(item)" v-if="item.claim_status == '1'">
              {{ $t('lang.h220') }}
            </div>
            <div class="line_right_button button_active" style="opacity: 0.4" v-else>{{ $t('lang.h221') }}</div>
          </div>
          <div class="lon_line">
            <div class="line_left">
              <span>{{ $t('lang.h222') }}</span>
              <span>{{ item.pledge_amount }}</span>
            </div>
            <div class="line_right_buttons">
              <div style="margin-right: 16px" @click="popupshowFun2(item)">-</div>
              <div @click="popupshowFun(item)">+</div>
            </div>
          </div>
        </div>
        <div class="lon_list" style="margin-bottom: 26px">
          <div class="lon_title">
            <div :class="fundinfo.id > 0 ? ['lon_title_left'] : ['lon_title_left', 'op']" style="display: flex; align-items: center">
              <div class="title_img">
                <img src="@/assets/imgsnowball/hap_icon.png" alt="" />
              </div>
              <span>HAP</span>
            </div>
            <div class="lon_title_right">
              <span>{{ ComstatusText }}</span>
            </div>
          </div>
          <div :class="fundinfo.id > 0 ? ['lon_line'] : ['lon_line', 'op']" style="margin-bottom: 22px; align-items: center; justify-content: center">
            <div class="line_left" style="text-align: center">
              <span>{{ $t('lang.h219') }}</span>
              <span style="font-size: 24px; color: #fff">{{ fundinfo.reward_amount }} GOC</span>
            </div>
          </div>
          <div :class="fundinfo.id > 0 ? ['lon_line'] : ['lon_line', 'op']">
            <div class="line_left">
              <span>{{ $t('lang.h223') }}</span>
              <span style="color: #fff">{{ fundinfo.pledge_amount }}</span>
            </div>
            <div class="line_left">
              <span v-if="fundinfo.end_time != ''">{{ $t('lang.h224') }}：{{ fundinfo.end_time }}</span>
              <span v-else></span>
              <div
                class="button"
                @click="circulationfundredeem"
                v-if="fundinfo.redeem_status == '0' || fundinfo.redeem_status == ''"
                :style="{ background: fundinfo.id > 0 ? '' : '#000000', color: fundinfo.id > 0 ? '' : '#fff' }"
              >
                {{ $t('lang.h226') }}
              </div>
              <div class="button" v-else :style="{ background: fundinfo.id > 0 ? '' : '#000000', color: fundinfo.id > 0 ? '' : '#fff' }">{{ $t('lang.h225') }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <van-popup v-model="MiningPopupShowBtPi_BNB" round position="bottom">
      <div class="popup" v-if="selectShowBtPi_BNB">
        <div class="popup_title" >{{ $t('BtPi-BNBLP') }}{{ $t('lang.h410') }} ：</div>
        <div class="popup_box" style="margin-bottom: 20px">{{ BtPi_BNBData.balance }}</div>
        <div class="popup_title">{{ $t('lang.h411') }}：</div>
        <div class="popup_box">
          <input type="number" v-model="joinInput" />
          <div class="popup_box_right" @click="fullInput(BtPi_BNBData.balance)">{{ $t('lang.h229') }}</div>
        </div>
        <div class="popup_button" @click="getBalanceBtPi_BNB">{{ $t('lang.h232') }}</div>
      </div>
      <div v-else class="popup">
        <div class="popup_title" >{{ $t('lang.h412') }}：</div>
        <div class="popup_box" style="margin-bottom: 20px">{{ BtPi_BNBData.myNumber }}</div>
        <div class="popup_title">{{ $t('lang.h413') }}：</div>
        <div class="popup_box">
          <input type="number" v-model="joinInput" />
          <div class="popup_box_right" @click="fullInput(BtPi_BNBData.myNumber)">{{ $t('lang.h229') }}</div>
        </div>
        <div class="popup_button" @click="getBalanceBtPi_BNB">{{ $t('lang.h232') }}</div>
      </div>
    </van-popup>
    <van-popup v-model="popupshow" round position="bottom">
      <div class="popup">
        <div class="popup_title">{{ $t('lang.h227') }}：</div>
        <div class="popup_box" style="margin-bottom: 20px">{{ banlace }}</div>
        <div class="popup_title">{{ $t('lang.h228') }}：</div>
        <div class="popup_box">
          <input type="number" v-model="joinInput" />
          <div class="popup_box_right" @click="fullInput(banlace)">{{ $t('lang.h229') }}</div>
        </div>
        <div class="poup_reamd">{{ $t('lang.h230') }}: {{ pledegejoinInfo.min_pledge }} {{ pledegejoinInfo.token }} {{ $t('lang.h231') }}</div>
        <div class="popup_button" @click="pledgepooldo">{{ $t('lang.h232') }}</div>
      </div>
    </van-popup>
    <van-popup v-model="popupshow2" round position="bottom">
      <div class="popup">
        <div class="popup_title">{{ $t('lang.h222') }}：</div>
        <div class="popup_box" style="margin-bottom: 20px">{{ pledegeredeemInfo.pledge_amount }}</div>
        <div class="popup_title">{{ $t('lang.h316') }}：{{ pledegeredeemInfo.pledge_amount }}</div>
        <div class="popup_box">
          <input type="number" v-model="redeemInput" />
          <div class="popup_box_right" @click="fullredeemInputInput">{{ $t('lang.h229') }}</div>
        </div>
        <div class="poup_reamd">{{ $t('lang.h230') }}:{{ pledegeredeemInfo.min_pledge }} {{ pledegeredeemInfo.token }} {{ $t('lang.h231') }}</div>
        <div class="popup_button" @click="pledgepoolredeem">{{ $t('lang.h232') }}</div>
      </div>
    </van-popup>
    <van-popup v-model="popupshow3" round closeable>
      <div class="status_popup">
        <img src="@/assets/img/status_success.png" alt="" />
        <span>{{ toastText }}</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { pledge, moneymask, circulation, web3_api } from '@/api';
import basicUrl from '@/config/configUrl/configUrl';
import Web3 from 'web3';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import BtPi_BNB_api from './Configs/BtPi_BNB/pledge_api.js';
import { BtPi_BNB_LPADDR, BtPi_BNB_PLEDGE_ADDR } from './Configs/BtPi_BNB/ConfigAddr.js';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
export default {
  name: 'iso',
  components: {},
  data() {
    return {
      popupshow: false,
      popupshow2: false,
      popupshow3: false,
      MiningPopupShowBtPi_BNB:false,
      selectShowBtPi_BNB:false,
      BtPi_BNBData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profitUSDT: 0, //已赚取U
        profitBtPi:0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      regionIndex: 0,
      toInput: '',
      checked: false,
      remenshow: false,
      bglist: {
        image: require('@/assets/img/earnmoneybg.png'),
        image1: require('@/assets/img/earnmoneybg1.png'),
      },
      switchlist: ['lang.swap358', 'lang.swap359'],
      poollist: [],
      pledegejoinInfo: {},
      banlace: 0,
      pledegeredeemInfo: {},
      joinInput: 0,
      redeemInput: 0,
      toastText: '操作成功',
      fundinfo: {},
      payLoading: false,
    };
  },
  computed: {
    ...mapState(['account', 'lang', 'ColorCheckData1','gasPrice']),
    ComstatusText() {
      if (this.fundinfo.begin_time == '' || this.fundinfo.end_time == '') return this.$t('lang.h234');
      const now = new Date(); // 当前时间
      const beginTime = new Date(this.fundinfo.begin_time); // 开始时间
      const endTime = new Date(this.fundinfo.end_time); // 结束时间

      if (now < beginTime) {
        return this.$t('lang.h234'); // 当前时间在开始时间之前
      } else if (now >= beginTime && now <= endTime) {
        return this.$t('lang.h235'); // 当前时间在开始时间和结束时间之间
      } else {
        return this.$t('lang.h236'); // 当前时间在结束时间之后
      }
    },
  },
  watch: {
    account() {
      this.init();
    },
    lang() {},
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.pledgepoollist();
        this.circulationfundinfo();

        this.handleApproveBtnShow();
        this.getBalanceOf();
        this.getDeposit();
        this.getReward();
      }
    },
    async handleApproveBtnShow() {

      //获取当前是否授权
      const BtPi_BNBContract = getErc20Contract(BtPi_BNB_LPADDR);

      const BtPi_BNBAppBalance = await BtPi_BNBContract.methods.allowance(this.account, BtPi_BNB_PLEDGE_ADDR).call();

      if (BtPi_BNBAppBalance == 0) {
        this.BtPi_BNBData.allowanceToRouter = true;
      } else {
        this.BtPi_BNBData.allowanceToRouter = false;
      }
    },
    async getBalanceOf() {
      const erc20ContractBtPi_BNB = getErc20Contract(BtPi_BNB_LPADDR);
      const balanceBtPi_BNB = await erc20ContractBtPi_BNB.methods.balanceOf(this.account).call();
      this.BtPi_BNBData.balance = formatAmount(balanceBtPi_BNB, 18);
    },
    getDeposit() {
      BtPi_BNB_api.balance(this.account).then(res => {
        this.BtPi_BNBData.myNumber = (res * 1) / Math.pow(10, 18);
        
      });
    },
    getReward() {
      BtPi_BNB_api.earned(this.account).then(res => {
        this.BtPi_BNBData.profitBtPi = significantDigits(res[0] / Math.pow(10, 18));
        this.BtPi_BNBData.profitUSDT = significantDigits(res[1] / Math.pow(10, 18));
      });
    },
    selectBtPi_BNB(state) {
      this.selectShowBtPi_BNB = state;
      this.MiningPopupfirmBtPi_BNB();
    },
    MiningPopupfirmBtPi_BNB () {
      this.MiningPopupShowBtPi_BNB = !this.MiningPopupShowBtPi_BNB;
    },
    async approveBtPi_BNB() {
      this.BtPi_BNBData.showApprove = true;
      const erc20Contract = useErc20Contract(BtPi_BNB_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(BtPi_BNB_PLEDGE_ADDR, amo).estimateGas({ from: this.account });
      erc20Contract.methods
        .approve(BtPi_BNB_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice),
        })
        .on('confirmation', res => {
          this.BtPi_BNBData.showApprove = false;
          this.BtPi_BNBData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.BtPi_BNBData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },


    getBalanceBtPi_BNB () {
      let amount = parseAmount(String(this.joinInput), 18);
      
      if (this.selectShowBtPi_BNB) {
        if(parseFloat(this.joinInput)<2) return this.$toast(this.$t('lang.h414'));
        if(parseFloat(this.joinInput) % 1 !== 0) return this.$toast(this.$t('lang.h415'));
        this.MiningPopupShowBtPi_BNB = false;
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.swap143') + 'BtPi-BNB',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        BtPi_BNB_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        this.MiningPopupShowBtPi_BNB = false;
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.swap160') + 'BtPi-BNB',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        BtPi_BNB_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    BtPi_BNBHarvest() {
      if (this.BtPi_BNBData.profitBtPi == 0 && this.BtPi_BNBData.profitUSDT == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      BtPi_BNB_api.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },


    async circulationfundredeem() {
      if (this.fundinfo.id == 0) return;
      if (this.payLoading) return;
      this.payLoading = true;
      let sign = null;
      try {
        sign = await web3_api.signMessage('Circulation Fund Redeem', this.account);
      } catch (error) {
        this.payLoading = false;
        return;
      }
      let data = {
        signature: sign,
      };
      circulation.circulationfundredeem(data, this.account).then(res => {
        if (res.code == 200) {
          this.init();
        }
        this.$toast(res.msg);
        this.payLoading = false;
      });
    },

    circulationfundinfo() {
      circulation.circulationfundinfo(this.account).then(res => {
        if (res.code == 200) {
          this.fundinfo = res.data;
        }
      });
    },
    getwalletgetinfo(id) {
      moneymask.getwalletgetinfo({ token_id: id }, this.account).then(res => {
        this.banlace = res.data.usable;
      });
    },

    pledgepoollist() {
      pledge.pledgepoollist(this.account).then(res => {
        let list = res.data;
        for (let item of list) {
          item.logo = basicUrl.http_url + item.logo;
        }
        this.poollist = list;
      });
    },
    fullInput(banlace) {
      this.joinInput = parseFloat(banlace);
    },
    fullredeemInputInput() {
      this.redeemInput = parseFloat(this.pledegeredeemInfo.pledge_amount);
    },
    shiswitch(index) {
      this.regionIndex = index;
    },
    remeshowfun() {
      this.remenshow = !this.remenshow;
    },
    popupshowFun(item) {
      this.pledegejoinInfo = item;
      this.getwalletgetinfo(item.token_id);
      this.popupshow = !this.popupshow;
    },
    popupshowFun2(item) {
      this.pledegeredeemInfo = item;
      this.popupshow2 = !this.popupshow2;
    },
    popupshowFun3() {
      this.popupshow3 = !this.popupshow3;
    },
    async pledgepooldo() {
      let web3 = new Web3(window.ethereum);
      let sign = null;
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Do'), this.account);
      } catch (error) {
        this.$toast(this.$t('lang.h237'));
        return;
      }
      let data = {
        pool_id: this.pledegejoinInfo.id,
        amount: parseFloat(this.joinInput),
        signature: sign,
      };
      pledge.pledgepooldo(data, this.account).then(res => {
        this.popupshow = false;
        if (res.code == 200) {
          this.init();

          this.toastText = this.$t('lang.h238');
          this.popupshow3 = true;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    async pledgepoolredeem() {
      let web3 = new Web3(window.ethereum);
      let sign = null;
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Redeem'), this.account);
      } catch (error) {
        this.$toast(this.$t('lang.h237'));
        return;
      }
      let data = {
        pool_id: this.pledegeredeemInfo.id,
        amount: parseFloat(this.redeemInput),
        signature: sign,
      };
      pledge.pledgepoolredeem(data, this.account).then(res => {
        this.popupshow2 = false;
        if (res.code == 200) {
          this.init();

          this.toastText = this.$t('lang.h239');
          this.popupshow3 = true;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    async pledgepoolclaim(item) {
      if (item.reward_amount == 0) {
        this.$toast(this.$t('lang.h240'));
        return;
      }
      let web3 = new Web3(window.ethereum);
      let sign = null;
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Reward Claim'), this.account);
      } catch (error) {
        this.$toast(this.$t('lang.h237'));
        return;
      }

      let data = {
        pool_id: item.id,
        amount: item.reward_amount,
        signature: sign,
      };
      console.log(data, 'data');
      pledge.pledgepoolclaim(data, this.account).then(res => {
        if (res.code == 200) {
          this.init();
          this.toastText = this.$t('lang.h317');
          this.popupshow3 = true;
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.op {
  opacity: 0.6;
}
.iso {
}

.i_main {
  text-align: center;
  position: relative;
  width: 100%;
  height: 500px;
  // background: url(~@/assets/img/earnmoneybg.png)no-repeat;
  background-size: contain !important;
  background-position: 0 130px !important;
  // background-size: 130% 243px !important;
  // background-position: -60px 150px !important;
  padding: 20px;

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var(--text-color);
    text-align: center;
    margin-top: 40px;
  }

  .m_text {
    margin-top: 7px;
    text-align: center;
    color: rgba(158, 156, 163, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: 'HarmonyOSSansSC';
  }

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .b_b1 {
      border-radius: 16px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 160px;
      height: 48px;
      opacity: 1;
      border-radius: 72px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.12);
    }
  }
}

.i_sosuo {
  width: 100%;
  padding: 21px;

  .s_line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .region {
      padding: 3px;
      width: 130px;
      height: 40px;
      border-radius: 19px;
      border: 1px solid rgba(180, 135, 255, 0.71);
      display: flex;
      line-height: 38px;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
      color: rgba(158, 156, 163, 1);
      // margin: 20px 0;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }

      .special {
        // width: 50%;
        color: #000;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }

    .l_zhi {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      span {
        opacity: 0.6;
        color: var(--text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
        margin-left: 5px;
      }

      /deep/.van-switch__node {
        background: var(--text-color);
      }
    }
  }

  .i_pai {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_li {
      height: 100%;
      color: var(--text-color);

      span {
        opacity: 0.6;
        margin-bottom: 20px;
      }
    }
  }
}

.menu {
  position: relative;
  margin-top: 20px;
  width: 137px;
  height: 50px;
  border-radius: 61px;
  opacity: 1;
  background: var(--line-bg-color);
  padding-left: 14px;
  line-height: 50px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';

  .m_icon {
    transform: rotate(90deg);
    color: var(--text-color);
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .over {
    width: 100%;
    height: 100px;
    position: absolute;
    background: var(--text-color);
    bottom: -110px;
    z-index: 9999;
    left: 0;
    border-radius: 20px;
  }
}

.van-field {
  width: 178px;
  height: 50px;
  border-radius: 61px;
  color: #bababaff;
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  background: var(--line-bg-color);
  margin-top: 20px;
  padding: 14px 14px !important;

  /deep/ .van-field__control {
    color: #000;
  }
}

.i_lon {
  width: 100%;

  border-radius: 30px 30px 0 0;
  margin-top: 20px;
  background: var(--box-bg-color);
  position: relative;
  padding: 28px 20px;

  .lon_list {
    padding: 19px 15px;
    width: 100%;

    opacity: 1;
    border-radius: 16px;

    border: 1px solid rgba(180, 135, 255, 0.46);

    .lon_title {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 18px;
      color: var(--text-color);

      .lon_title_right {
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        opacity: 1;
        border-radius: 3px;
        color: rgba(177, 138, 255, 1);
        border: 1px solid rgba(177, 138, 255, 1);
      }

      .title_img {
        margin-right: 5px;

        img {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin-right: 3px;
        }
      }
    }

    .lon_line {
      padding: 16px 17px;
      width: 100%;
      height: 86px;
      opacity: 1;
      border-radius: 8px;
      background: var(--line-bg-color);
      display: flex;
      align-items: end;
      justify-content: space-between;

      .line_left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        color: rgba(153, 153, 153, 1);

        .button {
          color: #000000;
          align-self: self-end;
          width: 67px;
          height: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: linear-gradient(138.02deg, #30fffc 0%, #b685ff 100%);
        }
      }

      .line_right_buttons {
        display: flex;

        & > div {
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 31px;
          width: 34px;
          height: 31px;
          opacity: 1;
          border-radius: 8px;
          background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        }
      }

      .line_right_button {
        width: 67px;
        height: 31px;
        opacity: 1;
        border-radius: 8px;
        background: var(--bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: var(--text-color);
      }

      .button_active {
        color: #000;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      }
    }

    .lon_line2 {
      width: 100%;
      height: 109px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(240, 245, 254, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 18px;

      .line2_title {
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(0, 0, 0, 1);
      }

      .line2_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 41px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(173, 184, 204, 1);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: var(--text-color);
      }
    }

    .lon_line3 {
      margin-bottom: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line3_left {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }

      .line3_right {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 89px;
      height: 158px;

      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(168, 168, 168, 1);
    }
  }
}

.popup {
  padding: 20px;

  .popup_title {
    font-size: 16px;
    font-weight: 700;
    color: var(--bg-color);
    margin-bottom: 8px;
  }

  .popup_box {
    padding: 16px;
    opacity: 1;
    border-radius: 7px;

    border: 1px solid rgba(217, 217, 217, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      border: none;
      background: none;
    }

    .popup_box_right {
      font-size: 14px;
      color: rgba(127, 183, 254, 1);
      padding-left: 23px;
      border-left: 1px solid rgba(230, 230, 230, 1);
    }
  }

  .poup_reamd {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(153, 153, 153, 1);
    margin-top: 8px;
  }

  .popup_button {
    margin-top: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    opacity: 1;
    border-radius: 110px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    font-size: 18px;
    font-weight: 700;
    color: rgba(34, 34, 34, 1);
  }
}

.status_popup {
  width: 335px;
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 147px;
    height: 88px;
  }
}
</style>
