import request from '../utils/request';
export default {
    //转账记录
    gettransferlist(data,address,language) {
        return request.request({
            url: '/transfer/record/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
            params:data
            
        })
    },

    //转账
    posttransferout(data,address,language){
        return request.request({
            url: '/transfer/out',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
            data:data
        })
    },
    //转账条件
    gettransfercondition(address,language){
        return request.request({
            url: '/transfer/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                
            },
           
        })
    }
}