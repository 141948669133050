<template>
  <div>

    <div class="gongao" style="border-radius: 20px 20px 0 0;">


      <div class="g_li" v-for="(item, index) in pagelist" @click="goxiangqing(item.group)" :key="index">
        <van-icon name="arrow" class="arrow" />
        <span class="span1">{{ item.title }}</span>
        <span class="span2">{{ item.created_time }}</span>
        <div class="line"></div>
        <span class="span3">{{ item.author }}</span>
      </div>
    </div>
  </div>

</template>

<script>
import { gongao } from '@/api/index';
import { mapState } from 'vuex';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {

  },
  components: { topbutton },
  data() {
    return {
      page: 1,
      pageSize: 30,
      pagelist: []
    }
  },

  created() {
    this.init();

  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  methods: {
    init() {
      if (this.account) {
        this.getlist()
      }

    },
    getlist() {
      let data = {
        page: this.page,
        page_size: this.pageSize
      }
      gongao.getNoticeList(data, this.account).then(res => {
        this.pagelist = res.data.list

      })
    },
    goxiangqing(index) {
      this.$router.push({ name: 'xiangqing', query: { id: index } });
    },
    toprouter() {
      console.log(this.router);
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="less" scoped>
.recruit_title {
  color: var(--text-color);
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.g_title {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
}

.gongao {

  width: 100%;
  min-height: 100vh;
  padding: 20px;

  .g_li {
    margin-bottom: 14px;
    width: 100%;
    height: 119px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 15px 16px;
    background: var(--box-bg-color);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .arrow {
      position: absolute;
      color: var(--text-color);
      right: 20px;
      top: 20px;
    }

    .line {
      width: 100%;
      height: 0;
      opacity: 1;
      border: 1px solid rgba(83, 87, 112, 1);
    }

    .span1 {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
    }

    .span2 {
      color: var(--text-color);
      opacity: 0.6;
      font-size: 12px;

      font-family: "PingFang";
    }

    .span3 {
      color: var(--text-color);
      font-size: 12px;
      font-weight: 500;
      font-family: "Poppins";
    }
  }
}
</style>