//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0xb61e1493cb2cbbf2e3e89872c6095aa000d5db32",
      "name": "HAP",
      "symbol": "HAP",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/HAP.png')
    },
    {
      "chainId": 56,
      "address": "0x69267e8d1f052eef1a236e0cf24019f7fd5f353f",
      "name": "GOC",
      "symbol": "GOC",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/GOC.png')
    },
]

export const BNBV2list = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0x92627c455652fad0fffabdf61f71a7455c333274",
      "name": "BtPi",
      "symbol": "BtPi",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/Btpi.png')
    },
]




